<template>
  <component
    :is="view"
    :phone-number="phoneNumber"
    :disabled="disabled"
    @click="handleClick"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useActiveCti } from '@/composable/active-cti';
import { key } from '@/store/index';
import MiitelCallButton from './MiitelCallButton.vue';
import ZoomPhoneCallButton from './ZoomPhoneCallButton.vue';

type TProps = {
  phoneNumber: string;
  disabled?: boolean;
  callResultNextActionId?: number;
  phoneNumberKey?: string;
};
const props = defineProps<TProps>();

const store = useStore(key);
const { activeCti } = useActiveCti(true);

const view = computed(() => activeCti.value === 'zoom' ? ZoomPhoneCallButton : MiitelCallButton);
const handleClick = () => {
  store.dispatch('userUi/setTargetCallResultFormLogValuesAction', {
    nextActionId: props.callResultNextActionId,
    phoneNumberKey: props.phoneNumberKey,
  });
};
</script>
