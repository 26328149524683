<template>
  <div @click="handleActivatorClick">
    <slot name="activator">
      <BBtn class="lead-view-select-activator">
        <BCustomIcon icon-class="b-list" />
        <span class="activator-text">
          {{ selectedLeadView?.name || $t('leadList.allTitle') }}
        </span>
      </BBtn>
    </slot>
  </div>
  <LeadViewSelectModal v-model:is-open="isOpen" />
</template>

<script lang="ts" setup>
import { ref, watch } from 'vue';
import { LeadView } from '@/api/openapi';
import { useModal } from '@/composable/modal';
import LeadViewSelectModal from './LeadViewSelectModal.vue';

type TProps = {
  selectedLeadView?: LeadView;
};

defineProps<TProps>();

const { openModal, closeModal } = useModal(() => 'leadViewSelect');

const isOpen = ref(false);

watch(isOpen, async () => {
  if (isOpen.value) {
    openModal();
  } else {
    closeModal();
  }
});

const handleActivatorClick = () => {
  isOpen.value = true;
};
</script>

<style lang="scss" scoped>
.lead-view-select {
  &-activator {
    @include m-fixed-width(400px);
    height: 40px;

    .b-list {
      margin-right: $basespace-100;
    }

    .activator-text {
      @include m-truncate-text;
      text-align: left;
      flex: 1;
    }
  }
}
</style>
