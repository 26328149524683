<template>
  <BUserTooltip
    v-if="user != null"
    :image-url="user.avatarUrl"
    :name="user.name"
    :user-id="user.id"
    :size="width"
  />
  <BEmptyBox
    v-else
    class="empty-box"
    display-only
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { UserForDisplay } from '@/api/openapi';
import BEmptyBox from './BEmptyBox.vue';
import BUserTooltip from './BUserTooltip.vue';

type TProps = {
  user: UserForDisplay | null;
  size?: 'small' | 'medium' | 'large';
};

const props = withDefaults(defineProps<TProps>(), {
  size: 'medium',
});
const width = computed(() => {
  if (props.size === 'small') return 24;
  if (props.size === 'large') return 48;
  return 36;
});
const fontSize = computed(() => {
  if (props.size === 'small') return 10;
  if (props.size === 'large') return 18;
  return 14;
});
</script>

<style lang="scss" scoped>
.empty-box {
  width: v-bind('`${width}px`');
  height: v-bind('`${width}px`');
  border-radius: 50%;
}
:deep(.el-avatar) {
  font-size: v-bind('`${fontSize}px`');
}
</style>
