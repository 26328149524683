<template>
  <div
    class="editable-select-user"
    :class="{ editable, 'with-avatar': withAvatar }"
    @click="handleActivatorClick"
  >
    <SelectUser
      v-if="editing"
      ref="selectUserRef"
      :key="key"
      v-model="selectedUserId"
      :teleported="teleported"
      :use-all-label="useAllLabel"
      :use-all="useAll"
      only-active-users
      :with-avatar="withAvatar"
      @blur="handleBlur"
    />
    <template v-else>
      <div class="editable-select-user-activator">
        <UserAvatar
          v-if="withAvatar"
          :user="userForDisplay"
        />
        <span v-else>
          {{ userForDisplay?.name || $t('general.none') }}
        </span>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { UserForDisplay } from '@/api/openapi';
import { useKey } from '@/composable/component';
import SelectUser from './SelectUser.vue';

type TProps = {
  userForDisplay: UserForDisplay | null;
  editable?: boolean;
  userNullable?: boolean;
  teleported?: boolean;
  withAvatar?: boolean;
  finishEditingOnBlur?: boolean;
};

const props = defineProps<TProps>();

const { t } = useI18n();
const { key } = useKey();

const selectedUserId = defineModel<number | null>({ default: null });
const editing = defineModel<boolean>('editing', { default: false });
const selectUserRef = ref<InstanceType<typeof SelectUser> | null>(null);

const useAllLabel = computed(() => {
  return props.userNullable ? t('selectUser.pleaseSelect') : '';
});
const useAll = computed(() => {
  return props.userNullable;
});

const handleActivatorClick = async () => {
  if (!props.editable) return;
  editing.value = true;
  await nextTick();
  selectUserRef.value?.focus();
};
const handleBlur = () => {
  if (!props.finishEditingOnBlur) return;
  editing.value = false;
};
</script>

<style lang="scss" scoped>
.editable-select-user {
  &-activator {
    @include m-flex-align-center;
  }
  &.editable {
    .editable-select-user-activator {
      padding: $basespace-50 $basespace-200;
      min-height: 40px;

      &:hover {
        cursor: pointer;
        border: 1px solid $bdcolor-light;
        border-radius: $border-radius-s;
      }
    }
  }
  &.with-avatar {
    .editable-select-user-activator {
      width: 90px;
      height: 54px;
    }
  }
}
</style>
