<template>
  <MailDrawerTemplate
    :title="$t('mail.confirmContent')"
  >
    <template
      v-if="!isSendNow"
      #drawerHeaderAction
    >
      <BBtn
        size="small"
        type="primary"
        outline
        @click="clickReserveButton"
      >
        <span>{{ $t('mail.reserve') }}</span>
        <BIcon>keyboard_arrow_right</BIcon>
      </BBtn>
    </template>
    <template
      v-else
      #drawerHeaderAction
    >
      <BBtn
        size="small"
        type="primary"
        outline
        :loading="$wait.is('sendBulkWait')"
        @click="clickSendButton"
      >
        <span>{{ $t('mail.send') }}</span>
        <BIcon>keyboard_arrow_right</BIcon>
      </BBtn>
    </template>
    <template #drawerBody>
      <div
        class="drawer-body"
      >
        <BLayout
          class="campaign-confirm mb-400"
          align-center
        >
          <div>{{ bulkMail.campaignName }}</div>
          <BTooltip
            top
            :content="$t('mail.sentCountLabel')"
          >
            <div class="can-send-count ml-a">
              <BListItem>
                <template #header>
                  <span>{{ $t('mail.sentCount') }}</span>
                </template>
                <div class="lh-1 fs-700 fw-bold">
                  {{ canSendCount }}
                </div>
              </BListItem>
            </div>
          </BTooltip>
        </BLayout>
        <div class="mail-confirm mb-600">
          <div class="subject-confirm">
            {{ bulkMail.subject }}
          </div>
          <div class="content-confirm">
            <div
              class="quill-confirm"
              v-html="bulkMail.content"
            />
          </div>
        </div>
        <BListItem class="list-item mb-600">
          <template #header>
            <div>{{ $t('mail.to') }}</div>
          </template>
          <div>
            <span>{{ selectedLeadViewName }}（<BTooltip
              top
              :content="$t('mail.leadCount')"
            ><span class="lead-count">{{ `${leadCount}${$t('general.matter')}` }}</span></BTooltip>）</span>
          </div>
        </BListItem>
        <BListItem class="list-item mb-600">
          <template #header>
            <div>{{ $t('mail.sender') }}</div>
          </template>
          <span>{{ senderSet }}</span>
        </BListItem>
        <BListItem
          v-if="!isDisabledReplyForm"
          class="list-item mb-600"
        >
          <template #header>
            <div>{{ $t('mail.reply') }}</div>
          </template>
          <span>{{ replySet }}</span>
        </BListItem>
        <BListItem class="list-item mb-600">
          <template #header>
            <div>{{ $t('mailNotificationEvent.formTitle') }}</div>
          </template>
          <div>{{ mailEventClickSet }}</div>
          <div class="mt-100">
            {{ mailEventOpenSet }}
          </div>
        </BListItem>
        <BListItem
          v-if="!isSendNow"
          class="list-item mb-600"
        >
          <template #header>
            <div>{{ $t('mail.sendAt') }}</div>
          </template>
          <div>{{ convertSendAt(bulkMail.sendAt) }}</div>
        </BListItem>
      </div>
    </template>
    <template #drawerFooter>
      <BLayout justify-center>
        <BBtn
          class="mr-100"
          @click="$emit('change-current-state-to-form')"
        >
          <BIcon>keyboard_arrow_left</BIcon><span>{{ $t('mail.backToEdit') }}</span>
        </BBtn>
        <BPopover
          v-model="testPopoverOpen"
          placement="top"
          width="240"
          trigger="click"
        >
          <template #reference>
            <BBtn
              class="ml-100"
              type="primary"
              :disabled="testPopoverOpen"
            >
              {{ $t('mail.sendTest') }}
            </BBtn>
          </template>
          <div>
            <div class="fw-bold">
              {{ $t('mail.sendTest') }}
            </div>
            <BListItem class="list-item mt-300">
              <template #header>
                <div>{{ $t('mail.to_address') }}</div>
              </template>
              <BInput
                v-model="testAddress"
                v-model:valid="testAddressValid"
                class="name"
                :placeholder="$t('mail.mailAddress')"
                :rules="[requiredRule, emailRule]"
                :max-length="255"
                underline
              />
            </BListItem>
            <BListItem class="list-item mt-300">
              <template #header>
                <BLayout
                  align-center
                >
                  {{ $t('callTarget.column.companyName') }}
                  <BTooltip
                    top
                    :content="$t('mail.testCompanyNameInfo')"
                  >
                    <BIcon
                      class="ml-50"
                      size="small"
                      type="gray"
                    >
                      help
                    </BIcon>
                  </BTooltip>
                </BLayout>
              </template>
              <BInput
                v-model="testCompanyName"
                v-model:valid="testCompanyValid"
                class="name"
                :rules="[requiredRule]"
                :max-length="255"
                underline
              />
            </BListItem>
            <BListItem class="list-item mt-300">
              <template #header>
                <BLayout align-center>
                  {{ $t('callTarget.column.toName') }}
                  <BTooltip
                    top
                    :content="$t('mail.testToNameInfo')"
                  >
                    <BIcon
                      class="ml-50"
                      size="small"
                      type="gray"
                    >
                      help
                    </BIcon>
                  </BTooltip>
                </BLayout>
              </template>
              <BInput
                v-model="testToName"
                v-model:valid="testToNameValid"
                class="name"
                :rules="[requiredRule]"
                :max-length="255"
                underline
              />
            </BListItem>
            <BListItem class="list-item mt-300">
              <template #header>
                <BLayout align-center>
                  {{ $t('mail.testFromName') }}
                  <BTooltip
                    top
                    :content="$t('mail.testFromNameInfo')"
                  >
                    <BIcon
                      class="ml-50"
                      size="small"
                      type="gray"
                    >
                      help
                    </BIcon>
                  </BTooltip>
                </BLayout>
              </template>
              <BInput
                v-model="testFromName"
                v-model:valid="testFromNameValid"
                class="name"
                :rules="[requiredRule]"
                :max-length="255"
                underline
              />
            </BListItem>
            <BLayout
              class="mt-300"
              justify-center
            >
              <BBtn
                class="mr-100"
                flat
                size="small"
                @click="testPopoverOpen = false"
              >
                {{ $t('general.cancel.text') }}
              </BBtn>
              <BBtn
                outline
                type="primary"
                size="small"
                :loading="$wait.is('testSendWait')"
                :disabled="!testValid"
                @click="sendTest"
              >
                {{ $t('mail.sendTest') }}
              </BBtn>
            </BLayout>
          </div>
        </BPopover>
      </BLayout>
    </template>
  </MailDrawerTemplate>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue';
import errorHandler from '@/mixins/error_handler';
import inputValidation from '@/mixins/input_validation';
import { formatShorterDateTime } from '@/utils/date-time';

export default {
  name: 'ConfirmDrawer',
  components: {
    MailDrawerTemplate,
  },
  mixins: [errorHandler, inputValidation],
  props: {
    isDisabledReplyForm: {
      type: Boolean,
      default: false,
    },
    isSendNow: {
      type: Boolean,
    },
    bulkMail: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'change-current-state-to-form',
    'send',
    'reserve',
  ],
  data () {
    return {
      testAddress: '',
      testCompanyName: '',
      testToName: '',
      testFromName: '',
      testAddressValid: false,
      testCompanyValid: false,
      testToNameValid: false,
      testFromNameValid: false,
      testPopoverOpen: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'leadViews',
      'mailLeadCount',
    ]),
    testValid () {
      return this.testAddressValid
        && this.testCompanyValid
        && this.testToNameValid
        && this.testFromNameValid;
    },
    selectedLeadView () {
      if (this.bulkMail.leadViewId == null || this.leadViews.length === 0) return null;
      return this.leadViews.find((leadView) => leadView.id === this.bulkMail.leadViewId);
    },
    selectedLeadViewName () {
      return this.selectedLeadView == null ? this.$t('general.delete.done') : this.selectedLeadView.name;
    },
    leadCount () {
      return this.mailLeadCount.leadCount;
    },
    canSendCount () {
      return this.mailLeadCount.canSendMailCount;
    },
    senderSet () {
      return `${this.bulkMail.fromName} <${this.bulkMail.fromAddress}>`;
    },
    replySet () {
      return `${this.bulkMail.replyName} <${this.bulkMail.replyAddress}>`;
    },
    mailEventOpenSet () {
      const message = this.bulkMail.open === true ? 'receive' : 'unreceive';
      return this.$t(`mailNotificationEvent.${message}`, { event: this.$t(`mailNotificationEvent.open`) });
    },
    mailEventClickSet () {
      const message = this.bulkMail.click === true ? 'receive' : 'unreceive';
      return this.$t(`mailNotificationEvent.${message}`, { event: this.$t(`mailNotificationEvent.click`) });
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      mailLeadCountAction: {
        action: 'mailLeadCountAction',
        loader: 'mailLeadCountWait',
      },
      testSendAction: 'testSendWait',
      sendBulkAction: 'sendBulkWait',
    }),
    clickSendButton (){
      this.$emit('send');
    },
    convertSendAt (sendAt){
      return formatShorterDateTime(sendAt);
    },
    clickReserveButton (){
      this.$emit('reserve');
    },
    async sendTest () {
      await this.testSendAction({
        body: {
          id: this.bulkMail.id,
          to: this.testAddress,
          fromName: this.testFromName,
          toName: this.testToName,
          companyName: this.testCompanyName,
        },
        errorHandlers: {
          422: this.defaultHandler,
        },
      });
      await this.$bitterAlert.show({
        title: this.$t('mail.testMailSendTitle'),
        text: this.$t('mail.testMailSendText'),
        closeOnClickOutside: true,
        buttonsCancel: false,
      });
      this.testPopoverOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .campaign-confirm {
    margin-top: -$basespace-400;
    margin-right: -$basespace-400;
    margin-left: -$basespace-400;
    padding: $basespace-200 $basespace-400;
    background-color: $concrete-light;
  }

  .mail-confirm {
    border: 1px solid $concrete;
    border-radius: 5px;

    .subject-confirm {
      min-height: 32px;
      max-height: 80px;
      overflow-y: scroll;
      border-bottom: 1px solid $concrete;
      padding: $basespace-100;
      font-weight: 600;
    }

    .content-confirm {
      max-height: 600px;
      overflow-y: scroll;
      padding: $basespace-100;

      .opt-out-link {
        margin: $basespace-400 0 $basespace-50;
        width: 160px;
        color: $basecolor-secondary;
        text-decoration-line: underline;
        font-size: $fontsize-100;
      }

    }
  }

  .lead-count {
    color: $basecolor-primary;
    font-weight: 600;
  }

  .flat-input {
    border-bottom: 1px solid $bdcolor-light;
  }

  .quill-confirm {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.42;
    tab-size: 4;
    text-align: left;

    // Biscuetの標準リンク色を上書き
    :deep(a) {
      color: #06c;
    }

    :deep(p) {
      margin: 0 !important;
      padding: 0 !important;
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
    }
  }
  .can-send-count {
    text-align: right;
  }
</style>
