import { readonly, ref } from 'vue';
import { DirectMailUnsubscribedEmail } from '@/api/openapi';
import { DirectMailUnsubscribedEmailService } from '@/api/user/resources/direct_mail_unsubscribed_email';
import { useWait } from '@/composable/vue-wait';

type TBulkMailUnsubscribedEmail = {
  message: string,
  createdAt: string,
};

type TUnsubscribeMail = {
  directMail: DirectMailUnsubscribedEmail | null,
  bulkMail: TBulkMailUnsubscribedEmail | null,
};

const useDirectMailUnsubscribedEmail = () => {
  const { doActionWithWait } = useWait();

  const api = new DirectMailUnsubscribedEmailService();
  const directMailUnsubscribedEmail = ref<DirectMailUnsubscribedEmail>(null);

  const fetchDirectMailUnsubscribedEmail = async (email: string) => {
    await doActionWithWait('fetchDirectMailUnsubscribedEmailWait', async () => {
      const { data } = await api.getUnsubscribedEmail({
        request: { email },
      });
      directMailUnsubscribedEmail.value = data.directMailUnsubscribedEmail;
    });
  };

  const unsubscribeDirectMailUnsubscribedEmail = async (email: string) => {
    await api.unsubscribe({ request: { email } });
  };

  const subscribeDirectMailUnsubscribedEmail = async (email: string) => {
    await api.subscribe({ request: { email } });
  };

  return {
    directMailUnsubscribedEmail: readonly(directMailUnsubscribedEmail),
    fetchDirectMailUnsubscribedEmail,
    unsubscribeDirectMailUnsubscribedEmail,
    subscribeDirectMailUnsubscribedEmail,
  };
};

export type {
  TUnsubscribeMail,
};
export {
  useDirectMailUnsubscribedEmail,
};
