<template>
  <div class="b-select-wrapper">
    <ElSelect
      v-bind="attributes"
      v-model="internalValue"
      :class="classes"
      @change="changeItem"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
      @clear="$emit('clear')"
    >
      <template #prefix>
        <slot name="prefix" />
      </template>
      <template #label="labelProps">
        <slot
          name="label"
          v-bind="labelProps"
        />
      </template>
      <template v-if="groups.length >= 1">
        <ElOptionGroup
          v-for="group in groups"
          :key="group.key"
          :label="group.label"
        >
          <ElOption
            v-for="item in group.options"
            :key="optionKey(item)"
            v-bind="optionAttrs(item)"
          >
            <slot
              name="optionTemplate"
              :item="item"
            />
          </ElOption>
        </ElOptionGroup>
      </template>
      <template v-else>
        <ElOption
          v-for="item in items"
          :key="optionKey(item)"
          v-bind="optionAttrs(item)"
        >
          <slot
            name="optionTemplate"
            :item="item"
          />
        </ElOption>
      </template>
    </ElSelect>
    <Transition name="el-fade-in-linear">
      <div
        v-if="required && !disabled"
        class="check-icon"
      >
        <BTooltip
          v-if="!internalValid"
          top
          :value="false"
        >
          <BIcon
            size="small"
            class="invalid"
          >
            check_circle_outline
          </BIcon>
          <template #content>
            <div>{{ $t('validation.required') }}</div>
          </template>
        </BTooltip>
        <BIcon
          v-else
          size="small"
          class="valid"
        >
          check_circle
        </BIcon>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from 'vue';

type TOption = Record<string | number | symbol, unknown>;

export type TOptions = TOption[];

type TGroup = {
  key: string;
  label?: string;
  options: TOptions;
};
function isTGroup(arg: any): arg is TGroup {
  if (arg.options === undefined) return false;
  if (!Array.isArray(arg.options)) return false;
  return true;
}
export type TGroups = TGroup[];
function isTGroups(arg: any): arg is TGroups {
  if (!Array.isArray(arg)) return false;
  if (arg.some(e => !isTGroup(e))) return false;
  return true;
}

export default defineComponent({
  props: {
    modelValue: [String, Number, Boolean, Array],
    multiple: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
    size: {
      type: String,
      default: undefined,
    },
    clearable: Boolean,
    collapseTags: Boolean,
    required: {
      type: Boolean,
      default: false,
    },
    multipleLimit: {
      type: Number,
      default: 0,
    },
    placeholder: {
      type: String,
      default: '',
    },
    items: {
      type: Array as PropType<TOptions | TGroups>,
      required: true,
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    flat: Boolean,
    underline: Boolean,
    fit: Boolean,
    filterable: {
      type: Boolean,
      default: false,
    },
    teleported: {
      type: Boolean,
      default: true,
    },
    automaticDropdown: {
      type: Boolean,
      default: false,
    },
    valid: {
      type: Boolean,
      default: true, // NOTE: こうしとかないと利用先でvalidを与えない時falseになっちゃうので...
    },
  },
  emits: [
    'focus',
    'clear',
    'update:modelValue',
    'update:valid',
    'change',
    'blur',
  ],
  data() {
    return {
      internalValid: this.valid,
    };
  },
  computed: {
    internalValue: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        if (this.modelValue !== newVal) this.$emit('update:modelValue', newVal);
      },
    },
    attributes() {
      return {
        'multiple': this.multiple,
        'disabled': this.disabled,
        'loading': this.loading,
        'size': this.size,
        'clearable': this.clearable,
        'collapse-tags': this.collapseTags,
        'multiple-limit': this.multipleLimit,
        'placeholder': this.placeholder || this.$t('general.select.please'),
        'filterable': this.filterable,
        'ref': 'elSelect',
        'teleported': this.teleported,
        'automatic-dropdown': this.automaticDropdown,
      };
    },
    classes() {
      return {
        'b-select': true,
        'is-flat': this.flat,
        'is-underline': this.underline,
        'is-fit': this.fit,
      };
    },
    groups() {
      if (isTGroups(this.items)) return this.items;
      return [];
    },
    isValid: {
      get() {
        return this.internalValid;
      },
      set(newVal) {
        this.internalValid = newVal;
        this.$emit('update:valid', newVal);
      },
    },
  },
  watch: {
    internalValue: {
      handler(value) {
        if (!this.required) {
          this.isValid = true;
          return;
        }
        if (this.multiple) {
          this.isValid = value.length > 0;
        } else {
          this.isValid = value !== '' && value != null;
        }
      },
      immediate: true,
    },
  },
  methods: {
    changeItem(item) {
      this.$emit('update:modelValue', item);
      this.$emit('change', item);
    },
    focus() {
      (this.$refs.elSelect as HTMLElement).focus();
    },
    truncate(text) {
      const length = 40;
      if (text.length <= length) {
        return text;
      }
      return `${text.substring(0, length)}...`;
    },
    optionKey(item: TOption) {
      return `selectOption-${item[this.itemValue]}`;
    },
    optionAttrs(item: TOption) {
      return {
        label: this.truncate(item[this.itemText] as string),
        value: item[this.itemValue],
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.b-select {
  :deep(.el-input__wrapper) {
    min-height: var(--el-input-height, 40px);
  }
  &:has(+ .check-icon) {
    :deep(.el-select__placeholder) {
      width: calc(100% - 16px);
    }
  }
  &.is-flat {
    :deep(.el-input__inner) {
      border: none;
      background: none;
    }
    :deep(.el-input) {
      .el-input__wrapper {
        box-shadow: none !important;
      }
    }
  }
  &.is-underline {
    :deep(.el-input) {
      transition: all 0.3s ease;
      border-bottom: 1px solid $bdcolor-base;
      &.is-focus {
        border-bottom: 1px solid $bdcolor-active;
      }
      .el-input__wrapper {
        box-shadow: none !important;
      }
    }
    :deep(.el-input__inner) {
      border: none;
    }
  }

  &.is-fit {
    width: 100%;
  }
}
.b-select-wrapper {
  position: relative;
  width: 100%;
}
.check-icon {
  position: absolute;
  top: 0;
  right: $basespace-600;
  bottom: 0;
  display: flex;
  align-items: center;
  height: var(--el-component-size);

  .valid {
    color: $basecolor-success;
  }

  .invalid {
    color: $basecolor-error;
  }
}
</style>
