<template>
  <BLayout wrap>
    <BListItem class="mb-50">
      <template #header>
        <div class="mb-50">
          {{ $t('unsubscribe.directMail.title') }}
        </div>
        <div class="radio-group">
          <BRadio
            v-model="unsubscribeDirectMail"
            :label="false"
            :disabled="!isExistEmail"
            class="radio-item"
          >
            {{ $t(`unsubscribe.status.subscribe`) }}
          </BRadio>
          <BRadio
            v-model="unsubscribeDirectMail"
            :label="true"
            :disabled="!isExistEmail"
            class="radio-item"
          >
            {{ $t(`unsubscribe.status.unsubscribe`) }}
          </BRadio>
        </div>
      </template>
    </BListItem>
    <BListItem class="mb-200">
      <template #header>
        <div class="mb-50">
          {{ $t('unsubscribe.bulkMail.title') }}
        </div>
        <div class="radio-group">
          <BRadio
            v-model="unsubscribeBulkMail"
            :label="false"
            :disabled="!isExistEmail"
            class="radio-item"
          >
            {{ $t(`unsubscribe.status.subscribe`) }}
          </BRadio>
          <BRadio
            v-model="unsubscribeBulkMail"
            :label="true"
            :disabled="!isExistEmail"
            class="radio-item"
          >
            {{ $t(`unsubscribe.status.unsubscribe`) }}
          </BRadio>
        </div>
      </template>
    </BListItem>
    <BBtn
      type="primary"
      :disabled="!canSave"
      @click="handleSaveUnsubscribe"
    >
      <span>{{ $t('general.save.text') }}</span>
    </BBtn>
  </BLayout>
</template>

<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { TUnsubscribeMail } from '@/composable/user/directMail/unsubscribed-email';

type TProps = {
  callTargetEmail: string;
  unsubscribeMail: TUnsubscribeMail;
};

const props = defineProps<TProps>();
const emit = defineEmits([
  'unsubscribe:bulkMail',
  'subscribe:bulkMail',
  'unsubscribe:directMail',
  'subscribe:directMail',
]);

const unsubscribeDirectMail = ref(false);
const unsubscribeBulkMail = ref(false);

const isDirectMailUnsubscribed = computed(() =>
  props.unsubscribeMail?.directMail?.createdAt != null,
);

const isBulkMailUnsubscribed = computed(() =>
  props.unsubscribeMail?.bulkMail?.createdAt != null,
);

watch(() => props.unsubscribeMail, (newValue) => {
  unsubscribeDirectMail.value = isDirectMailUnsubscribed.value;
  unsubscribeBulkMail.value = isBulkMailUnsubscribed.value;
}, { immediate: true });

const isExistEmail = computed(() =>
  props.callTargetEmail.length > 0,
);

const canSave = computed(() =>
  isExistEmail.value
    && (
      isBulkMailUnsubscribed.value !== unsubscribeBulkMail.value
      || isDirectMailUnsubscribed.value !== unsubscribeDirectMail.value
    ),
);

const handleSaveUnsubscribe = () => {
  if (!isBulkMailUnsubscribed.value && unsubscribeBulkMail.value) {
    emit('unsubscribe:bulkMail');
  } else if (isBulkMailUnsubscribed.value && !unsubscribeBulkMail.value) {
    emit('subscribe:bulkMail');
  }
  if (!isDirectMailUnsubscribed.value && unsubscribeDirectMail.value) {
    emit('unsubscribe:directMail');
  } else if (isDirectMailUnsubscribed.value && !unsubscribeDirectMail.value) {
    emit('subscribe:directMail');
  }
};
</script>

<style lang="scss" scoped>
.radio-group {
  display: flex;
  justify-content: space-between;

  .radio-item {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
    padding: 4px 12px;
    gap: 6px;
    min-height: 40px;
    line-height: 24px;
    border-radius: var(--el-border-radius-base);
    background-color: var(--el-fill-color-blank);
    transition: var(--el-transition-duration);
    box-shadow: 0 0 0 1px var(--el-border-color) inset;

    &:not(:first-child) {
      margin-left: 20px;
    }
  }
}
</style>
