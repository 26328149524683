<template>
  <BDrawerMenu
    v-model="isOpen"
    class="trigger-action-form-container"
    width="600px"
    :title="$t('trigger.actionSetting.title')"
    @cancel="handleCancelClick"
  >
    <div class="trigger-action-form-body-container">
      <BListItem class="pb-100">
        <span class="fs-300 fw-bold">{{ $t('trigger.actionSetting.targetSelect') }}</span>
      </BListItem>
      <BListItem class="list-item">
        <template #header>
          <span>{{ $t('trigger.actionSetting.actionSelect') }}</span>
        </template>
        <BSelect
          v-model="internalTriggerAction.actionType"
          :items="groupedActionTypes"
          item-text="name"
          item-value="value"
          fit
          required
        />
      </BListItem>
      <BListItem class="list-item">
        <div
          v-if="!!internalTriggerAction.actionType && forms.length > 0"
          v-loading="loading"
          class="action-detail-container form"
        >
          <div
            v-for="form in forms"
            :key="form.actionDetailKey"
          >
            <template v-if="form.inputType === 'sequence_select'">
              <BListItem class="list-item">
                <template #header>
                  <span>{{ form.label }}</span>
                </template>
                <SequenceMasterSimpleSelect
                  v-model="(internalTriggerAction.actionDetail[form.actionDetailKey] as number | null)"
                />
              </BListItem>
            </template>
          </div>
        </div>
      </BListItem>
      <BListItem class="pt-200 pb-200">
        <span class="fs-300 fw-bold">{{ $t('trigger.actionSetting.approveUserSelect') }}</span>
      </BListItem>
      <BListItem class="list-item">
        <template #header>
          <span>{{ $t('trigger.actionSetting.approveUser') }}</span>
        </template>
        <SelectUser
          v-model="internalTriggerAction.approveUserId"
          :use-all="false"
          :clearable="true"
        />
      </BListItem>
    </div>
    <template #footer>
      <div
        class="footer"
      >
        <BLayout
          align-center
          justify-center
        >
          <BBtn
            class="mr-300 btn"
            text
            @click="handleCancelClick"
          >
            {{ $t('general.cancel.text') }}
          </BBtn>
          <BBtn
            class="ml-300 btn"
            type="primary"
            outline
            :disabled="!isFormValid"
            @click="handleSetClick"
          >
            {{ $t('general.setting.text') }}
          </BBtn>
        </BLayout>
      </div>
    </template>
  </BDrawerMenu>
</template>

<script lang="ts" setup>
import { ref, watch, nextTick, computed } from 'vue';
import { useTriggerAction, useTriggerActionForm } from '@/composable/user/trigger/trigger-action';
import SequenceMasterSimpleSelect from '../SequenceMasterSimpleSelect.vue';
import { TriggerActionBodyExt } from '../types';

type TEmits = {
  'click:cancel': [];
};
const emit = defineEmits<TEmits>();

const model = defineModel<TriggerActionBodyExt | null>();
const isOpen = defineModel<boolean>('isOpen', { default: false });

const { groupedActionTypes, makeInitialTriggerAction } = useTriggerAction();
const { forms, fetchForms, loading } = useTriggerActionForm();
const internalTriggerAction = ref<TriggerActionBodyExt>();

const clearInternalValues = () => {
  internalTriggerAction.value = makeInitialTriggerAction(model.value);
  forms.value = [];
};
clearInternalValues();

watch(() => internalTriggerAction.value.actionType, value => {
  if (value) fetchForms(value);
});

watch(() => isOpen.value, () => {
  nextTick(() => {
    clearInternalValues();
  });
});

const isFormValid = computed(() => {
  return !!internalTriggerAction.value.actionType
    && (
      forms.value.length === 0 || forms.value.every(form => {
        return !!internalTriggerAction.value.actionDetail[form.actionDetailKey];
      })
    );
});

const handleCancelClick = () => {
  emit('click:cancel');
};

const handleSetClick = () => {
  model.value = internalTriggerAction.value;
};
</script>
