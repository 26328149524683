<template>
  <div class="lead-view-list-container">
    <div
      v-infinite-scroll="readMore"
      class="lead-view-list"
      :infinite-scroll-disabled="scrollDisabled"
      @click.stop
    >
      <LeadViewCard
        v-for="leadView in innerLeadViews"
        :key="leadView.id"
        :lead-view="leadView"
        :selected-owner-id="editingOwnerIds.get(leadView.id)"
        :owner-editing="isLeadViewEditing(leadView.id)"
        :open-in-new-tab="false"
        :for-editing="editing"
        with-description
        owner-editable
        @before-link-to="handleBeforeLinkTo"
        @edit="() => handleLeadViewEdit(leadView)"
        @update:owner-id="$event => handleUpdateOwnerId(leadView, $event)"
        @update:owner-editing="$event => handleUpdateStartOwnerEditing(leadView, $event)"
        @after-delete="handleAfterDelete"
      />
      <BEmptyBox
        v-if="innerLeadViews.length === 0"
        class="empty-lead-view-list"
        display-only
      >
        {{ $t('home.leadViewList.empty') }}
      </BEmptyBox>
    </div>
  </div>
  <LeadViewUpdateModal
    :lead-view="selectedLeadView"
    @after-update="handleAfterUpdate"
  />
</template>

<script lang="ts" setup>
import { valueEquals } from 'element-plus';
import { computed, ref, watch } from 'vue';
import { LeadView } from '@/api/openapi';
import { useInfiniteScroll } from '@/composable/infinite-loading';
import { useModal } from '@/composable/modal';
import { useFilterParams } from '@/composable/user/leadList/lead-filter';
import LeadViewCard from '../LeadViewCard.vue';
import LeadViewUpdateModal from '../modal/leadView/LeadViewUpdateModal.vue';

type TProps = {
  leadViews: LeadView[];
};
type TEmit = {
  'afterUpdate': [];
  'afterDelete': [];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const INFINITE_SCROLL_LIMIT = 20;
const editingOwnerIds = defineModel<Map<number, number | null>>('editingOwnerIds', { required: true });
const selectedLeadView = ref<LeadView | undefined>();
const editing = computed(() => editingOwnerIds.value.size > 0);

const { openModal } = useModal(() => 'leadViewUpdateModal');
const { clearLeadListFilterParams } = useFilterParams();
const { readItems: innerLeadViews, scrollDisabled, readMore, reset } = useInfiniteScroll(() => props.leadViews, INFINITE_SCROLL_LIMIT);

watch(() => props.leadViews, () => {
  reset();
});

const isLeadViewEditing = (leadViewId: number) => {
  return editingOwnerIds.value.has(leadViewId);
};

const handleBeforeLinkTo = () => {
  clearLeadListFilterParams();
};
const handleLeadViewEdit = (leadView: LeadView) => {
  selectedLeadView.value = leadView;
  openModal();
};
const handleAfterUpdate = async () => {
  selectedLeadView.value = undefined;
  emit('afterUpdate');
};
const handleAfterDelete = () => {
  emit('afterUpdate');
};
const handleUpdateOwnerId = (leadView: LeadView, ownerId: number) => {
  editingOwnerIds.value.set(leadView.id, ownerId);
};
const handleUpdateStartOwnerEditing = (leadView: LeadView, editing: boolean) => {
  if (editing) {
    // NOTE: 「編集開始」の指示を受けた瞬間に初期化しているので、LeadViewCardに反映されるまで一瞬ラグあり（視認不可程度）
    editingOwnerIds.value.set(leadView.id, leadView.owner?.id);
  } else {
    editingOwnerIds.value.delete(leadView.id);
  }
};
</script>

<style lang="scss" scoped>
.lead-view-list {
  position: relative; // z-index調整用

  .lead-view-card {
    margin-top: $basespace-100;

    :deep(.title) {
      @include m-truncate-text-multi-line(3);
    }
    :deep(.description) {
      @include m-truncate-text-multi-line(1);
    }
  }

  .empty-lead-view-list {
    @include m-flex-center;
    width: 100%;
    padding: $basespace-400;
    margin-top: $basespace-100;
  }
}
</style>
