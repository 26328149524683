import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import {
  DirectMailUnsubscribeApi,
  DirectMailUnsubscribeApiGetUnsubscribedEmailRequest,
  DirectMailUnsubscribeApiUnsubscribeDirectMailRequest,
  DirectMailUnsubscribeApiSubscribeDirectMailRequest,
  GetUnsubscribedEmail200Response,
} from '@/api/openapi';

export class DirectMailUnsubscribedEmailService extends ApiService {
  api = new DirectMailUnsubscribeApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getUnsubscribedEmail(
    {
      request,
      options,
      errorHandlers,
    }: ApiArguments<DirectMailUnsubscribeApiGetUnsubscribedEmailRequest>,
  ): Promise<AxiosResponse<GetUnsubscribedEmail200Response>> {
    return this.checkResponse<GetUnsubscribedEmail200Response>(
      this.api.getUnsubscribedEmail(request, options),
      errorHandlers,
    );
  }

  async unsubscribe({
    request,
    options,
    errorHandlers,
  }: ApiArguments<DirectMailUnsubscribeApiUnsubscribeDirectMailRequest>) {
    return this.checkResponse<void>(
      this.api.unsubscribeDirectMail(request, options),
      errorHandlers,
    );
  }

  async subscribe({
    request,
    options,
    errorHandlers,
  }: ApiArguments<DirectMailUnsubscribeApiSubscribeDirectMailRequest>) {
    return this.checkResponse<void>(
      this.api.subscribeDirectMail(request, options),
      errorHandlers,
    );
  }
}
