<template>
  <BListItem class="block-title">
    <span>{{ $t('leadExport.exportFormatBlock') }}</span>
  </BListItem>
  <BListItem class="list-item">
    <ElDescriptions
      :column="1"
      border
    >
      <ElDescriptionsItem>
        <template #label>
          <span>{{ $t('leadExport.fileType.title') }}</span>
        </template>
        <div class="radio-group">
          <BRadio
            v-for="fileType in ExportFileType"
            :key="fileType"
            v-model="model.fileType"
            :label="fileType"
            class="radio-item"
          >
            {{ $t(`leadExport.fileType.enum.${camelCase(fileType)}`) }}
          </BRadio>
        </div>
      </ElDescriptionsItem>
      <ElDescriptionsItem>
        <template #label>
          <span>{{ $t('leadExport.dataFormat.title') }}</span>
        </template>
        <div class="radio-group">
          <BRadio
            v-for="dataFormat in ExportDataFormat"
            :key="dataFormat"
            v-model="model.dataFormat"
            :label="dataFormat"
            class="radio-item"
          >
            {{ $t(`leadExport.dataFormat.enum.${camelCase(dataFormat)}`) }}
          </BRadio>
        </div>
      </ElDescriptionsItem>
      <ElDescriptionsItem>
        <template #label>
          <div>{{ $t('leadExport.writeTo') }}</div>
          <div>{{ $t('general.parentheses', { item: $t('general.optional') }) }}</div>
        </template>
        <BInput
          v-model="model.writeTo"
          v-model:valid="writeToValid"
          fit
          :rules="[writeToRule]"
        />
        <div class="description">
          {{ $t('leadExport.writeToDescription') }}
        </div>
      </ElDescriptionsItem>
    </ElDescriptions>
  </BListItem>
</template>

<script lang="ts" setup>
import { camelCase } from 'lodash';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { ExportDataFormat, ExportFileType, PartLeadExportSettingCommon } from '@/api/openapi';
import { useInputValidations } from '@/composable/input-validations';

type TEmit = {
  'update:valid': [valid: boolean];
};

const emit = defineEmits<TEmit>();
const model = defineModel<PartLeadExportSettingCommon>();

const i18n = useI18n();
const { makeRegexRule } = useInputValidations();
const writeToRule = makeRegexRule(/^[a-zA-Z\d\-_]+$/, i18n.t('leadExport.writeToValidation'));

const writeToValid = ref(false);

const formValid = computed(() => {
  return model.value.fileType !== null && model.value.dataFormat !== null && writeToValid.value;
});

watch(formValid, (newVal) => {
  emit('update:valid', newVal);
}, { immediate: true });
</script>

<style lang="scss" scoped>
.description {
  margin-top: $basespace-50;
  padding-left: $basespace-50;
  font-size: $fontsize-100;
}
</style>
