<template>
  <div class="b-editable-content">
    <template v-if="internalEditing">
      <div class="input-box">
        <div class="b-input-box">
          <BBooleanSelect
            v-model="internalValue"
            :disabled="loading"
          />
          <div class="btn-area">
            <BLayout align-center>
              <BBtn
                v-if="valueChanged"
                class="mr-50"
                size="small"
                fab
                flat
                :loading="loading"
                :data-test="`${dataTest}Btn`"
                @click="check"
              >
                <BIcon
                  class="check-icon"
                  size="small"
                >
                  check
                </BIcon>
              </BBtn>
              <BBtn
                size="small"
                fab
                flat
                :disabled="loading"
                @click="close"
              >
                <BIcon
                  class="close-icon"
                  size="small"
                >
                  close
                </BIcon>
              </BBtn>
            </BLayout>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="item input"
        :data-test="dataTest"
        @click="handleClickEdit"
      >
        {{ value ? $t('customField.boolean.true') : $t('customField.boolean.false') }}
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';

type TProps = {
  required?: boolean;
  hyphen?: boolean;
  loading?: boolean;
  editing?: boolean;
  value?: boolean;
  dataTest?: string;
};
const props = withDefaults(defineProps<TProps>(), {
  value: false,
  editing: false,
});

const emit = defineEmits<{
  'update:editing': [value: boolean];
  'check': [value: boolean];
  'cancel': [];
}>();

const newValue = ref(props.value);
const internalEditing = ref(props.editing);
const valueChanged = ref(false);

const internalValue = computed({
  get: () => newValue.value,
  set: (val: boolean) => {
    newValue.value = val;
    valueChanged.value = true;
  },
});

// ウォッチャー
watch(() => props.loading, (newVal) => {
  if (!newVal) {
    internalEditing.value = false;
  }
});

watch(() => props.editing, (newVal) => {
  internalEditing.value = newVal;
});

watch(internalEditing, (newVal) => {
  emit('update:editing', newVal);
});

// メソッド
const handleClickEdit = () => {
  valueChanged.value = true;
  internalEditing.value = true;
};

const check = () => {
  emit('check', newValue.value);
};

const close = () => {
  emit('cancel');
  newValue.value = props.value ?? false;
  internalEditing.value = false;
};
</script>

<style lang="scss" scoped>
  .b-editable-content {
    width: 100%;
  }

  .item {
    width: 100%;
    border: 1px solid transparent;
    padding: $basespace-50 $basespace-100;
    margin-left: -$basespace-100;

    &:hover {
      border-color: $bdcolor-light;
      cursor: pointer;
    }

    &.input:hover {
      border-radius: 4px;
    }
  }

  .input-box {
    margin-left: -$basespace-100;
  }

  .b-input-box {
    display: flex;
    align-items: center;
    gap: $basespace-100;
  }

  .check-icon {
    color: $basecolor-primary;
  }

  .close-icon {
    color: $textcolor-light;
  }
</style>
