<template>
  <div
    class="lead-view-card"
    :class="{ clickable: !editing }"
    @click="handleClickLeadView"
  >
    <div class="content">
      <div class="title">
        {{ leadView.name }}
      </div>
      <div
        v-if="withDescription"
        class="description"
      >
        {{ leadView.description }}
      </div>
      <div class="created-at">
        {{ updatedAt }}
      </div>
    </div>
    <div
      class="user-info"
      @click.stop
    >
      <template v-if="ownerEditable">
        <EditableSelectUser
          :key="leadView.id"
          v-model:editing="ownerEditing"
          :model-value="ownerId"
          :user-for-display="leadView.owner"
          editable
          with-avatar
          @update:model-value="handleUpdateOwnerId"
        />
      </template>
      <template v-else>
        <UserAvatar
          :user="leadView.owner"
        />
      </template>
    </div>
    <div
      class="actions"
      @click.stop
    >
      <BMoreMenu
        trigger="hover"
        z-index100
        :disabled="editing"
      >
        <BBtn
          flat
          fit
          size="small"
          @click.stop="handleEditClick"
        >
          {{
            $t('general.edit.do')
          }}
        </BBtn>
        <BPopover
          v-if="leadView.isUsedByReservedBulkMail"
          placement="top"
          width="240"
          trigger="hover"
        >
          <template #reference>
            <div>
              <BBtn
                flat
                fit
                size="small"
                disabled
              >
                {{ $t('general.delete.do') }}
              </BBtn>
            </div>
          </template>
          <div>{{ $t('mail.alreadyUsedReserve') }}</div>
        </BPopover>
        <BBtn
          v-else
          flat
          fit
          size="small"
          @click.stop="handleDeleteClick"
        >
          {{ $t('general.delete.do') }}
        </BBtn>
      </BMoreMenu>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { LeadView } from '@/api/openapi';
import { LeadViewApiService } from '@/api/user/resources/lead_view';
import { LeadViewLogApiService } from '@/api/user/resources/lead_view_log';
import { useConfirm } from '@/composable/message-dialog';
import { useRouterLink } from '@/composable/router-link';
import { useWait } from '@/composable/vue-wait';
import { formatDateTime } from '@/utils/date-time';

type TProps = {
  leadView: LeadView;
  withDescription?: boolean;
  ownerEditable?: boolean;
  openInNewTab?: boolean;
  forEditing?: boolean;
};
type TEmit = {
  'edit': [];
  'beforeLinkTo': [];
  'afterDelete': [];
  'update:ownerId': [ownerId: number | null];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

// NOTE: ownerId, selectedOwnerIdまとめたらダメだっけ
const selectedOwnerId = defineModel<number | null>('selectedOwnerId', { required: false });
const ownerEditing = defineModel<boolean>('ownerEditing', { default: false });
const ownerId = computed(() => ownerEditing.value ? selectedOwnerId.value : props.leadView.owner?.id);
const updatedAt = computed(() => {
  return formatDateTime(props.leadView.updatedAt);
});
const editing = computed(() => props.forEditing || ownerEditing.value);

const { confirm } = useConfirm();
const { t } = useI18n();
const { doActionWithWait } = useWait();
const { linkToOrReload } = useRouterLink();
const api = new LeadViewApiService();
const logApi = new LeadViewLogApiService();

const handleEditClick = () => {
  emit('edit');
};

const handleDeleteClick = async () => {
  const check = await confirm({
    title: t('general.confirm.text'),
    message: t('leadView.deleteCaution'),
  });
  if (!check) {
    return;
  }
  await doActionWithWait('deleteLeadViewWait', async () => {
    await api.deleteLeadView({
      request: {
        id: props.leadView.id,
      },
    });
  });
  emit('afterDelete');
};

const handleClickLeadView = async () => {
  if (editing.value) return;

  emit('beforeLinkTo');
  // NOTE: 同窓かつ同じURLの場合リロード
  linkToOrReload({ name: 'LeadList', query: { leadViewId: props.leadView.id } }, props.openInNewTab);

  await logApi.postLeadViewLog({
    request: {
      leadViewLogBody: {
        leadViewId: props.leadView.id,
        event: 'click',
      },
    },
  });
};

const handleUpdateOwnerId = async (userId: number | null) => {
  selectedOwnerId.value = userId;
  emit('update:ownerId', userId);
};
</script>

<style lang="scss" scoped>
.lead-view-card {
  @include m-flex-center;
  padding: $basespace-400;
  border: 1px solid $bdcolor-light;
  border-radius: $border-radius-s;
  background-color: $bgcolor-white;

  &.clickable {
    cursor: pointer;
  }

  .content {
    flex: 1;
    margin: auto 0;

    .title {
      margin-bottom: $basespace-50;
    }
    .description {
      color: $textcolor-light;
      font-size: $fontsize-100;
      margin-bottom: 2px;
    }
    .created-at {
      color: $textcolor-light;
      font-size: $fontsize-100;
    }
  }
  .user-info {
    @include m-flex-center;
    padding-left: $basespace-200;
  }
  .actions {
    @include m-flex-center;
    padding-left: $basespace-50;
  }
}
</style>
