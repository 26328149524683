<template>
  <div class="b-boolean-select">
    <div
      class="b-input-box"
      :class="classes"
    >
      <BRadio
        v-model="modelValue"
        :disabled="disabled"
        :label="true"
      >
        {{ $t('customField.boolean.true') }}
      </BRadio>

      <BRadio
        v-model="modelValue"
        :disabled="disabled"
        :label="false"
      >
        {{ $t('customField.boolean.false') }}
      </BRadio>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

type TProps = {
  modelValue: boolean;
  disabled?: boolean;
  layout?: 'horizontal' | 'vertical';
};

const props = withDefaults(defineProps<TProps>(), {
  modelValue: false,
  disabled: false,
  layout: 'horizontal'
});

const emit = defineEmits<{
  'update:modelValue': [value: boolean];
}>();

const classes = computed(() => ({
  'b-input-box-horiz': props.layout === 'horizontal',
  'b-input-box-vert': props.layout === 'vertical',
}));

const modelValue = computed({
  get: () => props.modelValue,
  set: (val: boolean) => emit('update:modelValue', val)
});
</script>

<style lang="scss" scoped>
.b-boolean-select {
  width: 100%;
}

.b-input-box-vert {
  display: flex;
  flex-direction: column;
  gap: $basespace-100;
}

.b-input-box-horiz {
  display: flex;
  align-items: center;
  gap: $basespace-100;
}
</style>
