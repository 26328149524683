import { capitalize, cloneDeep } from 'lodash';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useWait } from '@/composable/vue-wait';
import { key } from '@/store';
import { camelcaseKeys, isEmptyObject, isObject } from '@/utils/object';
import { useVuexCallTargets } from './call-target';

type TFilterIncExc = 'inc' | 'exc';
type TFilterAndOr = 'and' | 'or';
const FILTER_TYPE_ENUM = {
  incOr: 'incOr',
  excOr: 'excOr',
  incAnd: 'incAnd',
  // NOTE: 現状未実装
  // excAnd: 'excAnd',
} as const;
type TFilterType = typeof FILTER_TYPE_ENUM[keyof typeof FILTER_TYPE_ENUM];

type TFilterParamInner<T = unknown> = { and: T } | { or: T };
type TFilterParam<T = unknown> = { inc: TFilterParamInner<T> } | { exc: TFilterParamInner<T> };
type TFilterParams<T = unknown> = {
  customFieldItems?: Record<string, TFilterParam<T>>;
} & Record<string, TFilterParam<T>>;

// NOTE: { hoge: { inc: { or: [xx, xxx] } } }のような値を処理するための関数
const getObjectFirstValue = <T>(obj: unknown): T | null =>
  isObject(obj) ? Object.values(obj)[0] as T : null;
const getObjectFirstKey = <T>(obj: unknown): T | null =>
  isObject(obj) ? Object.keys(obj)[0] as T : null;
const getIncExc = (filterParam: TFilterParam): TFilterIncExc | null => {
  return getObjectFirstKey<TFilterIncExc>(filterParam);
};
const getAndOr = (filterParam: TFilterParam): TFilterAndOr | null => {
  return getObjectFirstKey<TFilterAndOr>(getObjectFirstValue(filterParam));
};
const getFilterParamValue = <T>(filterParam: TFilterParam): T | null => {
  return getObjectFirstValue<T>(getObjectFirstValue(filterParam));
};

const isCustomFieldKey = (key: string) => {
  return key.includes('custom_field_');
};
const camelcaseFilterParamsKeys = (filterParams: unknown) => {
  return camelcaseKeys(filterParams, { exclude: [/^custom_field_.*[^items]/] });
};


// *************************************************************
// for FilterItem.vue
// *************************************************************

const useVuexFilterParams = () => {
  const store = useStore(key);
  const { doActionWithWait } = useWait();

  const selectedFilterItemKey = computed<string | null>(() => store.getters['userUi/selectedFilterDetailKeyName']);
  const leadListFilterParams = computed<TFilterParams>(() => store.getters['userUi/leadListFilterParams']);

  const setSelectedFilterDetailKeyName = (filterItemKey: string | null) => doActionWithWait('setSelectedFilterDetailKeyNameWait', async () => {
    await store.dispatch('userUi/setSelectedFilterDetailKeyNameAction', filterItemKey);
  });
  const clearSelectedFilterItem = () => setSelectedFilterDetailKeyName(null);
  const setSelectedFilterItem = (filterItemKey: string) => setSelectedFilterDetailKeyName(filterItemKey);

  const setLeadListFilterParams = (filterParams: TFilterParams) => doActionWithWait('setLeadListFilterParamsWait', async () => {
    await store.dispatch('userUi/setLeadListFilterParamsAction', filterParams);
  });
  const clearLeadListFilterParams = () => setLeadListFilterParams({});

  return {
    leadListFilterParams,
    selectedFilterItemKey,
    clearSelectedFilterItem,
    setSelectedFilterItem,
    setLeadListFilterParams,
    clearLeadListFilterParams,
  };
};

const useFilterParams = () => {
  const { fetchCallTargetsAndMeta } = useVuexCallTargets();
  const { leadListFilterParams, clearSelectedFilterItem, setLeadListFilterParams, clearLeadListFilterParams } = useVuexFilterParams();

  const isEmptyFilter = (filter: TFilterParam | undefined | null): boolean => {
    if (filter == null) return true;

    const incExc = getIncExc(filter);
    const andOr = getAndOr(filter);
    const filterValue = filter[incExc]?.[andOr];
    return (
      (filterValue == null || filterValue.length === 0)
      && filterValue !== false
    );
  };

  const removeFilterItem = (newFilterParams: TFilterParams, removeItemKey: string) => {
    if (isCustomFieldKey(removeItemKey)) {
      return removeCustomFieldFilterItem(newFilterParams, removeItemKey);
    }
    return removeSingleFilterItem(newFilterParams, removeItemKey);
  };
  const removeSingleFilterItem = (newFilterParams: TFilterParams, removeItemKey: string) => {
    const isEmptyFilterData = isEmptyFilter(newFilterParams[removeItemKey]);
    delete newFilterParams[removeItemKey];
    return isEmptyFilterData;
  };
  const removeCustomFieldFilterItem = (newFilterParams: TFilterParams, removeItemKey: string) => {
    const isEmptyFilterData = isEmptyFilter(newFilterParams.customFieldItems[removeItemKey]);
    delete newFilterParams.customFieldItems[removeItemKey];

    if (isEmptyObject(newFilterParams.customFieldItems)) {
      delete newFilterParams.customFieldItems;
    }
    return isEmptyFilterData;
  };

  const updateFilterParamsAndFetchLeadList = (newFilterParams: TFilterParams, willFetchLeadList: boolean) => {
    setLeadListFilterParams(newFilterParams);
    clearSelectedFilterItem();
    if (willFetchLeadList) {
      fetchCallTargetsAndMeta();
    }
  };

  const removeFilterItemAndFetchLeadList = (removeItemKey: string) => {
    const newFilterParams = cloneDeep(leadListFilterParams.value);
    const isEmptyFilterData = removeFilterItem(newFilterParams, removeItemKey);

    // 空のフィルタでなかった場合のみリードを再取得する
    updateFilterParamsAndFetchLeadList(newFilterParams, !isEmptyFilterData);
  };
  /**
   * FIXME: 現状複数キーを受け取る実装となっているが、複数項目を含むフィルタを一括で削除する用途。よって、最終的には「複数項目を含むフィルタ（親）」のキーを1つ受け取って処理するように実装変更した方が良さげ
   */
  const removeMultipleFilterItem = (removeItemKeys: string[]) => {
    const newFilterParams = cloneDeep(leadListFilterParams.value);
    const isEmptyFilterData = removeItemKeys.map((key) => {
      return removeSingleFilterItem(newFilterParams, key);
    }).every((isEmpty) => isEmpty);

    // 空のフィルタでなかった場合のみリードを再取得する
    updateFilterParamsAndFetchLeadList(newFilterParams, !isEmptyFilterData);
  };

  return {
    removeFilterItemAndFetchLeadList,
    removeMultipleFilterItem,
    clearLeadListFilterParams,
  };
};

const useFilterItemSelect = (getFilterItemKey: () => string, getReadonly: () => boolean) => {
  const { selectedFilterItemKey, clearSelectedFilterItem, setSelectedFilterItem } = useVuexFilterParams();

  const isSelectedItem = computed(() => {
    return selectedFilterItemKey.value === getFilterItemKey();
  });
  const filterItemPopoverVisible = computed<boolean>({
    get: () => {
      if (getReadonly()) return false;
      return isSelectedItem.value;
    },
    set: (newVal) => {
      if (getReadonly()) return;
      if (isSelectedItem.value === newVal) return;
  
      if (isSelectedItem.value) {
        clearFilterItemSelected();
      } else {
        setFilterItemSelected();
      }
    },
  });

  const setFilterItemSelected = async () => {
    await setSelectedFilterItem(getFilterItemKey());
  };
  const clearFilterItemSelected = async () => {
    await clearSelectedFilterItem();
  };
  const closeFilterItemPopover = () => {
    filterItemPopoverVisible.value = false;
  };

  return {
    filterItemPopoverVisible,
    closeFilterItemPopover,
  };
};

/*
TODO: フィルタの値と条件の処理の分離について
フィルタの値と条件の処理は本来無関係なので分離したい。
が、現状フィルタの値と条件の処理がobjectの形式により密結合しているため不可能。
処理のリファクタは、objectの形式のリファクタと合わせて対応する必要がある。
（※objectの現在の形式: { [incExc]: { [andOr]: [xx, xxx] } }）
（※objectにおける分離は例えばこう: { type: 'and' | 'or', include: 'inc' | 'exc', value: [xx, xxx] }）
*/
const useFilterTypes = (getFilterType: () => TFilterType) => {
  const i18n = useI18n();

  const filterTypes = computed(() => {
    return Object.values(FILTER_TYPE_ENUM).map((type) => ({
      text: `${i18n.t(`leadView.filterType.${type}`)}`,
      value: type,
    }));
  });
  const filterTypeForDisplay = computed(() => {
    return filterTypes.value.find((type) => type.value === getFilterType())?.text;
  });

  return {
    filterTypes,
    filterTypeForDisplay,
  };
};


// *************************************************************
// for フィルタ項目の各component
// *************************************************************

const useFilterParam = <T>(getFilterParm: () => TFilterParam<T>) => {
  const filterType = ref<TFilterType>('incOr');

  watch(getFilterParm, (newVal, oldVal) => {
    if (newVal === oldVal) return;
    if (newVal == null) return;

    const incExc = getIncExc(newVal);
    const andOr = getAndOr(newVal);
    filterType.value = `${incExc}${capitalize(andOr)}` as TFilterType;
  }, { immediate: true });

  const incExc = computed<TFilterIncExc>(() => {
    return filterType.value.startsWith('inc') ? 'inc' : 'exc';
  });
  const andOr = computed<TFilterAndOr>(() => {
    return filterType.value.endsWith('And') ? 'and' : 'or';
  });
  const filterValue = computed<T>(() => {
    return getFilterParamValue(getFilterParm());
  });

  const makeFilterParam = (filterValue: T) => {
    return { [incExc.value]: { [andOr.value]: filterValue } } as TFilterParam<T>;
  };

  return {
    filterType,
    filterValue,
    makeFilterParam,
  };
};

const useGeneralFilterItem = <T extends TFilterParam>(getLeadListFilterParams: () => TFilterParams, getFilterItemKey: () => string) => {
  const filterParam = computed<T>(() => {
    return getLeadListFilterParams()[getFilterItemKey()] as T;
  });

  return {
    filterParam,
  };
};

const useCustomFieldFilterItem = <T extends TFilterParam>(getLeadListFilterParams: () => TFilterParams, getFilterItemKey: () => string) => {
  const filterParam = computed<T>(() => {
    return getLeadListFilterParams().customFieldItems[getFilterItemKey()] as T;
  });

  return {
    filterParam,
  };
};

export type {
  TFilterType,
  TFilterParam,
  TFilterParams,
};
export {
  useFilterParams,
  useFilterItemSelect,
  useFilterTypes,
  useFilterParam,
  useGeneralFilterItem,
  useCustomFieldFilterItem,
  // FIXME: そのうちこのcomposableを使う実装に統一し、exportを削除する
  getIncExc,
  getFilterParamValue,
  camelcaseFilterParamsKeys,
  isCustomFieldKey,
};
