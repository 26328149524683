<template>
  <FilterDetailPopover
    :title="title"
    :is-display-only="isDisplayOnly"
    :key-name="keyName"
    @hide="$emit('hide', target)"
    @show="popoverShow"
  >
    <template #reference>
      <BLayout align-center>
        <BListItem>
          <template #header>
            <span>{{ title }}</span>
          </template>
          <div>{{ displayValue }}</div>
        </BListItem>
      </BLayout>
    </template>
    <div>
      <FilterSwitchBtn v-model="target.inc.or" :trueText="$t('customField.boolean.true')" :falseText="$t('customField.boolean.false')"/>
    </div>
  </FilterDetailPopover>
</template>

<script>
import lodash from 'lodash';
import { mapGetters } from 'vuex';
import FilterDetailPopover from '@/components/organisms/user/leadList/filter/FilterDetailPopover.vue';
import { getIncExc } from '@/composable/user/leadList/lead-filter';
import FilterSwitchBtn from '@/components/organisms/user/FilterSwitchBtn.vue';

export default {
  components: {
    FilterDetailPopover,
    FilterSwitchBtn
  },
  props: {
    title: String,
    value: {
      type: Boolean,
      default() {
        return true;
      }
    },
    customFieldItem: {
      type: Object,
      default () {
        return { inc: { or: null } };
      },
    },
    keyName: {
      type: String,
      require: true,
    },
    isDisplayOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'hide',
  ],
  data () {
    return {
      switchValue: { inc: { or: null } },
      internalCustomFieldItem: null,
      isInclude: getIncExc(this.target) !== 'exc',
    };
  },
  computed: {
    ...mapGetters('user', [
      'customFields',
    ]),
    ...mapGetters('userUi', [
      'leadListFilterParams',
    ]),
    displayValue() {
      if (this.value === null || this.value === undefined) return '-';
      return this.value ? this.$t('customField.boolean.true') : this.$t('customField.boolean.false')
    },
    groupingText () {
      if (this.target == null || this.targetDetail.length === 0) return;
      return this.$t(`general.filter.${this.incExc}`);
    },
    target () {
      return this.customFieldItem;
    },
    targetDetail () {
      if (this.target == null) return [];
      return this.target[this.incExc].or;
    },
    incExc () {
      return getIncExc(this.target);
    },
    grouping () {
      return this.isInclude ? 'inc' : 'exc';
    }
  },
  methods: {
    popoverShow () {
      this.target = this.target?.inc?.or === null ? { inc: { or: true } } : lodash.cloneDeep(this.target);
    },
    hide() {

      this.$emit('hide', { [this.grouping]: { or: this.internalCustomFieldItem } });
    },
  },
};
</script>

<style lang="scss" scoped>
.grouping-text {
  color: $basecolor-cold;
  margin-left: 2px;

  &.is-include {
    color: $basecolor-hot;
  }

  &.inc-text {
    min-width: 24px;
  }

  &.exc-text {
    min-width: 48px;
  }
}
</style>
