/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FilterCondition } from '../models';
// @ts-ignore
import { GetNextActions200Response } from '../models';
/**
 * NextActionApi - axios parameter creator
 * @export
 */
export const NextActionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 次のアクション一覧取得API
         * @summary 次のアクション一覧取得API
         * @param {number} [page] infinityLoading用ページ番号
         * @param {boolean} [isDone] 完了フラグ
         * @param {Array<string>} [ownerName] 所有者名
         * @param {FilterCondition} [ownerNameCondition] 所有者名条件
         * @param {Array<string>} [actionTypeName] 次のアクションタイプ名
         * @param {FilterCondition} [actionTypeNameCondition] 次のアクションタイプ名名条件
         * @param {Array<string>} [priorityName] 優先度名
         * @param {FilterCondition} [priorityNameCondition] 優先度名条件
         * @param {boolean} [reservedAtNull] 予定日なし
         * @param {string} [reservedAtFrom] 予定日(From)
         * @param {string} [reservedAtTo] 予定日(To)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextActions: async (page?: number, isDone?: boolean, ownerName?: Array<string>, ownerNameCondition?: FilterCondition, actionTypeName?: Array<string>, actionTypeNameCondition?: FilterCondition, priorityName?: Array<string>, priorityNameCondition?: FilterCondition, reservedAtNull?: boolean, reservedAtFrom?: string, reservedAtTo?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/next_actions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (isDone !== undefined) {
                localVarQueryParameter['isDone'] = isDone;
            }

            if (ownerName) {
                localVarQueryParameter['ownerName[]'] = ownerName;
            }

            if (ownerNameCondition !== undefined) {
                localVarQueryParameter['ownerNameCondition'] = ownerNameCondition;
            }

            if (actionTypeName) {
                localVarQueryParameter['actionTypeName[]'] = actionTypeName;
            }

            if (actionTypeNameCondition !== undefined) {
                localVarQueryParameter['actionTypeNameCondition'] = actionTypeNameCondition;
            }

            if (priorityName) {
                localVarQueryParameter['priorityName[]'] = priorityName;
            }

            if (priorityNameCondition !== undefined) {
                localVarQueryParameter['priorityNameCondition'] = priorityNameCondition;
            }

            if (reservedAtNull !== undefined) {
                localVarQueryParameter['reservedAtNull'] = reservedAtNull;
            }

            if (reservedAtFrom !== undefined) {
                localVarQueryParameter['reservedAtFrom'] = reservedAtFrom;
            }

            if (reservedAtTo !== undefined) {
                localVarQueryParameter['reservedAtTo'] = reservedAtTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NextActionApi - functional programming interface
 * @export
 */
export const NextActionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NextActionApiAxiosParamCreator(configuration)
    return {
        /**
         * 次のアクション一覧取得API
         * @summary 次のアクション一覧取得API
         * @param {number} [page] infinityLoading用ページ番号
         * @param {boolean} [isDone] 完了フラグ
         * @param {Array<string>} [ownerName] 所有者名
         * @param {FilterCondition} [ownerNameCondition] 所有者名条件
         * @param {Array<string>} [actionTypeName] 次のアクションタイプ名
         * @param {FilterCondition} [actionTypeNameCondition] 次のアクションタイプ名名条件
         * @param {Array<string>} [priorityName] 優先度名
         * @param {FilterCondition} [priorityNameCondition] 優先度名条件
         * @param {boolean} [reservedAtNull] 予定日なし
         * @param {string} [reservedAtFrom] 予定日(From)
         * @param {string} [reservedAtTo] 予定日(To)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNextActions(page?: number, isDone?: boolean, ownerName?: Array<string>, ownerNameCondition?: FilterCondition, actionTypeName?: Array<string>, actionTypeNameCondition?: FilterCondition, priorityName?: Array<string>, priorityNameCondition?: FilterCondition, reservedAtNull?: boolean, reservedAtFrom?: string, reservedAtTo?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNextActions200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNextActions(page, isDone, ownerName, ownerNameCondition, actionTypeName, actionTypeNameCondition, priorityName, priorityNameCondition, reservedAtNull, reservedAtFrom, reservedAtTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NextActionApi - factory interface
 * @export
 */
export const NextActionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NextActionApiFp(configuration)
    return {
        /**
         * 次のアクション一覧取得API
         * @summary 次のアクション一覧取得API
         * @param {number} [page] infinityLoading用ページ番号
         * @param {boolean} [isDone] 完了フラグ
         * @param {Array<string>} [ownerName] 所有者名
         * @param {FilterCondition} [ownerNameCondition] 所有者名条件
         * @param {Array<string>} [actionTypeName] 次のアクションタイプ名
         * @param {FilterCondition} [actionTypeNameCondition] 次のアクションタイプ名名条件
         * @param {Array<string>} [priorityName] 優先度名
         * @param {FilterCondition} [priorityNameCondition] 優先度名条件
         * @param {boolean} [reservedAtNull] 予定日なし
         * @param {string} [reservedAtFrom] 予定日(From)
         * @param {string} [reservedAtTo] 予定日(To)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNextActions(page?: number, isDone?: boolean, ownerName?: Array<string>, ownerNameCondition?: FilterCondition, actionTypeName?: Array<string>, actionTypeNameCondition?: FilterCondition, priorityName?: Array<string>, priorityNameCondition?: FilterCondition, reservedAtNull?: boolean, reservedAtFrom?: string, reservedAtTo?: string, options?: any): AxiosPromise<GetNextActions200Response> {
            return localVarFp.getNextActions(page, isDone, ownerName, ownerNameCondition, actionTypeName, actionTypeNameCondition, priorityName, priorityNameCondition, reservedAtNull, reservedAtFrom, reservedAtTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NextActionApi - interface
 * @export
 * @interface NextActionApi
 */
export interface NextActionApiInterface {
    /**
     * 次のアクション一覧取得API
     * @summary 次のアクション一覧取得API
     * @param {number} [page] infinityLoading用ページ番号
     * @param {boolean} [isDone] 完了フラグ
     * @param {Array<string>} [ownerName] 所有者名
     * @param {FilterCondition} [ownerNameCondition] 所有者名条件
     * @param {Array<string>} [actionTypeName] 次のアクションタイプ名
     * @param {FilterCondition} [actionTypeNameCondition] 次のアクションタイプ名名条件
     * @param {Array<string>} [priorityName] 優先度名
     * @param {FilterCondition} [priorityNameCondition] 優先度名条件
     * @param {boolean} [reservedAtNull] 予定日なし
     * @param {string} [reservedAtFrom] 予定日(From)
     * @param {string} [reservedAtTo] 予定日(To)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextActionApiInterface
     */
    getNextActions(page?: number, isDone?: boolean, ownerName?: Array<string>, ownerNameCondition?: FilterCondition, actionTypeName?: Array<string>, actionTypeNameCondition?: FilterCondition, priorityName?: Array<string>, priorityNameCondition?: FilterCondition, reservedAtNull?: boolean, reservedAtFrom?: string, reservedAtTo?: string, options?: AxiosRequestConfig): AxiosPromise<GetNextActions200Response>;

}

/**
 * Request parameters for getNextActions operation in NextActionApi.
 * @export
 * @interface NextActionApiGetNextActionsRequest
 */
export interface NextActionApiGetNextActionsRequest {
    /**
     * infinityLoading用ページ番号
     * @type {number}
     * @memberof NextActionApiGetNextActions
     */
    readonly page?: number

    /**
     * 完了フラグ
     * @type {boolean}
     * @memberof NextActionApiGetNextActions
     */
    readonly isDone?: boolean

    /**
     * 所有者名
     * @type {Array<string>}
     * @memberof NextActionApiGetNextActions
     */
    readonly ownerName?: Array<string>

    /**
     * 所有者名条件
     * @type {FilterCondition}
     * @memberof NextActionApiGetNextActions
     */
    readonly ownerNameCondition?: FilterCondition

    /**
     * 次のアクションタイプ名
     * @type {Array<string>}
     * @memberof NextActionApiGetNextActions
     */
    readonly actionTypeName?: Array<string>

    /**
     * 次のアクションタイプ名名条件
     * @type {FilterCondition}
     * @memberof NextActionApiGetNextActions
     */
    readonly actionTypeNameCondition?: FilterCondition

    /**
     * 優先度名
     * @type {Array<string>}
     * @memberof NextActionApiGetNextActions
     */
    readonly priorityName?: Array<string>

    /**
     * 優先度名条件
     * @type {FilterCondition}
     * @memberof NextActionApiGetNextActions
     */
    readonly priorityNameCondition?: FilterCondition

    /**
     * 予定日なし
     * @type {boolean}
     * @memberof NextActionApiGetNextActions
     */
    readonly reservedAtNull?: boolean

    /**
     * 予定日(From)
     * @type {string}
     * @memberof NextActionApiGetNextActions
     */
    readonly reservedAtFrom?: string

    /**
     * 予定日(To)
     * @type {string}
     * @memberof NextActionApiGetNextActions
     */
    readonly reservedAtTo?: string
}

/**
 * NextActionApi - object-oriented interface
 * @export
 * @class NextActionApi
 * @extends {BaseAPI}
 */
export class NextActionApi extends BaseAPI implements NextActionApiInterface {
    /**
     * 次のアクション一覧取得API
     * @summary 次のアクション一覧取得API
     * @param {NextActionApiGetNextActionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NextActionApi
     */
    public getNextActions(requestParameters: NextActionApiGetNextActionsRequest = {}, options?: AxiosRequestConfig) {
        return NextActionApiFp(this.configuration).getNextActions(requestParameters.page, requestParameters.isDone, requestParameters.ownerName, requestParameters.ownerNameCondition, requestParameters.actionTypeName, requestParameters.actionTypeNameCondition, requestParameters.priorityName, requestParameters.priorityNameCondition, requestParameters.reservedAtNull, requestParameters.reservedAtFrom, requestParameters.reservedAtTo, options).then((request) => request(this.axios, this.basePath));
    }
}
