import { computed, getCurrentInstance } from 'vue';

const useKey = () => {
  const key = computed(() => getCurrentInstance()?.vnode?.key);
  
  return { key };
};

export {
  useKey,
};
