import { type RouteLocationRaw, useRouter } from 'vue-router';

const useRouterLink = () => {
  const router = useRouter();

  const linkToOrReload = async (to: RouteLocationRaw, openInNewTab = false) => {
    if (!openInNewTab) {
      const currentHref = router.resolve(router.currentRoute.value).href;
      const toHref = router.resolve(to).href;
      if (currentHref === toHref) {
        window.location.reload();
        return;
      }
    }

    await linkTo(to, openInNewTab);
  };
  const linkTo = async (to: RouteLocationRaw, openInNewTab = false) => {
    if (openInNewTab) {
      const href = router.resolve(to).href;
      window.open(href, '_blank');
      return;
    }

    await router.push(to);
  };

  return { linkToOrReload, linkTo };
};

export {
  useRouterLink,
};
