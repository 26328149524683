import { computed, readonly, ref } from 'vue';
import { User } from '@/api/openapi';
import apis from '@/api/user';
import { useWait } from '@/composable/vue-wait';

const useUsers = (needsInitialFetch = () => true, getKey = () => '') => {
  const users = ref<User[]>([]);
  const activeUsers = computed(() => users.value.filter((user) => user.isActive));
  const invitingUsers = computed(() => users.value.filter((user) => user.isInviting));
  const archivedUsers = computed(() => users.value.filter((user) => !user.isActive && !user.isInviting));
  const keyOfFetchUsers = computed(() => `getUsersWait${getKey()}`);

  const { doActionWithWait } = useWait();

  const fetchUsers = async () => {
    await doActionWithWait(keyOfFetchUsers.value, async () => {
      const res = await apis.getUsers();
      users.value = res.data.users;
    });
  };
  const findUser = (id: number) => {
    return users.value.find((user) => user.id === id);
  };
  if (needsInitialFetch()) {
    fetchUsers();
  }

  return {
    users: readonly(users),
    activeUsers,
    invitingUsers,
    archivedUsers,
    keyOfFetchUsers,
    fetchUsers,
    findUser,
  };
};

const useCurrentUser = () => {
  const currentUser = ref<User>(null);
  const fetchCurrentUser = async () => {
    const res = await apis.getCurrentUser();
    currentUser.value = res.data.currentUser;
  };
  const fetchCurrentUserPromise = fetchCurrentUser();
  const isBalesBPO = computed<boolean>(() => currentUser.value?.tenantType === 'bales_bpo');

  return {
    currentUser: readonly(currentUser),
    fetchCurrentUser,
    fetchCurrentUserPromise,
    isBalesBPO,
  };
};

export {
  useUsers,
  useCurrentUser,
};
