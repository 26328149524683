<template>
  <div class="b-input-wrapper">
    <div class="b-input-inner">
      <ElInput
        v-bind="attributes"
        :id="inputId"
        ref="input"
        v-model="internalValue"
        class="b-input"
        :class="inputClass"
        @keydown.enter="keyDownEnter"
        @focus="focus"
        @blur="blur"
        @input="handleInput"
        @clear="handleClear"
      >
        <template
          v-if="$slots.prefix"
          #prefix
        >
          <slot name="prefix" />
        </template>
        <template
          v-if="$slots.prepend"
          #prepend
        >
          <slot name="prepend" />
        </template>
        <template
          v-if="$slots.append"
          #append
        >
          <slot name="append" />
        </template>
      </ElInput>
      <div 
        v-if="copyable"
        class="b-input-tools"
      >
        <BCopyButton
          :text="internalValue"
          :title="placeholder || type"
        />
      </div>
      <Transition name="el-fade-in-linear">
        <div
          v-if="isExistValidation && statusObject"
          class="check-icon"
        >
          <BTooltip
            :disabled="isValid"
            top
            :value="isError"
          >
            <BIcon
              size="small"
              :class="statusObject.class"
            >
              {{ statusObject.icon }}
            </BIcon>
            <template #content>
              <div v-if="isRequired && !isError">
                {{ $t('validation.required') }}
              </div>
              <div
                v-for="message in errorMessages"
                v-else
                :key="message"
              >
                {{ message }}
              </div>
            </template>
          </BTooltip>
        </div>
      </Transition>
    </div>
    <input
      type="text"
      style="display: none;"
    >
  </div>
</template>

<script lang="ts">
import { ElInput } from 'element-plus';
import { defineComponent, ref } from 'vue';
import inputValidation from '@/mixins/input_validation';

export default defineComponent({
  mixins: [inputValidation],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    placeholder: String,
    modelValue: [String, Number],
    autofocus: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    color: String,
    counter: Boolean,
    appendIcon: String,
    prependIcon: String,
    validateOnBlur: Boolean,
    required: Boolean,
    maxDigits: Number, // number型の最大桁数（validation = trueにする）
    maxLength: Number, // 最大文字数（validation = trueにする）
    minLength: Number, // 最小文字数（validation = trueにする）
    min: {
      type: Number,
      required: false,
      default: undefined,
    },
    max: {
      type: Number,
      required: false,
      default: undefined,
    },
    validation: Boolean, // rulesを渡さなくてもチェックができる。maxDigits,maxLength,min,maxのみ
    valid: {
      type: Boolean,
      default: true,
    },
    flat: Boolean,
    underline: Boolean,
    inputId: {
      type: String,
      default: null,
    },
    label: String,
    clearable: Boolean,
    rows: [Number, String],
    autosize: [Boolean, Object],
    resize: String,
    needsParseInt: Boolean,
    copyable: Boolean,
  },
  emits: [
    'update:valid',
    'update:modelValue',
    'focus',
    'blur',
    'keypress-enter',
    'input',
    'clear',
  ],
  setup(_, { expose }) {
    const input = ref<InstanceType<typeof ElInput>>(null);
    const getInputRef = () => input.value?.ref;
    const forceFocus = () => input.value?.focus();
    expose({
      getInputRef,
      forceFocus,
    });
    return {
      input,
    };
  },
  data() {
    return {
      isFocus: false,
      isError: false,
      isValueEmpty: true,
      internalValue: '',
      errorMessages: [],
    };
  },
  computed: {
    isValid: {
      get() {
        return this.valid;
      },
      set(newVal) {
        this.$emit('update:valid', newVal);
      },
    },
    isRequired() {
      if (this.required) return true;
      return this.rules.findIndex((rule) => rule.name.includes('requiredRule')) >= 0;
    },
    isExistValidation() {
      return this.rules.length > 0 || this.validation;
    },
    attributes() {
      return {
        'type': this.type,
        'label': this.label,
        'placeholder': this.placeholder,
        'rules': this.rules,
        'autofocus': this.autofocus,
        'readonly': this.readonly,
        'disabled': this.disabled,
        'color': this.color,
        'counter': this.counter,
        'suffix-icon': this.appendIcon,
        'prefix-icon': this.prependIcon,
        'validate-on-blur': this.validateOnBlur,
        'clearable': this.isExistValidation ? false : this.clearable,
        'rows': this.rows,
        'autosize': this.autosize,
        'resize': this.resize,
        'min': this.min,
        'max': this.max,
      };
    },
    inputClass() {
      return {
        'is-error': this.isError,
        'exist-validation': this.isExistValidation,
        'is-flat': this.flat,
        'is-underline': this.underline,
      };
    },
    statusObject() {
      if (this.isValueEmpty) {
        if (!this.isRequired) return null;
        if (this.isError) {
          return { class: 'is-error', icon: 'error' };
        } else {
          return { class: 'is-error', icon: 'check_circle_outline' };
        }
      }

      if (this.isError) {
        return { class: 'is-error', icon: 'error' };
      } else {
        return { class: 'is-success', icon: 'check_circle' };
      }
    },
  },
  watch: {
    modelValue(newVal) {
      this.internalValue = newVal;
      this.checkEmptyValue();
    },
    internalValue(newVal) {
      const parsedNewVal = this.parseIntIfNeeded(newVal);
      this.internalValue = parsedNewVal;
      this.$emit('update:modelValue', parsedNewVal);
      this.checkEmptyValue();
      this.validate();
    },
  },
  created() {
    this.internalValue = this.modelValue;
    this.checkEmptyValue();
    // NOTE: この場合this.internalValueの変更がなくwatch.internalValueが呼ばれないため、ここでvalidationを実行する
    if (this.modelValue === '') {
      this.validate();
    }
  },
  methods: {
    parseIntIfNeeded(value) {
      if (!this.needsParseInt) return value;
      if (value == null) return null;
      const parsed = parseInt(value, 10);
      if (isNaN(parsed)) return null;
      return parsed;
    },
    validate() {
      this.initializeStatus();
      if (!this.isExistValidation) {
        return;
      }
      if (this.isValueEmpty) {
        if (this.isRequired) {
          this.validateRule(this.requiredRule, this.internalValue);
          this.isValid = this.errorMessages.length === 0;
        }
        return;
      }

      this.rules.forEach((rule) => {
        this.validateRule(rule, this.internalValue);
      });

      // 最大文字数
      if (this.maxLength && this.internalValue && this.internalValue.length > this.maxLength) {
        this.errorMessages.push(this.$t('validation.maxLength', { text: this.maxLength }));
      }

      // 最小文字数
      if (this.minLength && this.internalValue && this.internalValue.length < this.minLength) {
        this.errorMessages.push(this.$t('validation.minLength', { text: this.minLength }));
      }

      // number型の最大桁数
      if (
        this.type === 'number'
          && this.maxDigits
          && this.internalValue
          && this.internalValue.length > this.maxDigits
      ) {
        this.errorMessages.push(this.$t('validation.maxDigits', { number: this.maxDigits }));
      }

      this.validateMaxMin();

      this.isError = this.errorMessages.length > 0;
      this.isValid = this.errorMessages.length === 0;
    },
    validateMaxMin() {
      // NOTE: number型のみ対応している。必要に応じて増やす
      if (this.type !== 'number') return;
      if (this.max != null && this.internalValue > this.max) {
        this.errorMessages.push(this.$t('validation.max', { number: this.max }));
      }
      if (this.min != null && this.internalValue < this.min) {
        this.errorMessages.push(this.$t('validation.min', { number: this.min }));
      }
    },
    validateRule(rule, value) {
      const result = rule(value);
      // errorだった場合にmessageが返ってくる
      if (typeof result === 'string') {
        this.errorMessages.push(result);
      }
    },
    checkEmptyValue() {
      this.isValueEmpty = this.internalValue == null || this.internalValue === '';
    },
    focus(e) {
      this.isFocus = true;
      this.validate();
      this.$emit('focus', e);
    },
    blur(e) {
      this.isFocus = false;
      this.validate();
      if (!this.isValid) {
        this.isError = this.errorMessages.length > 0;
        return;
      }
      this.$emit('blur', e);
    },
    keyDownEnter(e) {
      if (e.keyCode !== 13) return;
      this.validate();
      if (!this.isValid) {
        this.isError = this.errorMessages.length > 0;
        return;
      }
      this.$emit('keypress-enter', e);
    },
    initializeStatus() {
      this.isValid = true;
      this.isError = false;
      this.errorMessages = [];
    },
    handleClear() {
      this.$emit('clear');
    },
    handleInput(e) {
      this.$emit('input', e);
    },
  },
});
</script>

<style lang="scss" scoped>
.b-input-wrapper {
  display: inline-block;
  width: 100%;
  position: relative;
}

.b-input-inner {
  position: relative;
  height: var(--el-component-size);
}

.check-icon {
  position: absolute;
  top: 0;
  right: $basespace-100;
  bottom: 0;
  display: flex;
  align-items: center;
  height: var(--el-component-size);

  .is-success {
    color: $basecolor-success;
  }

  .is-error {
    color: $basecolor-error;
  }
}

.b-input-tools {
  margin-top: 8px;
  display: flex;
}

.b-input {
  :deep(.el-input__wrapper) {
    padding: 0;
    .el-input__inner {
      padding: 0 15px;
      --el-input-inner-height: var(--el-input-height, 40px);
      border-radius: 4px;
      position: relative;
      &::-webkit-inner-spin-button {
        height: var(--el-input-height - 2px, 38px);
        position: absolute;
        top: 1px;
        right: 26px;
      }
    }
    .el-input__prefix {
      margin-left: 15px;
      & + .el-input__inner {
        padding-left: 0;
      }
    }
    .el-input__suffix {
      margin-right: 15px;
    }
  }

  :deep(.el-input-group__append) {
    padding: 0 $basespace-200;
  }

  &.exist-validation {
    :deep(.el-input__inner) {
      padding-right: $basespace-500;
    }
  }

  &.is-error {
    :deep(.el-input__wrapper) {
      box-shadow: 0 0 0 1px $basecolor-error inset
    }
  }

  &.is-flat {
    &.el-input {
      :deep(.el-input__wrapper) {
        box-shadow: none;
      }
    }
    :deep(.el-input__wrapper) {
      .el-textarea__inner {
        border: none;
      }
      .el-input__inner {
        border: none;
        background: none;
      }
    }
  }

  &.is-underline {
    border: none;
    transition: all 0.3s ease;
    border-bottom: 1px solid $bdcolor-base;
    &.is-error {
      border-bottom: 1px solid $basecolor-error !important;
    }
    &:focus-within {
      border-bottom: 1px solid $bdcolor-active;
    }
    :deep(.el-input__wrapper) {
      box-shadow: none;
      .el-textarea__inner {
        border: none;
      }
      .el-input__inner {
        border: none;
        background: none;
      }
    }
  }
}
.el-textarea {
  :deep(.el-textarea__inner) {
    padding: $basespace-50 $basespace-100;
  }
}
</style>
