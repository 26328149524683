<template>
  <ElPopover
    v-bind="attributes"
    ref="menu"
    v-model:visible="internalValue"
    :show="$emit('show')"
    :disabled="disabled"
  >
    <template #reference>
      <slot name="activator" />
    </template>
    <slot />
  </ElPopover>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'bottom-start',
    },
    width: [Number, String],
    trigger: {
      type: String,
      default: 'click',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // MEMO: BModalなどと併用するためのもの
    zIndex100: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'show',
    'update:modelValue',
  ],
  data() {
    return {
      internalValue: this.modelValue,
    };
  },
  computed: {
    attributes () {
      return {
        'show-arrow': false,
        'popper-class': `b-menu-popper ${this.zIndex100 ? 'z-index-100' : ''}`,
        'placement': this.placement,
        'width': this.width || 'auto',
        'trigger': this.trigger,
      };
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(val) {
        this.internalValue = val;
      },
    },
    internalValue(val) {
      this.$emit('update:modelValue', val);
    },
  },
  methods: {
    save(value) {
      this.$refs.menu.save(value);
    },
  },
};
</script>
