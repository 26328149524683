<template>
  <div class="immediate-export">
    <BModalBody>
      <div class="form-items">
        <ExportDescription
          :number-of-target-leads="numberOfTargetLeads"
          :number-of-leads="numberOfLeads"
        />
        <ExportSettingFormCommon
          v-model="model"
          @update:valid="handleCommonFormValidChange"
        />
      </div>
    </BModalBody>
    <BModalFooter>
      <BTooltip
        top
        :content="tooManyLeadsMessage"
        :disabled="!tooManyLeadsMessage"
      >
        <BBtn
          type="primary"
          :disabled="executeDisabled"
          @click="handleExecuteClick"
        >
          {{ $t('leadExport.immediateExportExecute') }}
        </BBtn>
      </BTooltip>
    </BModalFooter>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { ImmediateLeadExportSetting } from '@/api/openapi';
import { LeadExportApiService } from '@/api/user/resources/lead_export';
import { useWait } from '@/composable/vue-wait';
import BModalBody from '@/plugins/biscuet-materials/components/molecules/BModalBody.vue';
import { makeInitialModel, useLeadLimit } from './export-setting';
import ExportDescription from './ExportDescription.vue';
import ExportSettingFormCommon from './ExportSettingFormCommon.vue';
import type { TLeadListProps } from './types';
type TProps = TLeadListProps;

const props = defineProps<TProps>();
const emit = defineEmits(['afterExport']);

const { wait, doActionWithWait } = useWait();
const api = new LeadExportApiService();
const { tooManyLeadsMessage } = useLeadLimit(() => props.numberOfTargetLeads, () => model.value.dataFormat);

const model = ref<ImmediateLeadExportSetting>(makeInitialModel());
const commonFormValid = ref(false);
const executeDisabled = computed(() => {
  return tooManyLeadsMessage.value !== '' || !commonFormValid.value || wait.is('postImmediateLeadExportWait');
});

const handleCommonFormValidChange = (valid: boolean) => {
  commonFormValid.value = valid;
};
const handleExecuteClick = async () => {
  await doActionWithWait('postImmediateLeadExportWait', async () => {
    await api.postImmediateLeadExport({
      request: {
        immediateLeadExportSetting: {
          ...model.value,
          leadListFilterParams: props.leadListFilterParams,
        },
      },
    });
  });
  emit('afterExport');
};
</script>
