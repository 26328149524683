<template>
  <BModal
    modal-name="leadViewSelect"
    width="700px"
    @close="handleCloseModal"
  >
    <BHeightAdjuster>
      <BModalHeader
        class="lead-view-select-header"
        :title="$t('leadViewSelect.title')"
        @modal-close="handleCloseModal"
      />
      <div class="lead-view-select-actions">
        <BBtn
          :disabled="editing"
          class="all-lead-view-button"
          @click="handleClickAllLeadView"
        >
          {{ $t('leadViewSelect.allLeadView') }}
        </BBtn>
        <BInput
          v-model="searchText"
          :placeholder="$t('leadViewSelect.searchText')"
          :max-length="255"
          :disabled="editing"
          class="search-text"
          clearable
          @clear="handleClearSearchText"
          @input="handleSearchTextInput"
        >
          <template #prefix>
            <BElementIcon
              name="search"
              type="gray"
            />
          </template>
        </BInput>
        <SelectUser
          v-model="selectedUserId"
          :teleported="false"
          :use-all-label="$t('selectUser.allOwners')"
          :disabled="editing"
          only-active-users
          @update:model-value="handleSelectedUserIdChange"
        />
      </div>
      <BModalBody
        v-loading="loading"
        class="lead-view-select-body"
      >
        <LeadViewList
          v-if="!loading"
          v-model:editing-owner-ids="editingOwnerIds"
          :lead-views="leadViews"
          @after-update="handleAfterUpdate"
          @after-delete="handleAfterDelete"
        />
      </BModalBody>
      <BModalFooter
        v-if="editing"
        class="lead-view-select-footer"
      >
        <BBtn
          class="cancel-button"
          @click="handleCancel"
        >
          {{ $t('general.cancel.text') }}
        </BBtn>
        <BBtn
          type="primary"
          @click="handleSave"
        >
          {{ $t('general.save.do') }}
        </BBtn>
      </BModalFooter>
    </BHeightAdjuster>
  </BModal>
</template>

<script lang="ts" setup>
import lodash from 'lodash';
import { computed, onBeforeMount, onBeforeUnmount, ref, watch } from 'vue';
import { LeadView } from '@/api/openapi';
import { LeadViewApiService } from '@/api/user/resources/lead_view';
import { useRouterLink } from '@/composable/router-link';
import { useFilterParams } from '@/composable/user/leadList/lead-filter';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import LeadViewList from './LeadViewList.vue';

const { currentUser, fetchCurrentUser } = useCurrentUser();
const api = new LeadViewApiService();
const { doActionWithWait, wait } = useWait();
const { clearLeadListFilterParams } = useFilterParams();
const { linkToOrReload } = useRouterLink();

const isOpen = defineModel<boolean>('isOpen', { required: true });
const selectedUserId = ref<number | null>(null);
const searchText = ref<string>('');
const originalLeadViews = ref<LeadView[]>([]);
const leadViews = ref<LeadView[]>([]);
const editingOwnerIds = ref<Map<number, number | null>>(new Map());
const editing = computed(() => editingOwnerIds.value.size > 0);
const loading = computed(() => wait.is(['fetchRecentlyMostUsedLeadViewsWait', 'updateLeadViewOwnerWait', 'deleteLeadViewWait']));

onBeforeMount(async () => {
  await setDefaultSelectedUserId();
});
onBeforeUnmount(async () => {
  debouncedFilterLeadViews.cancel();
});
watch(isOpen, async () => {
  if (isOpen.value) {
    cancelEditingOwnerIds();
    await fetchRecentlyMostUsedLeadViews(selectedUserId.value, searchText.value);
  }
});

const setDefaultSelectedUserId = async () => {
  await fetchCurrentUser();
  selectedUserId.value = currentUser.value.id;
};
const fetchRecentlyMostUsedLeadViews = async (userId: number, searchText: string) => {
  // NOTE: 複数回処理がよばれるケースがあり、infinity-loadingのリストが壊れるので制限
  if (wait.is('fetchRecentlyMostUsedLeadViewsWait')) return;

  await doActionWithWait('fetchRecentlyMostUsedLeadViewsWait', async () => {
    const { data } = await api.getRecentlyMostUsedLeadViews({
      request: {
        userId,
      },
    });
    originalLeadViews.value = data.leadViews;
    filterLeadViewsByText(searchText);
  });
};
const refetchRecentlyMostUsedLeadViews = async () => {
  await fetchRecentlyMostUsedLeadViews(selectedUserId.value, searchText.value);
};
const filterLeadViewsByText = (text: string) => {
  if (!text) {
    leadViews.value = originalLeadViews.value;
    return;
  }
  leadViews.value = originalLeadViews.value.filter(leadView => leadView.name.includes(text) || leadView.description.includes(text));
};

const handleCloseModal = () => {
  isOpen.value = false;
};
const handleClickAllLeadView = () => {
  clearLeadListFilterParams();
  linkToOrReload({ name: 'LeadList' });
  isOpen.value = false;
};
const debouncedFilterLeadViews = lodash.debounce(async (text: string) => {
  await doActionWithWait('fetchRecentlyMostUsedLeadViewsWait', async () => {
    filterLeadViewsByText(text);
  });
}, 250);
const handleSearchTextInput = async (inputValue: string) => {
  debouncedFilterLeadViews(inputValue);
};
const handleClearSearchText = async () => {
  // NOTE: clearでもinputが発火するが、上記debounceのため若干挙動が遅く感じるのでclearの場合は直接filterLeadViewsByTextを呼び出す
  await doActionWithWait('fetchRecentlyMostUsedLeadViewsWait', async () => {
    filterLeadViewsByText('');
  });
};
const handleSelectedUserIdChange = async (userId: number) => {
  await fetchRecentlyMostUsedLeadViews(userId, searchText.value);
};
const handleAfterUpdate = async () => {
  await refetchRecentlyMostUsedLeadViews();
};
const handleAfterDelete = async () => {
  await refetchRecentlyMostUsedLeadViews();
};

// footer
const handleCancel = () => {
  cancelEditingOwnerIds();
};
const cancelEditingOwnerIds = () => {
  editingOwnerIds.value.clear();
};
const handleSave = async () => {
  // NOTE: 所有者が変更されたもののみPOST
  const updatedValues = Array.from(editingOwnerIds.value.entries())
    .filter(([leadViewId, ownerId]) => {
      const leadView = leadViews.value.find(leadView => leadView.id === leadViewId);
      return ownerId !== leadView?.owner?.id;
    });
  if (updatedValues.length === 0) {
    cancelEditingOwnerIds();
    return;
  }

  // NOTE: 現状数件程度の更新を想定しているので、まとめ更新APIを作らず複数回API callしている
  await doActionWithWait('updateLeadViewOwnerWait', async () => {
    await Promise.all(
      updatedValues.map(([leadViewId, ownerId]) => updateLeadViewOwner(leadViewId, ownerId)),
    );
  });
  cancelEditingOwnerIds();
  await refetchRecentlyMostUsedLeadViews();
};
const updateLeadViewOwner = async (leadViewId: number, ownerId: number) => {
  await api.updateLeadViewOwner(leadViewId, {
    request: { ownerId },
  });
};
</script>

<style lang="scss" scoped>
.lead-view-select {
  &-actions {
    @include m-flex-align-center;
    padding-top: 0;
    padding-right: $basespace-700;
    padding-bottom: $basespace-100;
    padding-left: $basespace-700;
    z-index: calc(#{$z-index-modal} + 1); // 下部要素がSelectUserを貫通するのを防止

    .all-lead-view-button {
      margin-right: $basespace-400;
      height: 40px; // テキストの高さに合わせる
    }
    .search-text {
      flex: 1;
      padding-right: $basespace-100;
    }
  }

  &-footer {
    @include m-flex-center;
    border-top: 1px solid $bdcolor-light;

    .cancel-button {
      margin-right: $basespace-100;
    }
  }
}
</style>
