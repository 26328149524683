<template>
  <i
    :class="[classes]"
  >
    <slot />
  </i>
</template>

<script>
export default {
  props: {
    provider: {
      type: String,
      default: 'material',
    },
    type: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'medium',
    },
    bold: Boolean,
    disabled: Boolean,
    hover: Boolean,
    outlined: Boolean,
    rounded: Boolean,
  },
  computed: {
    classes () {
      return {
        'b-icon': true,
        'b-icon-default': this.type === 'default',
        'b-icon-white': this.type === 'white',
        'b-icon-gray': this.type === 'gray',
        'b-icon-primary': this.type === 'primary',
        'b-icon-success': this.type === 'success',
        'b-icon-error': this.type === 'error',
        'b-icon-warning': this.type === 'warning',
        'b-icon-mini': this.size === 'mini',
        'b-icon-small': this.size === 'small',
        'b-icon-medium': this.size === 'medium',
        'b-icon-large': this.size === 'large',
        'b-icon-extra-large': this.size === 'extra-large',
        'b-icon-super-extra-large': this.size === 'super-extra-large',
        'b-icon-hover': this.hover,
        'is-bold': this.bold,
        'material-icons': this.provider === 'material',
        'material-symbols-outlined': this.outlined,
        'material-symbols-rounded': this.rounded,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.b-icon {
  display: inline-flex;
  align-items: center;
  font-feature-settings: 'liga';
  font-size: 20px;
  justify-content: center;
  line-height: 1;
  transition: $transition-base;
  color: rgba($textcolor-base, .6);
  vertical-align: text-bottom;

  &.is-bold {
    font-weight: 600;
  }
}

// --- Type
.b-icon-default {
  color: $textcolor-base;
}

.b-icon-white {
  color: $textcolor-white;
}

.b-icon-gray {
  color: rgba($textcolor-light, .6);
}

.b-icon-primary {
  color: $basecolor-primary;
}

.b-icon-success {
  color: $basecolor-success;
}

.b-icon-error {
  color: $basecolor-error;
}

.b-icon-warning {
  color: $basecolor-warning;
}

.b-icon-mini {
  font-size: 12px;
}

.b-icon-small {
  font-size: 16px;
}

.b-icon-medium {
  font-size: 18px;
}

.b-icon-large {
  font-size: 20px;
}

.b-icon-extra-large {
  font-size: 24px;
}

.b-icon-super-extra-large {
  font-size: 80px;
}

.b-icon-hover {
  background: transparent;
  border: none;
  border-radius: 50%;
  padding: $basespace-50;
  &:hover {
    background-color: $bgcolor-dark;
  }
}

.material-symbols-rounded {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 24
}
</style>
