<template>
  <div
    v-loading="$wait.is(['updateNextActionWait', 'getNextActionsWait', 'doneNextActionWait'])"
    class="next-action-area"
  >
    <template
      v-for="nextAction in nextActions"
      :key="`nextaction-${nextAction.id}`"
    >
      <div
        class="next-action-task"
        @click="() => handleNextActionEdit(nextAction)"
      >
        <CallAction
          v-if="isCallActionType(nextAction.actionType)"
          :next-action="nextAction"
          :call-target="callTarget"
        />
        <NextAction
          v-else
          :next-action="nextAction"
          @click:done="handleNextActionDone"
          @click:delete="handleNextActionDelete"
          @click:editor="handleEditorClick"
          @update="handleNextActionUpdate"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { CallTarget, CallTargetNextAction as TNextAction } from '@/api/openapi';
import { isItSequenceAction } from '@/composable/user/nextAction/next-action';
import { isCallActionType } from '@/composable/user/sequence/post-put-body';
import CallAction from './task/CallAction.vue';
import NextAction from './task/NextAction.vue';

type TProps = {
  nextActions: TNextAction[];
  callTarget: CallTarget;
};
type TEmit = {
  // FIXME: camelCaseにしたいが、親コンポーネントがoptional APIで書かれており、ケバブでないと動かない
  'click-done': [nextAction: TNextAction];
  'click-edit': [nextAction: TNextAction];
  'click-delete': [nextActionId: number];
  'update': [nextAction: TNextAction];
  'click-drawer-close': [];
  'click:sequenceAction': [sequenceInstanceId: number];
};

defineProps<TProps>();
const emit = defineEmits<TEmit>();

const handleNextActionDone = (nextAction: TNextAction) => {
  emit('click-done', nextAction);
};

const handleNextActionEdit = (nextAction: TNextAction) => {
  if (isItSequenceAction(nextAction)) {
    emit('click:sequenceAction', nextAction.sequenceStepInstance.sequenceInstanceId);
  } else {
    emit('click-edit', nextAction);
  }
};

const handleNextActionDelete = (nextAction: TNextAction) => {
  emit('click-delete', nextAction.id);
};

const handleNextActionUpdate = (nextAction: TNextAction) => {
  emit('update', nextAction);
};

const handleEditorClick = () => {
  emit('click-drawer-close');
};
</script>

