<template>
  <div style="height: 100%">
    <FormDrawer
      v-if="currentState === state.form && senderData"
      v-bind="formAttr"
      v-model="formValues"
      :is-loading="$wait.is(['getDirectMailWait', 'createDirectMailWait', 'updateDirectMailWait'])"
      :sender-data="senderData"
      :send-to-address="toEmail"
      @update:model-value="updateFormValues"
      @change-current-state-to-confirm="handleChangeToConfirm"
      @save-draft="saveDraft"
      @cancel="checkCancelDrawer"
      @close-openai="handleCloseOpenAi"
    />
    <VWait
      v-else-if="currentState === state.confirm"
      for="none"
      style="height: 100%"
      :visible="$wait.is(['getDirectMailWait']) || !innerDirectMail"
    >
      <template #waiting>
        <div class="confirm-loading-area">
          <BPlaceholder
            v-loading="true"
            height="50vh"
            element-loading-background="transparent"
          />
        </div>
      </template>
      <ConfirmDrawer
        v-if="innerDirectMail && senderData"
        v-bind="confirmAttr"
        :is-loading="false"
        :sender-data="senderData"
        @edit="edit"
        @reserve="reserve"
        @send="send"
        @close="closeModal"
      />
    </VWait>
    <SendingDrawer
      v-else-if="currentState === state.sending"
      v-bind="sendingAttr"
      @cancel="checkCancelDrawer"
      @sending-animation-completed="onSendingAnimationCompleted"
    />
  </div>
</template>

<script lang="ts">
import axios from 'axios';
import { defineComponent } from 'vue';
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import { DirectMail, PostPutDirectMailBody, ReceivedMail } from '@/api/openapi';
import { OpenAiApiService } from '@/api/user/resources/openai_mail';
import { ReceivedMailApiService } from '@/api/user/resources/received_mail';
import SendingDrawer from '@/components/organisms/user/mail/common/drawer/state/sending/SendingDrawer.vue';
import ConfirmDrawer from '@/components/organisms/user/mail/direct/drawer/state/confirm/ConfirmDrawer.vue';
import FormDrawer from '@/components/organisms/user/mail/direct/drawer/state/form/FormDrawer.vue';
import { getCommonDefault } from '@/components/organisms/user/mail/direct/drawer/state/form-values';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import errorHandler from '@/mixins/error_handler';
import SfTaskSyncService from '@/mixins/salesforce/sf_task_sync_service';
import { TPartialFormLeadDirectMail, SenderData } from './direct/drawer/state/types';

type TData = {
  currentState: number;
  innerDirectMail: DirectMail | null;
  formValues: TPartialFormLeadDirectMail;
  cancelOpenai: () => void | undefined;
  senderData: SenderData | null;
};
const initialState = (): TData => ({
  currentState: 0,
  innerDirectMail: null,
  formValues: {
    ...getCommonDefault(),
    reservedAt: null,
  },
  cancelOpenai: undefined,
  senderData: null,
});

export default defineComponent({
  components: {
    FormDrawer,
    SendingDrawer,
    ConfirmDrawer,
  },
  mixins: [errorHandler, SfTaskSyncService],
  props: {
    callTargetId: {
      type: Number,
      require: true,
      default: null,
    },
    selectedDirectMailId: {
      type: Number,
      require: true,
      default: null,
    },
    replyToReceivedMailId: {
      type: Number,
      require: false,
      default: null,
    },
  },
  emits: [
    'change',
    'close',
    'reset',
  ],
  setup(props) {
    const { isAvailableMailTemplateFeature, isAvailableAiFeatureToBeReleased } = useAvailableFeatureCheck();
    const { currentUser, fetchCurrentUser } = useCurrentUser();
    const { doActionWithWait } = useWait();

    return {
      doActionWithWait,
      isAvailableAiFeatureToBeReleased,
      isAvailableMailTemplateFeature,
      currentUser,
      fetchCurrentUser,
      ...SfTaskSyncService.setup(props),
    };
  },
  data(): TData {
    return initialState();
  },
  async created() {
    await this.fetchCurrentUser();
    this.setSenderData();
  },
  computed: {
    ...mapGetters('user', [
      'callTarget',
    ]),
    ...mapGetters('userUi', [
      'targetMailFormVisible',
    ]),
    state () {
      return {
        form: 0,
        confirm: 1,
        sending: 2,
        sent: 3,
      };
    },
    commonState () {
      return {
        state: this.state,
        currentState: this.currentState,
      };
    },
    formAttr () {
      return {
        ...this.commonState,
        callTargetId: this.callTargetId,
        title: this.toEmail ? this.$t('mail.sendAddress', { email: this.toEmail }) : this.$t('mail.mailCreate'),
        isAvailableSalesforceApiFeature: this.isAvailableSalesforceApiFeature,
        isAvailableMailTemplateFeature: this.isAvailableMailTemplateFeature,
      };
    },
    confirmAttr() {
      return {
        directMail: this.innerDirectMail,
        isAvailableSalesforceApiFeature: this.isAvailableSalesforceApiFeature,
      };
    },
    sendingAttr () {
      return {
        ...this.commonState,
        width: 600,
      };
    },
    toEmail () {
      return this.callTarget?.callTargetInfo?.email;
    },
  },
  watch: {
    selectedDirectMailId: {
      immediate: true,
      async handler(newVal) {
        if (!newVal) return;
        this.toConfirm();
        const { data: directMail } = await this.getDirectMailAction({
          request: { directMailId: newVal },
        });
        this.setDirectMail(directMail);
      },
    },
    replyToReceivedMailId: {
      immediate: true,
      async handler(newVal) {
        if (!newVal) return;
        this.toForm();
        const api = new ReceivedMailApiService();
        const { data: receivedMail } = await api.getReceivedMail({
          request: { receivedMailId: newVal },
        });

        // このロジック内でほぼ完結させたかったので細かい処理分割はしない
        if (this.isAvailableAiFeatureToBeReleased) {
          try {
            await this.doActionWithWait('generateReplyContentByOpenSearch', async () => {
              this.cancelOpenai = () => {
                const cancelSource = axios.CancelToken.source();
                if (cancelSource == null || Object.keys(cancelSource).length === 0) return;
                cancelSource?.cancel();
              };

              const openaiApi = new OpenAiApiService();
              const res = await openaiApi.generateReplyContentByOpenSearch({ token: this.cancelOpenai.token, originalMail: receivedMail.quotedContent, responseMail: receivedMail.mainContent });
              receivedMail.content = res.data;
              this.cancelOpenai = null;
            });
          } catch (e) {
            receivedMail.content = '';
            this.cancelOpenai = null;
            this.$toast.show({
              message: this.$t('general.fail.to', { action: this.$t('openaiGenarate.title') }),
              type: 'error',
            });
          }

        } else {
          receivedMail.content = '';
        }
        this.setReceiveMail(receivedMail);
      },
    },
    targetMailFormVisible: {
      immediate: true,
      async handler(newVal) {
        if (newVal) return;
        // NOTE: 個別メール選択中に閉じた時は、フォームをresetする
        if (this.innerDirectMail?.id) {
          this.reset();
        }
      },
    },
  },
  methods: {
    ...mapWaitingActions('user', {
      sendDirectAction: 'sendDirectWait',
      getLeadHistoriesBackgroundAction: { action: 'getLeadHistoriesAction', loader: 'getLeadHistoriesBackgroundWait' },
      createDirectMailAction: 'createDirectMailWait',
      updateDirectMailAction: 'updateDirectMailWait',
      reserveDirectMailAction: 'reserveDirectMailWait',
      cancelReservationDirectMailAction: 'cancelReservationDirectMailWait',
      getDirectMailAction: 'getDirectMailWait',
    }),
    ...mapWaitingActions('userUi', {
      setTargetHeaderMoveButtonDisabledAction: 'setTargetHeaderMoveButtonDisabledWait',
    }),
    reset () {
      Object.assign(this.$data, initialState());
      this.$emit('reset');
    },
    async send () {
      this.toSending();
      this.setTargetHeaderMoveButtonDisabledAction(false);
      try {
        await this.sendDirectAction({
          body: {
            directMailId: this.innerDirectMail.id,
            ...this.makeRequestBody(),
          },
          errorHandlers: {
            404: this.defaultHandler,
          },
        });
      } catch (e) {
        this.toForm();
        this.$bitterAlert.show({
          text: e.response?.data?.errorMessage || this.$t('directMail.drawer.errorModalTitle'),
          buttonsCancel: false,
        });
      } finally {
        this.$emit('change');
      }
    },
    async checkCancelDrawer () {
      const check = await this.$bitterAlert.show({
        title: this.$t('general.confirm.text'),
        text: this.$t('general.alert.of', { target: this.$t('general.unsavedValue'), action: this.$t('general.clear') }),
      });
      if (!check) { return; }
      if (this.cancelOpenai != null) { this.cancelOpenai(); }
      this.closeModal();
    },
    closeModal() {
      this.reset();
      this.$emit('close');
    },
    toForm () {
      this.currentState = this.state.form;
    },
    toSending () {
      this.currentState = this.state.sending;
    },
    toConfirm() {
      this.currentState = this.state.confirm;
    },
    onSendingAnimationCompleted () {
      this.reset();
      this.$emit('close');
    },
    setDirectMail(directMail: DirectMail) {
      this.innerDirectMail = directMail;
      const {
        subject,
        content,
        notificationSetting: {
          open,
          click,
        },
        sendCc,
        sendBcc,
        reservedAt,
        mailAttachmentIds,
        replyToReceiveMailId,
      } = this.innerDirectMail;
      this.updateFormValues(
        {
          subject,
          content,
          reservedAt,
          open,
          click,
          sendCc,
          sendBcc,
          mailAttachmentIds,
          replyToReceiveMailId,
        },
      );
      this.setSenderData();
    },
    setReceiveMail(receivedMail: ReceivedMail) {
      const {
        subject,
        content,
        cc,
        id,
      } = receivedMail;

      const setValues = {
        reservedAt: null,
        subject: `Re: ${subject}`,
        sendCc: cc,
        sendBcc: [],
        replyToReceiveMailId: id,
      };

      if (content?.length > 0) {
        setValues.content = content;
      }

      this.updateFormValues(
        {
          ...getCommonDefault(),
          ...setValues,
        },
      );

    },
    updateFormValues (formValues: PostPutDirectMailBody) {
      this.formValues = formValues;
    },
    setSenderData() {
      const { senderEmail, lastName, firstName } = this.innerDirectMail ? this.innerDirectMail.owner : this.currentUser;
      const senderName = `${lastName} ${firstName}`;
      this.senderData = { senderEmail, senderName };
    },
    makeRequestBody() {
      return {
        ...this.formValues,
        callTargetId: this.callTargetId,
        sendCc: this.formValues.sendCc.length === 0 ? null : JSON.stringify(this.formValues.sendCc),
        sendBcc: this.formValues.sendBcc.length === 0 ? null : JSON.stringify(this.formValues.sendBcc),
      };
    },
    async handleChangeToConfirm() {
      await this.saveDraft();
      this.toConfirm();
    },
    async reserve() {
      if (!this.innerDirectMail) return;

      const { data: savedDirectMail } = await this.reserveDirectMailAction({
        request: {
          directMailId: this.innerDirectMail.id,
          putReserveBody: {},
        },
        errorHandlers: {
          404: this.defaultHandler,
          422: this.defaultHandler,
        },
      });
      this.setDirectMail(savedDirectMail);
      this.$nextTick(() => {
        this.$emit('change');
        this.$emit('close');
        this.$toast.show(this.$t('general.success.to', { action: this.$t('mail.reserve') }));
      });
    },
    afterSaveDraft() {
      this.$nextTick(() => {
        this.$emit('change');
        this.$toast.show(this.$t('general.success.to', { action: this.$t('mail.saveDraft') }));
      });
    },
    async saveDraft() {
      if (this.innerDirectMail) {
        const { data: savedDirectMail } = await this.updateDirectMailAction({
          request: {
            directMailId: this.innerDirectMail.id,
            postPutDirectMailBody: {
              ...this.makeRequestBody(),
            },
          },
          errorHandlers: {
            404: this.defaultHandler,
            422: this.defaultHandler,
          },
        });
        this.setDirectMail(savedDirectMail);
      } else {
        const { data: savedDirectMail } = await this.createDirectMailAction({
          request: {
            postPutDirectMailBody: {
              ...this.makeRequestBody(),
            },
          },
          errorHandlers: {
            422: this.defaultHandler,
          },
        });
        this.setDirectMail(savedDirectMail);
      }
      this.afterSaveDraft();
    },
    async edit() {
      if (this.innerDirectMail.status === 'pending') {
        const result = await this.$bitterAlert.show({
          text: this.$t('mail.confirmReservationCancel'),
        });
        if (!result) return;

        await this.cancelReservation();
      }

      this.toForm();
    },
    async cancelReservation() {
      const { data: savedDirectMail } = await this.cancelReservationDirectMailAction({
        request: {
          directMailId: this.innerDirectMail.id,
        },
        errorHandlers: {
          404: this.defaultHandler,
          422: this.defaultHandler,
        },
      });
      this.setDirectMail(savedDirectMail);
      this.afterSaveDraft();
    },
    handleCloseOpenAi(cancel: () => void) {
      this.cancelOpenai = cancel;
    },
  },
});
</script>

<style lang="scss" scoped>
.confirm-loading-area {
  height: 100%;
  width: 600px;
  background-color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.25) -1px 0px 10px;
}
</style>
