import { computed, onBeforeMount, readonly, ref, Ref } from 'vue';
import { PaginationMeta } from '@/api/openapi';
import { useWait } from './vue-wait';

const useInfiniteScroll = <TItem>(
  getItems: () => TItem[],
  limit: number,
) => {
  const readItems = ref<TItem[]>([]);
  const currentPage = ref(0);

  const itemsLength = computed(() => getItems().length);
  const readItemsLength = computed(() => readItems.value.length);
  const scrollDisabled = computed(() => itemsLength.value <= readItemsLength.value);

  const readMore = () => {
    if (scrollDisabled.value) return;

    const end = itemsLength.value < readItemsLength.value + limit
      ? itemsLength.value
      : readItemsLength.value + limit;
    readItems.value = (readItems.value as TItem[]).concat(
      getItems().slice(readItemsLength.value, end),
    );
    currentPage.value += 1;
  };
  const reset = () => {
    readItems.value = [];
    currentPage.value = 0;
  };

  return {
    readItems: readonly(readItems),
    scrollDisabled,
    readMore,
    reset,
  };
};

const useInfiniteLoading = <TItem>(
  fetchItems: (page: number) => Promise<{ items: TItem[]; paginationMeta: PaginationMeta }>,
  keyOfWait: string,
) => {
  const { doActionWithWait } = useWait();

  // NOTE: 解決される型が理想と違うので変換
  // @see https://github.com/vuejs/core/issues/2136
  const items = ref<TItem[]>([]) as Ref<TItem[]>;
  const paginationMeta = ref<PaginationMeta>(null);
  const currentPage = ref(1);
  const infiniteScrollDisabled = ref(true);

  const fetchAndConcatItems = async (page = 1) => {
    await doActionWithWait(keyOfWait, async () => {
      try {
        const result = await fetchItems(page);

        paginationMeta.value = result.paginationMeta;

        const previous = items.value.slice(0, (page - 1) * paginationMeta.value.limitValue);
        const following = items.value.slice(page * paginationMeta.value.limitValue);
        items.value = previous.concat(result.items, following);
      } catch (e) {
        if (e.response?.status === 404) {
          items.value = [];
        }
        throw e;
      }
    });
  };
  const loadMore = async () => {
    if (paginationMeta.value.totalCount <= items.value.length) return;

    infiniteScrollDisabled.value = true;
    const nextPage = currentPage.value + 1;
    await fetchAndConcatItems(nextPage);
    currentPage.value = nextPage;

    infiniteScrollDisabled.value = false;
  };
  const clear = () => {
    // これがないと、「非表示 -> 表示」無限スクロールが発火してしまって、複数リクエストが実行されてしまう。
    infiniteScrollDisabled.value = true;

    currentPage.value = 1;
    items.value = [];
  };
  const handleAfterOpen = () => {
    fetchAndConcatItems()
      .then(() => {
        infiniteScrollDisabled.value = false;
      })
      .catch((e) => { throw e; });
  };
  const fetchLoadedPage = () => {
    for (let i = 1; i <= currentPage.value; i++) {
      fetchAndConcatItems(i).catch((e) => { throw e; });
    }
  };

  onBeforeMount(() => {
    fetchAndConcatItems().catch((e) => { throw e; });
  });

  return {
    items: readonly(items),
    paginationMeta: readonly(paginationMeta),
    infiniteScrollDisabled: readonly(infiniteScrollDisabled),
    loadMore,
    clear,
    handleAfterOpen,
    fetchLoadedPage,
  };
};

export {
  useInfiniteScroll,
  useInfiniteLoading,
};
