<template>
  <CommonTask
    v-model:content="content"
    :owner="nextAction.owner"
    :title="nextAction.name"
    :date-time="nextAction.reservedAt"
    :is-sequence="isSequenceAction"
    :is-deletable="isDeletable"
    :is-content-editable="isContentEditable"
    :link-to-text="$t('general.confirm.do')"
  >
    <template #action>
      <BTooltip
        top
        :content="$t('callTarget.inputCallResult')"
        :disabled="callResultFormDisable"
      >
        <BBtn
          size="mini"
          fab
          :disabled="callResultFormDisable"
          @click.stop="handleCallResultFormOpenClick"
        >
          <BCustomIcon
            class="custom-icon"
            icon-class="b-call-add"
            size="small"
          />
        </BBtn>
      </BTooltip>
    </template>
    <template #status>
      <BPriorityPreview
        :priority="nextAction.priority"
        size="micro"
      />
    </template>
  </CommonTask>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { CallTarget, CallTargetNextAction as NextAction } from '@/api/openapi';
import { useSfSync } from '@/composable/sf-feature';
import { useNextActionComputed } from '@/composable/user/nextAction/next-action';
import { key } from '@/store/index';
import CommonTask from './CommonTask.vue';

type TProps = {
  nextAction: NextAction;
  callTarget: CallTarget;
};
const props = defineProps<TProps>();
const content = defineModel<string>('content');

watch(() => props.nextAction.content, (newVal) => {
  content.value = newVal;
}, { immediate: true });

const store = useStore(key);
const { validateCallResultSyncState } = useSfSync(() => props.callTarget);
const { isSequenceAction } = useNextActionComputed(props.nextAction);

const isDeletable = computed(() => !isSequenceAction.value);
const isContentEditable = computed(() => !isSequenceAction.value);
const callResultFormDisable = computed(() => store.getters['userUi/targetCallResultFormVisible']);

const handleCallResultFormOpenClick = async () => {
  const valid = await validateCallResultSyncState();
  if (!valid) return;

  store.dispatch('userUi/setTargetCallResultFormLogValuesAction', {
    nextActionId: props.nextAction.id,
  });
  store.dispatch('userUi/setTargetHeaderMoveButtonDisabledAction', true);
  store.dispatch('userUi/setTargetCallResultFormVisibleAction', true);
};
</script>

<style lang="scss" scoped>
.click-to-call-toggle {
  padding: 0 $basespace-100;
  .el-icon {
    padding-left: $basespace-50;
  }
}
.click-to-call {
  display: flex;
  width: 100%;
  .click-to-call-text {
    padding-right: $basespace-200;
    font-size: $fontsize-100;
    line-height: 28px; // NOTE: ボタンの高さと同じにする
  }
}
:deep(.click-to-call-button) {
  display: block;
  margin-left: auto;
  .b-icon {
    margin: 0;
  }
}
</style>
