import { computed, ModelRef, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { CallResult } from '@/api/openapi';
import { useConfirm } from '@/composable/message-dialog';
import { isEmptyObject } from '@/utils/object';
import { isCallActionDefaultActionType } from '../sequence/post-put-body';
import { useSequenceStepInstance } from '../sequence/sequence-step-instance';
import { useMakeSequenceStepTitle } from '../sequence/step-computed-property';

const useNextActionCallResult = (getCallResult: () => CallResult | Record<string, never>) => {
  const connected = computed(() => {
    return isConnectedToNextAction(getCallResult());
  });
  const isConnectedToNextAction = (callResult: CallResult | Record<string, never>) => {
    return callResult.nextActionCallResult != null;
  };
  const completed = computed(() => {
    return isNextActionCompleted(getCallResult());
  });
  const isNextActionCompleted = (callResult: CallResult | Record<string, never>) => {
    return callResult.nextActionId != null;
  };

  const initializeConnectingValues = (
    callResult: CallResult | Record<string, never>,
    connecting: ModelRef<boolean>,
    completing: ModelRef<boolean>,
  ) => {
    if (isEmptyObject(callResult)) {
      connecting.value = true;
      completing.value = false;
    } else {
      connecting.value = isConnectedToNextAction(callResult);
      completing.value = isNextActionCompleted(callResult);
    }
  };

  return {
    connected,
    completed,
    initializeConnectingValues,
  };
};

const useNextActionCallResultWithSequence = (getCallResult: () => CallResult | Record<string, never>, getConnectingNextActionId: () => number | null) => {
  const { connected, completed, initializeConnectingValues } = useNextActionCallResult(getCallResult);
  const { currentSequenceStepInstance, fetchCurrentSequenceStepInstance, clearCurrentSequenceStepInstance } = useSequenceStepInstance();
  const { makeShortSequenceStepTitle } = useMakeSequenceStepTitle();

  const sequenceStepName = computed(() => {
    if (!currentCallStepActive.value) return '';
    return makeShortSequenceStepTitle(currentSequenceStepInstance.value);
  });

  const connectedToAnotherNextAction = computed(() => {
    const connectedNextActionId = getCallResult().nextActionCallResult?.nextActionId;
    if (connectedNextActionId == null) return false;
    if (currentSequenceStepInstance.value == null) return false;
    return currentSequenceStepInstance.value.nextActionId !== connectedNextActionId;
  });
  const currentCallStepActive = computed(() => {
    return currentSequenceStepInstance.value != null && isCallActionDefaultActionType(currentSequenceStepInstance.value);
  });

  const canDisplayInputs = computed(() => {
    return currentCallStepActive.value;
  });
  const canEditConnecting = computed(() => {
    return getConnectingNextActionId() == null && currentCallStepActive.value && !connectedToAnotherNextAction.value && !connected.value;
  });
  const canEditCompleting = computed(() => {
    return currentCallStepActive.value && !connectedToAnotherNextAction.value && !completed.value;
  });
  const canEdit = computed(() => {
    return canEditCompleting.value || canEditConnecting.value;
  });

  const connectingNextActionId = computed(() => {
    return getConnectingNextActionId()
      || (currentCallStepActive.value ? currentSequenceStepInstance.value?.nextActionId : null);
  });

  const refetchCurrentSequenceStepInstance = async (activeSequenceInstanceId: number | null) => {
    if (activeSequenceInstanceId == null) {
      clearCurrentSequenceStepInstance();
    } else {
      await fetchCurrentSequenceStepInstance(activeSequenceInstanceId);
    }
  };

  return {
    canDisplayInputs,
    canEditConnecting,
    canEditCompleting,
    canEdit,
    sequenceStepName,
    connectedToAnotherNextAction,
    connectingNextActionId,
    // methods
    initializeConnectingValues,
    refetchCurrentSequenceStepInstance,
  };
};

const useConnectingCallResultToNextAction = () => {
  const i18n = useI18n();
  const { confirm } = useConfirm();

  const canEditSequenceForm = ref(false);
  const connectingNextActionId = ref(null);
  const setCanEditSequenceForm = (canEdit: boolean) => {
    canEditSequenceForm.value = canEdit;
  };
  const setConnectingNextActionId = (nextActionId: number | null) => {
    connectingNextActionId.value = nextActionId;
  };

  type TNextActionRequestBody = {
    nextActionId: number | null;
    phoneNumberKey: string | null;
    nextActionCompleting: boolean;
  };
  /**
   * 次のアクションと紐づけるためのRequestBodyを作成。
   * call_results create / update のRequestBodyに組み込んで利用
   */
  const makeConnectingNextActionRequestBody = async (connecting: boolean, completing: boolean, targetCallResultFormPhoneNumberKey: string): Promise<TNextActionRequestBody | false> => {
    const notConnectingRequestBody: TNextActionRequestBody = {
      nextActionId: null,
      phoneNumberKey: null,
      nextActionCompleting: false,
    };
    if (!canEditSequenceForm.value) return notConnectingRequestBody;
    if (!connecting) return notConnectingRequestBody;

    if (completing) {
      // NOTE: 「完了する」の場合のみconfirm
      const ok = await confirm({
        title: i18n.t('callResult.sequence.completingConfirm.title'),
        message: i18n.t('callResult.sequence.completingConfirm.message'),
      });
      if (!ok) return false;
    }

    return {
      nextActionId: connectingNextActionId.value,
      phoneNumberKey: targetCallResultFormPhoneNumberKey,
      nextActionCompleting: completing,
    };
  };

  return {
    setCanEditSequenceForm,
    setConnectingNextActionId,
    makeConnectingNextActionRequestBody,
  };
};

export {
  useNextActionCallResultWithSequence,
  useConnectingCallResultToNextAction,
};
