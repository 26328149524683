import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import { CustomFieldsApi } from '@/api/openapi/apis/custom-fields-api';
import {
  GetCustomFields200Response
} from '@/api/openapi'


export class CustomFieldsApiService extends ApiService {
  api = new CustomFieldsApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async import({
    options,
    errorHandlers,
  }): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.customFieldsImport(options),
      errorHandlers,
    );
  }

  async getCustomFields({
    options,
    errorHandlers,
  }): Promise<AxiosResponse<GetCustomFields200Response>> {
    return this.checkResponse<GetCustomFields200Response>(
      this.api.getCustomFields(options),
      errorHandlers,
    );
  }

}
