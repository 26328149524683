<template>
  <BLayout wrap>
    <span
      v-if="$wait.is('fetchDirectMailUnsubscribedEmailWait')"
      class="ml-200"
    >
      <BContentLoader
        :height="24"
        :width="200"
      >
        <rect
          x="0"
          y="8.5"
          rx="6"
          ry="6"
          width="160"
          height="12"
        />
      </BContentLoader>
    </span>
    <span
      v-else
    >
      <BTooltip
        v-if="isDirectMailUnsubscribed"
        placement="top"
        width="200"
        trigger="hover"
        :content="directMailUnsubscribedMessage"
      >
        <ElTag
          type="danger"
          effect="plain"
          size="medium"
          round
          disable-transitions
        >
          {{ $t('unsubscribe.directMail.title') }}
        </ElTag>
      </BTooltip>
      <ElTag
        v-else
        type="info"
        effect="light"
        size="medium"
        round
        disable-transitions
      >
        {{ $t('unsubscribe.directMail.title') }}
      </ElTag>
      <BTooltip
        v-if="isBulkMailUnsubscribed"
        placement="top"
        width="200"
        trigger="hover"
        :content="bulkMailUnsubscribedMessage"
      >
        <ElTag
          type="danger"
          effect="plain"
          size="medium"
          round
          disable-transitions
        >
          {{ $t('unsubscribe.bulkMail.title') }}
        </ElTag>
      </BTooltip>
      <ElTag
        v-else
        type="info"
        effect="light"
        size="medium"
        round
        disable-transitions
      >
        {{ $t('unsubscribe.bulkMail.title') }}
      </ElTag>
    </span>
  </BLayout>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { TUnsubscribeMail } from '@/composable/user/directMail/unsubscribed-email';
import { formatDateTime } from '@/utils/date-time';

type TProps = {
  unsubscribeMail: TUnsubscribeMail;
};

const props = defineProps<TProps>();
const i18n = useI18n();

const isDirectMailUnsubscribed = computed(() =>
  props.unsubscribeMail?.directMail?.createdAt != null,
);

const isBulkMailUnsubscribed = computed(() =>
  props.unsubscribeMail?.bulkMail?.createdAt != null,
);

const directMailUnsubscribedMessage = computed(() =>
  i18n.t('unsubscribe.directMail.unsubscribedInfo', {
    user: props.unsubscribeMail?.directMail.operatedUser.name,
    time: formatDateTime(props.unsubscribeMail?.directMail.createdAt),
  }),
);

const bulkMailUnsubscribedMessage = computed(() =>
  props.unsubscribeMail?.bulkMail?.message,
);
</script>

<style lang="scss" scoped>
.el-tag {
  margin-left: $basespace-200;
  padding: $basespace-50 $basespace-100;
  font-weight: 400;

  &.el-tag--info {
    color: $textcolor-light;
    background-color: $basecolor-disabled;
    border-color: $basecolor-disabled;
  }
}
</style>
