import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import type { PartialSequenceStepMaster } from '@/api/openapi';
import { makeNextActionType } from '@/api/user/models/next_action_types';
import type { TFormSequenceStep, TStepType, TWithDaysPartialSequenceStepMaster } from '@/components/organisms/user/general/sequence/types';
import { useNextActionTypes } from '@/composable/user/nextActionType/next-action-types';
import { formatShorterDate } from '@/utils/date-time';
import { isDirectMailReservedActionType, isAutomatic, getStepTypeByActionType } from './post-put-body';

const isTFormSequenceStep = (step: PartialSequenceStepMaster | TFormSequenceStep): step is TFormSequenceStep => {
  return 'actionType' in step;
};

const getActionType = (step: { actionType?: string, defaultActionTypeId?: number | null, actionTypeId?: number | null }) => {
  if (step.actionType != null) return step.actionType;
  return makeNextActionType(step.defaultActionTypeId, step.actionTypeId);
};

const getStepType = (step: PartialSequenceStepMaster): TStepType => {
  return getStepTypeByActionType(makeNextActionType(step.defaultActionTypeId, step.actionTypeId));
};

const getIsSkipped = (step: PartialSequenceStepMaster | TFormSequenceStep) => {
  if (isTFormSequenceStep(step)) return step.status === 'skipped';
  return false;
};

const useMakeSequenceStepTitle = () => {
  const i18n = useI18n();
  const { findNextActionType } = useNextActionTypes();

  type TStepTitle = {
    typeName: string;
    subTypeName: string;
    numberOfDays: string | null;
    scheduledOrEndedDatetime: string | null;
    executionType: string;
  };

  const getScheduledOrEndedDatetimeString = (step: TFormSequenceStep) => {
    return step.scheduledOrEndedDatetime != null ? formatShorterDate(step.scheduledOrEndedDatetime) : null;
  };

  const makeSequenceStepTitle = (step: TWithDaysPartialSequenceStepMaster | TFormSequenceStep) => {
    const stepWithActionType = { ...step, actionType: getActionType(step) };
    const serialNumber = getSerialNumber(stepWithActionType.sortOrder);
    const stepTypeName = getStepTypeName(stepWithActionType.actionType);

    const numberOfDays = getIsSkipped(stepWithActionType) ? null : `${step.day}${i18n.t('sequence.day')}`;
    const scheduledOrEndedDatetime = getScheduledOrEndedDatetimeString(stepWithActionType as TFormSequenceStep);
    const executionType = isAutomatic(stepWithActionType) ? i18n.t('sequence.automatic') : i18n.t('sequence.manual');

    const titleObj: TStepTitle = {
      ...stepTypeName,
      numberOfDays,
      scheduledOrEndedDatetime,
      executionType,
    };
    const title = Object.values(titleObj).filter(i => i !== null).join(' - ');

    return `${serialNumber}. ${title}`;
  };

  const makeShortSequenceStepTitle = (step: { actionType?: string, defaultActionTypeId?: number | null, actionTypeId?: number | null, sortOrder: number }) => {
    const actionType = getActionType(step);
    const serialNumber = getSerialNumber(step.sortOrder);
    const stepTypeName = getStepTypeName(actionType);
    const title = Object.values(stepTypeName).filter(i => i !== null).join(' - ');

    return `${serialNumber}.${title}`;
  };

  const getSerialNumber = (sortOrder: number) => {
    return sortOrder + 1;
  };

  const getStepTypeName = (actionType: string) => {
    const stepType = getStepTypeByActionType(actionType);
    let stepTypeName: { typeName: string; subTypeName: string | null } | null = null;
    switch (stepType) {
      case 'direct_mail':
        stepTypeName = {
          typeName: i18n.t('sequence.step.types.directMail'),
          subTypeName: isDirectMailReservedActionType(actionType) ? i18n.t('mail.reserve') : i18n.t('mail.draft'),
        };
        break;
      case 'call_action':
        stepTypeName = {
          typeName: i18n.t('sequence.step.types.callAction'),
          subTypeName: null,
        };
        break;
      default:
        stepTypeName = {
          typeName: i18n.t('sequence.step.types.nextAction'),
          subTypeName: findNextActionType(actionType)?.name,
        };
        break;
    }
    return stepTypeName;
  };

  return {
    makeSequenceStepTitle,
    makeShortSequenceStepTitle,
  };
};

const useSequenceStepComputedProperty = (getSequenceStep: () => TFormSequenceStep) => {
  const isSkipped = computed(() => {
    return getIsSkipped(getSequenceStep());
  });

  const { makeSequenceStepTitle } = useMakeSequenceStepTitle();
  const title = computed(() => {
    return makeSequenceStepTitle(getSequenceStep());
  });

  return {
    isSkipped,
    title,
  };
};

export {
  getStepType,
  useSequenceStepComputedProperty,
  useMakeSequenceStepTitle,
};
