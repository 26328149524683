<template>
  <BContentBox>
    <template #content>
      <div
        v-loading="loading"
      >
        <BLayout
          justify-center
          column
        >
          <div v-if="isAvailableSalesforceLinkFeature">
            <BListItem
              class="sf-connect-explain"
            >
              {{ $t('corpSettingApi.salesforce.explain') }}
            </BListItem>
            <div class="mt-100">
              <span>{{ $t(`corpSettingApi.salesforce.connectStatus`) }}：</span>
              <span v-if="!isConnect">{{ $t(`corpSettingApi.salesforce.status.unconnect`) }}</span>
              <span v-if="isConnect">{{ $t(`corpSettingApi.salesforce.status.connect`) }}</span>
              <span
                v-if="isExpired"
                class="token-expire"
              >{{ $t(`corpSettingApi.salesforce.status.expired`) }}</span>
            </div>
            <BListItem
              v-if="isConnect"
              class="mt-50"
            >
              <span>{{ $t(`corpSettingApi.salesforce.connectUserName`) }}：</span>
              <span>{{ salesforceSetting.connectUserName }}</span>
            </BListItem>
            <BListItem
              v-if="isConnect"
              class="mt-50"
            >
              <span>{{ $t(`corpSettingApi.salesforce.apiCallNum`) }}：</span>
              <span v-if="!salesforceApiRequestLimit.errorMessage">{{ salesforceApiRequestLimit.used }}/{{ salesforceApiRequestLimit.max }}</span>
              <span v-else>{{ salesforceApiRequestLimit.errorMessage }}</span>
            </BListItem>
            <BListItem class="mt-300">
              <div class="sf-connect-buttons">
                <BBtn
                  class="mr-100"
                  type="primary"
                  :disabled="loading || !$can('owner')"
                  @click="salesforceConnect(false)"
                >
                  <span>{{ syncText }}</span>
                </BBtn>
                <BBtn
                  type="primary"
                  v-if='isConnect == false'
                  :disabled="loading || !$can('owner')"
                  @click="salesforceConnect(true)"
                >
                  <span>{{ sandboxsyncText }}</span>
                </BBtn>
              </div>
            </BListItem>
            <BListItem
              class="mt-300"
            >
              <BBtn
                type="primary"
                :disabled="isConnect == false || loading || !$can('owner')"
                @click="changeSfSyncSetting"
              >
                <span>{{ syncSettingText }}</span>
              </BBtn>
            </BListItem>
          </div>
        </BLayout>
      </div>
    </template>
  </BContentBox>
</template>

<script>
import { mapGetters } from 'vuex';
import SalesforceSettingApi from '@/api/user/resources/salesforce/salesforce_setting';
import { SfLeadSyncSettingService } from '@/api/user/resources/salesforce/sf_lead_sync_setting';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import SalesforceConst from '@/const/salesforce';
import errorHandler from '@/mixins/error_handler';
import inputValidation from '@/mixins/input_validation';

export default {
  name: 'SalesforceApi',
  mixins: [inputValidation, errorHandler],
  props: {
    salesforceApiInfo: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'reload-salesforce-api-info',
  ],
  setup() {
    const { isAvailableSalesforceLinkFeature } = useAvailableFeatureCheck();
    return {
      isAvailableSalesforceLinkFeature,
    };
  },
  data () {
    return {
      loading: false,
      isEnabled: false,
    };
  },
  computed: {
    ...mapGetters('user', [
      'currentUser',
    ]),
    salesforceSetting () {
      return this.salesforceApiInfo.data?.salesforceSetting;
    },
    salesforceApiRequestLimit() {
      return this.salesforceApiInfo.data?.salesforceApiRequestLimit;
    },
    syncText () {
      if (this.isConnect) {
        return this.$t(`corpSettingApi.salesforce.btnDisconnect`);
      } else {
        return this.$t(`corpSettingApi.salesforce.btnText`);
      }
    },
    sandboxsyncText() {
      return this.$t(`corpSettingApi.salesforce.btnSandboxText`);
    },
    syncSettingText() {
      if (this.isEnabled) {
        return this.$t(`corpSettingApi.salesforce.syncDisableBtnText`);
      } else {
        return this.$t(`corpSettingApi.salesforce.synEnableBtnText`);
      }
    },
    isConnect () {
      return this.salesforceSetting?.connectStatus ==  SalesforceConst.Connected;
    },
    isExpired () {
      return this.salesforceSetting?.connectStatus == SalesforceConst.TokenExpired;
    },
  },
  async created() {
    try {
      this.loading = true;
      await this.getSfSyncSetting();
      this.$emit('reload-salesforce-api-info');
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async handleDeleteSetting() {
      try {
        this.loading = true;
        const result = await SalesforceSettingApi.deleteSalesforceSetting();
        this.$emit('reload-salesforce-api-info');
        this.success = result.data;
      } finally {
        this.loading = false;
      }
    },
    async salesforceConnect (sandbox) {
      if (this.isConnect) {
        const check = await this.$bitterAlert.show({
          title: this.$t('corpSettingApi.salesforce.cancelLinkConfirmText'),
          text: this.$t('general.confirmText'),
        });

        if (!check) { return; }

        await this.handleDeleteSetting();
      } else {
        if (sandbox) {
          location.href = this.generateSfSandboxAuthLink();
        } else {
          location.href = this.generateProdSfAuthLink();
        }
      }
    },
    async changeSfSyncSetting() {
      const check = await this.$bitterAlert.show({
        title: this.$t('corpSettingApi.salesforce.changeSyncSettingConfirmText'),
        text: this.$t('general.confirmText'),
      });

      if (!check) { return; }

      if (this.isEnabled) {
        await this.disableSfSyncSetting();
      } else {
        await this.enableSfSyncSetting();
      }
    },
    generateSfSandboxAuthLink () {
      const url = 'https://test.salesforce.com/services/oauth2/authorize';
      const redirectUri = this.generateSfAuthLink(url)
      return `${redirectUri}&state=sandbox`
    },
    generateProdSfAuthLink () {
      const url = 'https://login.salesforce.com/services/oauth2/authorize';
      const redirectUri = this.generateSfAuthLink(url)
      return `${redirectUri}&state=prod`
    },
    generateSfAuthLink(url) {
      const clientId = import.meta.env.VITE_SALESFORCE_CLIENT_ID;
      const splitHostname = location.hostname.split('.');
      splitHostname.shift();
      const appDomain = splitHostname.join('.');
      const redirectUrl = `https://${this.currentUser.slug}.${appDomain}/app/salesforce/oauth2/callback`;
      return `${url}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}`;
    },
    async enableSfSyncSetting () {
      const api = new SfLeadSyncSettingService();
      await api.enableSfLeadSyncSetting();
      this.isEnabled = true;
    },
    async disableSfSyncSetting () {
      const api = new SfLeadSyncSettingService();
      await api.disableSfLeadSyncSetting();
      this.isEnabled = false;
    },
    async getSfSyncSetting () {
      const api = new SfLeadSyncSettingService();
      const result = await api.getSfLeadSyncSettingStatus();
      this.isEnabled = result.data?.sfLeadSyncSetting?.enableFlg === true;
      return result.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.sf-connect-explain {
  color: $concrete-dark;
  margin-bottom: 20px;
  white-space: pre-wrap;
}

.token-expire {
  color: $basecolor-error;
}

.sf-connect-buttons {
  display: flex;
}

.general-footer {
  padding-top: $basespace-600;
}
</style>
