import { inject } from 'vue';
import { KEY_OF_MODAL } from '@/injection-keys';

const useModal = (getModalName: () => string) => {
  const modal = inject(KEY_OF_MODAL);
  const openModal = () => {
    modal.show(getModalName());
  };
  const closeModal = () => {
    modal.hide(getModalName());
  };
  return { openModal, closeModal };
};

export { useModal };
