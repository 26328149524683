<template>
  <BLayout align-center>
    <MoveButton
      v-bind="moveButtonProps"
      :disabled="moveButtonDisabled"
      @change-target-page="changeTargetPage"
      @change-target-call-target="changeTargetCallTarget"
      @close-modal="$emit('closeModal')"
    />
    <div>
      <BLayout align-center>
        <BTooltip
          v-if="isAvailableSalesforceApiFeature"
          top
          :content="$t('callTarget.header.salesforce')"
        >
          <BBtn
            v-bind="buttonAttributes"
            class="mr-200"
          >
            <BSfStatusIcon
              :connect-status="sfConnectedStatus"
              @open-sf-modal="openSfModal"
            />
          </BBtn>
        </BTooltip>
        <SfStatusModal
          v-if="isAvailableSalesforceApiFeature"
          modal-name="sfRelatedLinkModalDetail"
          :sf-related-link-info="callTarget.sfRelatedLinkInfo"
        />
        <div v-if="(sfSyncSettingInfo?.sfUserId != null && sfSyncSettingInfo?.callResultSfSync) || !sfSyncSettingInfo?.callResultSfSync">
          <ZoomPhoneOpenButton v-if="activeCti === 'zoom'" />
          <MiitelOpenButton v-if="activeCti === 'miitel'" />
        </div>
        <BDivider
          class="py-100"
          direction="vertical"
        />
        <BTooltip
          top
          :content="$t('callTarget.header.freeAction')"
          :disabled="targetFreeActionFormDisable"
        >
          <BCustomIcon
            class="mr-300 ml-200 custom-icon"
            icon-class="b-memo"
            :disabled="targetFreeActionFormDisable"
            data-test="comment"
            hover
            @click="handleTargetFreeActionForm"
          />
        </BTooltip>
        <BTooltip
          top
          :content="$t('callTarget.header.callResult')"
          :disabled="callResultFormDisable"
        >
          <BCustomIcon
            class="mr-300 custom-icon"
            icon-class="b-call-add"
            :disabled="callResultFormDisable"
            data-test="callResult"
            hover
            @click="handleOpenCallResultForm"
          />
        </BTooltip>
        <BTooltip
          top
          :content="$t('callTarget.header.nextAction')"
          :disabled="targetSingleNextActionFormDisable"
        >
          <BCustomIcon
            class="mr-300 custom-icon"
            icon-class="b-next-action"
            :disabled="targetSingleNextActionFormDisable"
            data-test="nextAction"
            hover
            @click="handleSingleNextActionForm"
          />
        </BTooltip>
        <BTooltip
          v-if="isAvailableDirectMailFeature"
          top
          :content="mailFormDisableTooltipText"
        >
          <BCustomIcon
            class="mr-300 custom-icon"
            icon-class="b-mail-send"
            :disabled="targetMailFormDisable"
            hover
            @click="$emit('clickMailButton')"
          />
        </BTooltip>
        <BBtn
          class="opportunity-open-btn mr-500"
          :disabled="opportunityDisable"
          type="emphasis"
          data-test="opportunity"
          @click="handleOpenOpportunityForm"
        >
          <span>{{ $t('callTarget.header.opportunity') }}</span>
        </BBtn>
      </BLayout>
    </div>
  </BLayout>
</template>

<script>
import { mapWaitingActions } from 'vue-wait';
import { mapGetters } from 'vuex';
import SfStatusModal from '@/components/organisms/user/general/modal/salesforce/SfStatusModal.vue';
import MiitelOpenButton from '@/components/organisms/user/lead/header/MiitelOpenButton.vue';
import ZoomPhoneOpenButton from '@/components/organisms/user/lead/header/ZoomPhoneOpenButton.vue';
import { useActiveCti } from '@/composable/active-cti';
import { useAvailableFeatureCheck } from '@/composable/available-feature-check';
import BSfStatusIcon from '@/plugins/biscuet-materials/components/molecules/BSfStatusIcon.vue';

export default {
  components: {
    SfStatusModal,
    BSfStatusIcon,
    ZoomPhoneOpenButton,
    MiitelOpenButton,
  },
  props: {
    callTargetId: Number,
    pageLoading: Boolean,
    sfSyncSettingInfo: {
      type: Object,
      required: false,
    },
    callTargets: {
      type: Array,
      required: true,
    },
    callTargetsMeta: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'closeModal',
    'clickMailButton',
    'changeTargetPage',
    'changeTargetCallTarget',
  ],
  setup() {
    const { isAvailableSalesforceApiFeature, isAvailableDirectMailFeature } = useAvailableFeatureCheck();
    const { activeCti } = useActiveCti(true);
    return {
      isAvailableSalesforceApiFeature,
      isAvailableDirectMailFeature,
      activeCti,
    };
  },
  computed: {
    ...mapGetters('userUi', [
      'targetCallResultFormVisible',
      'targetOpportunityFormVisible',
      // TOO form from修正
      'targetFreeActionFromVisible',
      'targetSingleNextActionFormVisible',
      'targetHeaderMoveButtonDisabled',
      'targetMailFormVisible',
      'isClosingAndMovingLeadEnabled',
    ]),
    ...mapGetters('user', [
      'currentUser',
      'callTarget',
    ]),
    moveButtonProps () {
      return {
        callTargetId: this.callTargetId,
        currentCallTargets: this.callTargets,
        callTargetsMeta: this.callTargetsMeta,
        pageLoading: this.pageLoading,
      };
    },
    callResultFormDisable () {
      return this.targetCallResultFormVisible;
    },
    opportunityDisable () {
      return this.targetOpportunityFormVisible;
    },
    // TODO fix name to form visible
    targetFreeActionFormDisable () {
      return this.targetFreeActionFromVisible;
    },
    targetSingleNextActionFormDisable () {
      return this.targetSingleNextActionFormVisible;
    },
    targetMailFormDisable () {
      return !this.callTarget.callTargetInfo?.email || this.targetMailFormVisible;
    },
    mailFormDisableTooltipText () {
      return this.targetMailFormDisable ? this.$t('general.input.of', { item: `${this.$t('callTargetInfo.title')}${this.$t('callTargetInfo.item.email')}` }) : this.$t('callTarget.header.directMail');
    },
    moveButtonDisabled () {
      return this.targetHeaderMoveButtonDisabled || !this.isClosingAndMovingLeadEnabled;
    },
    buttonAttributes () {
      return {
        size: 'small',
        fab: true,
        flat: true,
      };
    },
    sfConnectedStatus(){
      return this.callTarget.sfRelatedLinkInfo?.sfRelatedLinks[0]?.status;
    },
  },
  mounted() {
    this.$store.subscribeAction({
      before: (action, _state) => {
        if (action.type === 'user/getCallTargetAction' || action.type === 'user/getLeadHistoriesAction') {
          this.setTargetHeaderMoveButtonDisabledAction(true);
        }
      },
      after: (action, _state) => {
        if (action.type === 'user/getCallTargetAction' || action.type === 'user/getLeadHistoriesAction') {
          this.setTargetHeaderMoveButtonDisabledAction(false);
        }
      },
    });
  },
  methods: {
    ...mapWaitingActions('userUi', {
      setTargetCallResultFormVisibleAction: 'setTargetCallResultFormVisibleWait',
      resetTargetCallResultFormLogValuesAction: 'resetTargetCallResultFormLogValueszWait',
      setTargetOpportunityFormVisibleAction: 'setTargetOpportunityFormVisibleWait',
      setTargetFreeActionFormVisibleAction: 'setTargetFreeActionFormVisibleWait',
      setTargetSingleNextActionFormVisibleAction: 'setTargetSingleNextActionFormVisibleWait',
      setTargetHeaderMoveButtonDisabledAction: 'setTargetHeaderMoveButtonDisabledWait',
    }),
    sfRelatedLinkModalName() {
      this.$modal.show('sfRelatedLinkModalDetail');
    },
    openSfModal() {
      this.$modal.show('sfRelatedLinkModalDetail');
    },
    changeTargetPage (id, page) {
      this.$emit('changeTargetPage', id, page);
    },
    changeTargetCallTarget (id) {
      this.$emit('changeTargetCallTarget', id);
    },
    handleOpenCallResultForm () {
      if (this.sfSyncSettingInfo?.sfUserId == null && this.sfSyncSettingInfo?.callResultSfSync) {
        this.$bitterAlert.show({
          title: this.$t('callTarget.errors.salesforceUnableToLinkUser.title'),
          text: this.$t('callTarget.errors.salesforceUnableToLinkUser.text'),
        });
      } else {
        this.setTargetHeaderMoveButtonDisabledAction(true);
        this.resetTargetCallResultFormLogValuesAction();
        this.setTargetCallResultFormVisibleAction(true);
      }
    },
    handleOpenOpportunityForm () {
      this.setTargetHeaderMoveButtonDisabledAction(true);
      this.setTargetOpportunityFormVisibleAction(true);
    },
    handleSingleNextActionForm () {
      this.setTargetHeaderMoveButtonDisabledAction(true);
      this.setTargetSingleNextActionFormVisibleAction(true);
    },
    handleTargetFreeActionForm () {
      if (this.sfSyncSettingInfo?.sfUserId == null && this.sfSyncSettingInfo?.freeActionSfSync) {
        this.$bitterAlert.show({
          title: this.$t('callTarget.errors.salesforceUnableToLinkUser.title'),
          text: this.$t('callTarget.errors.salesforceUnableToLinkUser.text'),
        });
      } else {
        this.setTargetHeaderMoveButtonDisabledAction(true);
        this.setTargetFreeActionFormVisibleAction(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .custom-icon {
    cursor: pointer;
  }
</style>
