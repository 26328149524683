<template>
  <div class="custom-field-simple-select-container">
    <span
      v-if="isDisplayOnly"
    >
      {{ selectedCustomField(model).name }}
    </span>
    <BSelect
      v-else
      v-model="model"
      v-model:valid="isValid"
      :items="booleanCustomFields"
      :required="required"
      item-text="name"
      item-value="id"
      fit
      filterable
    />
  </div>
</template>

<script lang="ts" setup>
import {  ref, watch, computed } from 'vue';
import { CustomField } from '@/api/openapi';
import { CustomFieldsApiService } from '@/api/user/resources/custom_fields';
import { useWait } from '@/composable/vue-wait';

type TProps = {
  required: boolean;
  isDisplayOnly: boolean;
};

type TEmits = {
  'change': [CustomField | null];
};

const props = defineProps<TProps>();
const { doActionWithWait } = useWait();

const model = defineModel<number | null>();
const isValid = defineModel<boolean>('isValid', { default: false });

const customFields = ref<CustomField[]>([]);

// Triggerで対象のカスタム項目を増やしていく際にdataTypeをついかしていく
const booleanCustomFields = computed(() => {
  return customFields.value.filter((field) => field.dataType === 'boolean');
});

const loadCustomFields = async () => {
  const api = new CustomFieldsApiService();
  await doActionWithWait('fetchCustomFields', async () => {
    const { data } = await api.getCustomFields({});
    customFields.value = data.customFields;
  });
};

customFields

loadCustomFields();

const selectedCustomField = (customFieldId: number) => {
  return customFields.value.find((customField) => customField.id === customFieldId);
};

const emit = defineEmits<TEmits>();

const handleChange = () => {
  emit('change', selectedCustomField(model.value));
};

watch(model, () => {
  handleChange();
});

</script>

<style lang="scss" scoped>
.custom-field-simple-select-container {
  position: relative;

  .custom-field-icon {
    margin-right: 1px;
  }
  .custom-field-btn {
    position: absolute;
    bottom: 46px;
    right: 0;
  }
}
</style>
