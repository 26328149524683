<template>
  <div
    v-if="canDisplayInputs"
    class="sequence-form"
  >
    <BListItem>
      <template #header>
        <span>{{ $t('callResult.sequence.title') }}</span>
      </template>
      <template v-if="connectedToAnotherNextAction">
        <div class="connected-to-another-next-action">
          {{ $t('callResult.sequence.connectedToAnotherNextAction') }}
        </div>
      </template>
      <template v-else>
        <BCheckbox
          v-model="connecting"
          class="connecting-checkbox"
          :readonly="!canEditConnecting"
          :label="$t('callResult.sequence.connecting', { stepName: sequenceStepName })"
        />
        <BSelectBtn
          v-show="connecting"
          v-model="completing"
          :disabled-value="completingDisabledValue"
          :items="completingItems"
          class="completing-select"
        />
      </template>
    </BListItem>
  </div>
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { CallResult } from '@/api/openapi';
import { useNextActionCallResultWithSequence } from '@/composable/user/callResult/next-action-call-result';
import BCheckbox from '@/plugins/biscuet-materials/components/atoms/BCheckbox.vue';

type TProps = {
  formVisible: boolean;
  activeSequenceInstanceId: number | null;
  selectedCallResult: CallResult | Record<string, never>;
  selectedNextActionId: number | null;
};
type TEmit = {
  'update:canEdit': [boolean];
  'update:connectingNextActionId': [number];
};

const props = defineProps<TProps>();
const emit = defineEmits<TEmit>();

const connecting = defineModel<boolean>('connecting', {
  default: true,
});
const completing = defineModel<boolean>('completing', {
  default: false,
});

const i18n = useI18n();
const {
  initializeConnectingValues,
  canDisplayInputs,
  canEdit,
  canEditConnecting,
  canEditCompleting,
  sequenceStepName,
  connectedToAnotherNextAction,
  refetchCurrentSequenceStepInstance,
  connectingNextActionId,
} = useNextActionCallResultWithSequence(() => props.selectedCallResult, () => props.selectedNextActionId);
// フォーム値の初期化
watch(() => props.selectedCallResult, (value) => {
  initializeConnectingValues(value, connecting, completing);
}, { immediate: true });

const completingItems = [
  {
    text: i18n.t('callResult.sequence.completing.completing'),
    value: true,
  },
  {
    text: i18n.t('callResult.sequence.completing.notCompleting'),
    value: false,
  },
];

watch(() => props.activeSequenceInstanceId, async (value) => {
  if (props.selectedNextActionId != null) return;
  await refetchCurrentSequenceStepInstance(value);
}, { immediate: true });
watch(() => props.formVisible, async (value) => {
  if (!value) return;
  await refetchCurrentSequenceStepInstance(props.activeSequenceInstanceId);
}, { immediate: true });

// NOTE: 編集不可の場合、全項目選択不可にし、readonlyと同等の表示にする
const completingDisabledValue = computed(() => {
  return canEditCompleting.value ? [] : [true, false];
});

// フォームの更新用の値
watch(connectingNextActionId, (newConnectingNextActionId) => {
  emit('update:connectingNextActionId', newConnectingNextActionId);
}, { immediate: true });
watch(canEdit, (newVal) => {
  emit('update:canEdit', newVal);
}, { immediate: true });
watch(() => props.formVisible, (newVal) => {
  if (!newVal) return;
  // フォームを開いたタイミングで上書き
  emit('update:connectingNextActionId', connectingNextActionId.value);
  emit('update:canEdit', canEdit.value);
}, { immediate: true });
</script>

<style lang="scss" scoped>
.sequence-form {
  margin-bottom: $basespace-300;
  .connecting-checkbox {
    margin-top: 6px;
    margin-bottom: $basespace-50;
  }
  .connected-to-another-next-action {
    background-color: $bgcolor-base;
    color: $textcolor-light;
    padding: $basespace-200;
  }
}
</style>
