<template>
  <div>
    <div class="py-300">
      <h3>
        <BIcon
          rounded
          class="mr-100"
        >
          groups
        </BIcon>
        {{ $t('sisense.nextAction.activity') }}
      </h3>
      <BCard
        v-loading="loading"
        class="sisense-dashboard-card mt-200"
      >
        <ThemeProvider :theme="theme">
          <ElContainer
            class="sisense-dashboard-container"
          >
            <ElMain
              class="sisense-dashboard-main"
            >
              <h4 class="mb-300">
                {{ $t('general.nextAction') }}
              </h4>
              <BFlex
                class="sisense-dashboard-chart"
              >
                <ElButtonGroup
                  class="sisense-dashboard-button-group"
                >
                  <ElButton
                    :type="indexType(0)"
                    class="sisense-dashboard-button"
                    @click="handleClickChartIndex(0)"
                  >
                    {{ $t('sisense.nextAction.byDate') }}
                  </ElButton>
                  <ElButton
                    :type="indexType(1)"
                    class="sisense-dashboard-button"
                    @click="handleClickChartIndex(1)"
                  >
                    {{ $t('sisense.nextAction.byType') }}
                  </ElButton>
                  <ElButton
                    :type="indexType(2)"
                    class="sisense-dashboard-button"
                    @click="handleClickChartIndex(2)"
                  >
                    {{ $t('sisense.nextAction.byPriority') }}
                  </ElButton>
                </ElButtonGroup>
                <ColumnChart
                  v-if="chartIndex === 0"
                  :data-set="dataSource"
                  :data-options="reservedAtChartProps.dataOptions"
                  :style-options="styleOptions"
                  :filters="multiFilters"
                  :on-data-point-click="(dataPoint, event) => {
                    handleClickReservedAt(dataPoint);
                  }"
                />
                <BarChart
                  v-if="chartIndex === 1"
                  :data-set="dataSource"
                  :data-options="actionTypeNameChartProps.dataOptions"
                  :style-options="styleOptions"
                  :filters="multiFilters"
                  :on-data-point-click="(dataPoint, event) => {
                    handleClickActionTypeName(dataPoint);
                  }"
                />
                <BarChart
                  v-if="chartIndex === 2"
                  :data-set="dataSource"
                  :data-options="priorityNameChartProps.dataOptions"
                  :style-options="styleOptions"
                  :filters="multiFilters"
                  :on-data-point-click="(dataPoint, event) => {
                    handleClickPriorityName(dataPoint);
                  }"
                />
              </BFlex>
            </ElMain>
            <ElAside
              class="sisense-dashboard-aside"
            >
              <h4 class="mb-300">
                {{ $t('home.filter') }}
              </h4>
              <ElDivider class="mb-300" />
              <BFlex
                class="sisense-dashboard-filter"
              >
                <MemberFilterTile
                  :attribute="ownerFilterTileProp.attribute"
                  :on-change="ownerFilterTileProp.onChange"
                  :data-source="ownerFilterTileProp.dataSource"
                  :title="ownerFilterTileProp.title"
                  :filter="ownerFilterTileProp.filter"
                  :class="{ disabled: ownerFilter.config.disabled }"
                />
                <MemberFilterTile
                  :attribute="priorityFilterTileProp.attribute"
                  :on-change="priorityFilterTileProp.onChange"
                  :data-source="priorityFilterTileProp.dataSource"
                  :title="priorityFilterTileProp.title"
                  :filter="priorityFilterTileProp.filter"
                  :class="{ disabled: priorityNameFilter.config.disabled }"
                />
                <MemberFilterTile
                  :attribute="actionTypeNameFilterTileProp.attribute"
                  :on-change="actionTypeNameFilterTileProp.onChange"
                  :data-source="actionTypeNameFilterTileProp.dataSource"
                  :title="actionTypeNameFilterTileProp.title"
                  :filter="actionTypeNameFilterTileProp.filter"
                  :class="{ disabled: actionTypeNameFilter.config.disabled }"
                />
                <RelativeDateFilterTile
                  :title="reservedAtFilterTileProp.title"
                  :filter="reservedAtFilterTileProp.filter"
                  :arrangement="reservedAtFilterTileProp.arrangement"
                  :on-update="reservedAtFilterTileProp.onUpdate"
                  :class="{ disabled: reservedAtFilter.config.disabled }"
                />
              </BFlex>
            </ElAside>
          </ElContainer>
        </ThemeProvider>
      </BCard>
    </div>
    <div class="py-300">
      <h3>
        <BIcon
          rounded
          class="mr-100"
        >
          tv_options_edit_channels
        </BIcon>
        {{ $t('sisense.nextAction.list') }}
      </h3>
      <BCard
        v-loading="$wait.is('fetchNextActions')"
        class="next-action-list"
      >
        <div
          v-infinite-scroll="loadNextActions"
          :infinite-scroll-disabled="scrollDisabled"
        >
          <NextActionComponent
            v-for="nextAction in sortedNextActions"
            :key="nextAction.id"
            :next-action="nextAction"
          />
          <BLayout
            v-if="$wait.is('fetchNextActions')"
            class="py-200"
            justify-center
          >
            <BElementIcon name="loading" />
          </BLayout>
          <div
            v-else-if="sortedNextActions?.length == 0"
            class="pt-300 pb-300 pl-600"
          >
            {{ $t(`sisense.empty`) }}
          </div>
        </div>
      </BCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import { measureFactory, filterFactory, Filter, MembersFilter, RelativeDateFilter } from '@sisense/sdk-data';
import {
  ThemeProvider,
  ColumnChart,
  BarChart,
  MemberFilterTile,
  RelativeDateFilterTile,
  type ColumnChartProps,
  type BarChartProps,
  type MemberFilterTileProps,
  type RelativeDateFilterTileProps,
} from '@sisense/sdk-ui-vue';
import { ref, computed, onBeforeMount, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { NextAction, FilterCondition } from '@/api/openapi';
import { NextActionApiService } from '@/api/user/resources/next_action';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import * as DM from '@/data-models/bc-data-source-V-0-0-1';
import BLayout from '@/plugins/biscuet-materials/components/atoms/BLayout.vue';
import { DateRangeOperator, formatISO8601Date, isDateValid, getDateRange } from '@/utils/date-time';
import NextActionComponent from './NextAction.vue';

const SISENSE_NULL_VALUE = 'N\A';

const i18n = useI18n();
const { currentUser, fetchCurrentUserPromise } = useCurrentUser();
const { doActionWithWait, wait } = useWait();
const chartIndex = ref(0);
const nextActions = ref<NextAction[]>([]);
const page = ref(1);
const totalCount = ref(0);
const today = formatISO8601Date(Date.now());

const styleOptions = {
  height: 590,
  navigator: {
    enabled: false,
  },
};
const theme = ref({
  general: {
    backgroundColor: '#FAFAFA',
    brandColor: '#2CBFB7',
  },
  palette: {
    variantColors: ['#2CBFB7'],
  },
});

const dataSource = DM.DataSource;
const isDone = ref<boolean>(false);
const reservedAtFrom = ref<string>(today);
const reservedAtTo = ref<string>(today);
const ownerName = ref<string[]>([]);
const ownerNameCondition = ref<FilterCondition>(FilterCondition.Inc);
const actionTypeName = ref<string[]>([]);
const actionTypeNameCondition = ref<FilterCondition>(FilterCondition.Inc);
const priorityName = ref<string[]>([]);
const priorityNameCondition = ref<FilterCondition>(FilterCondition.Inc);

const isDoneFilter = ref<Filter>(filterFactory.equals(DM.NextAction.is_done, 'false'));
const reservedAtFilter = ref<Filter>(filterFactory.dateRelative(DM.NextAction.reserved_at_jst.Days, 0, 1, today));
const reservedAtNullFilter = ref<Filter>(filterFactory.members(DM.NextAction.reserved_at_jst, []));
const ownerFilter = ref<Filter>(filterFactory.members(DM.NextAction.owner_name, []));
const actionTypeNameFilter = ref<Filter>(filterFactory.members(DM.NextAction.action_type_name, []));
const priorityNameFilter = ref<Filter>(filterFactory.members(DM.NextAction.priority_name, []));

const multiFilters = computed<Filter[]>(() => {
  return [
    isDoneFilter.value,
    reservedAtFilter.value,
    reservedAtNullFilter.value,
    ownerFilter.value,
    actionTypeNameFilter.value,
    priorityNameFilter.value,
  ];
});

// 予定日フィルター
const handleReservedAtFilter = (filter) => {
  reservedAtFilter.value = filter;
  setReservedAt();
};
const reservedAtFilterTileProp = ref<RelativeDateFilterTileProps>({
  title: i18n.t('sisense.nextAction.byDate'),
  filter: reservedAtFilter,
  arrangement: 'vertical',
  onUpdate: handleReservedAtFilter,
});

// 所有者フィルター
const handleOwnerFilter = (filter) => {
  ownerFilter.value = filter;
  ownerName.value = filter.config.disabled ? [] : filter.members;
  ownerNameCondition.value = filter.config.excludeMembers ? FilterCondition.Exc : FilterCondition.Inc;
};
const ownerFilterTileProp = ref<MemberFilterTileProps>({
  dataSource,
  title: i18n.t('sisense.nextAction.owner'),
  attribute: DM.NextAction.owner_name,
  filter: ownerFilter,
  onChange: handleOwnerFilter,
});

// タイプフィルター
const handleActionTypeNameFilter = (filter) => {
  actionTypeNameFilter.value = filter;
  actionTypeName.value = filter.config.disabled ? [] : filter.members;
  actionTypeNameCondition.value = filter.config.excludeMembers ? FilterCondition.Exc : FilterCondition.Inc;
};
const actionTypeNameFilterTileProp = ref<MemberFilterTileProps>({
  dataSource,
  title: i18n.t('sisense.nextAction.byType'),
  attribute: DM.NextAction.action_type_name,
  filter: actionTypeNameFilter,
  onChange: handleActionTypeNameFilter,
});

// 優先度フィルター
const handlePriorityNameFilter = (filter) => {
  priorityNameFilter.value = filter;
  priorityName.value = filter.config.disabled ? [] : filter.members;
  priorityNameCondition.value = filter.config.excludeMembers ? FilterCondition.Exc : FilterCondition.Inc;
};
const priorityFilterTileProp = ref<MemberFilterTileProps>({
  dataSource,
  title: i18n.t('sisense.nextAction.byPriority'),
  attribute: DM.NextAction.priority_name,
  filter: priorityNameFilter,
  onChange: handlePriorityNameFilter,
});

// 次のアクション:予定日別
const reservedAtChartProps = ref<ColumnChartProps>({
  dataOptions: {
    category: [DM.NextAction.reserved_at_jst.Days],
    value: [
      measureFactory.count(DM.NextAction.NextAction_id, i18n.t('sisense.nextAction.count')),
    ],
    breakBy: [],
  },
});

// 次のアクション:タイプ別
const actionTypeNameChartProps = ref<BarChartProps>({
  dataOptions: {
    category: [DM.NextAction.action_type_name],
    value: [{
      column: measureFactory.count(DM.NextAction.NextAction_id, i18n.t('sisense.nextAction.count')),
      sortType: 'sortDesc',
    }],
    breakBy: [],
  },
});

// 次のアクション:優先度別
const priorityNameChartProps = ref<BarChartProps>({
  dataOptions: {
    category: [DM.NextAction.priority_name],
    value: [{
      column: measureFactory.count(DM.NextAction.NextAction_id, i18n.t('sisense.nextAction.count')),
      sortType: 'sortDesc',
    }],
    breakBy: [],
  },
});

onBeforeMount(async () => {
  await doActionWithWait('onMountedWait', async () => {
    await fetchCurrentUserPromise;
  });
});

watch([
  ownerFilter,
  reservedAtNullFilter,
  reservedAtFilter,
  actionTypeNameFilter,
  priorityNameFilter,
], () => {
  page.value = 1;
  totalCount.value = 0;
  fetchNextActions();
});

watch(currentUser, () => {
  if (currentUser.value) {
    ownerFilter.value = filterFactory.members(DM.NextAction.owner_name, [currentUser.value.name]);
    handleOwnerFilter(ownerFilter.value);
  }
});

const loading = computed(() => {
  return wait.is(['onMountedWait']);
});

const sortedNextActions = computed(() => {
  return nextActions.value?.slice(0).sort((a, b) => a.name < b.name ? -1 : 1);
});

const scrollDisabled = computed(() => {
  return wait.is('fetchNextActions') || nextActions.value.length >= totalCount.value;
});

const reservedAtNull = computed(() => {
  const filter: MembersFilter = reservedAtNullFilter.value as MembersFilter;
  return filter.members.length > 0 && filter.members[0] === SISENSE_NULL_VALUE;
});

const loadNextActions = () => {
  if (scrollDisabled.value) return;
  page.value += 1;
  fetchNextActions();
};

const fetchNextActions = async () => {
  await doActionWithWait('fetchNextActions', async () => {
    const api = new NextActionApiService();
    const { data } = await api.getNextActions({
      request: {
        page: page.value,
        isDone: isDone.value,
        reservedAtNull: reservedAtNull.value,
        reservedAtFrom: reservedAtFrom.value,
        reservedAtTo: reservedAtTo.value,
        ownerName: ownerName.value,
        ownerNameCondition: ownerNameCondition.value,
        actionTypeName: actionTypeName.value,
        actionTypeNameCondition: actionTypeNameCondition.value,
        priorityName: priorityName.value,
        priorityNameCondition: priorityNameCondition.value,
      },
    });
    if (page.value === 1) nextActions.value = [];
    nextActions.value = [...nextActions.value, ...data.nextActions];
    totalCount.value = data.meta.totalCount || 0;
  });
};

const indexType = (index: number) => {
  return chartIndex.value === index ? 'primary': 'default';
};

const handleClickChartIndex = (index: number) => {
  chartIndex.value = index;
};

const stackReservedAtFilter = ref<Filter>();

const handleClickReservedAt = (dataPoint) => {
  if (stackReservedAtFilter.value) {
    reservedAtFilter.value = stackReservedAtFilter.value;
    reservedAtNullFilter.value = filterFactory.members(DM.NextAction.reserved_at_jst, []);
    stackReservedAtFilter.value = null;
    setReservedAt();
  } else {
    stackReservedAtFilter.value = reservedAtFilter.value;
    if (isDateValid(dataPoint.categoryValue)) {
      const selectedFrom = formatISO8601Date(dataPoint.categoryValue);
      reservedAtFilter.value = filterFactory.dateRelative(DM.NextAction.reserved_at_jst.Days, 0, 1, selectedFrom);
    } else {
      reservedAtNullFilter.value = filterFactory.members(DM.NextAction.reserved_at_jst, [SISENSE_NULL_VALUE]);
    }
    setReservedAt();
  }
};

const handleClickActionTypeName = (dataPoint) => {
  actionTypeName.value = actionTypeName.value.length > 0 ? [] : [dataPoint.categoryValue];
  actionTypeNameFilter.value = filterFactory.members(DM.NextAction.action_type_name, actionTypeName.value);
};

const handleClickPriorityName = (dataPoint) => {
  priorityName.value = priorityName.value.length > 0 ? [] : [dataPoint.categoryValue];
  priorityNameFilter.value = filterFactory.members(DM.NextAction.priority_name, priorityName.value);
};

const setReservedAt = () => {
  const filter: RelativeDateFilter = reservedAtFilter.value as RelativeDateFilter;
  let range = [];

  if (!filter.config.disabled) {
    const fromDate = formatISO8601Date(filter.anchor) || today;
    range = getDateRange(fromDate, filter.count, filter.attribute.granularity, filter.operator as DateRangeOperator);
  }

  if (range.length === 2) {
    reservedAtFrom.value = range[0];
    reservedAtTo.value = range[1];
  } else {
    reservedAtFrom.value = undefined;
    reservedAtTo.value = undefined;
  }
};
</script>

<style lang="scss" scoped>
.sisense-dashboard-card {
  padding: 0;

  .sisense-dashboard-container {
    height: 740px;

    .sisense-dashboard-main {
      background-color: $paper;
      padding: 32px;

      .sisense-dashboard-chart {
        height: 590px;

        .sisense-dashboard-button-group {
          display: flex;
          width: 100%;

          .sisense-dashboard-button {
            flex: 1;

            &.el-button--default {
              background-color: var(--el-fill-color-blank);
            }
            &.el-button--primary {
              color: $uranus;
              font-weight: bold;
              background-color: var(--el-color-primary-light-9);
            }
          }
        }
      }
    }

    .sisense-dashboard-aside {
      width: 255px;
      background-color: $concrete-light;
      padding: 20px;

      .sisense-dashboard-filter {
        > :deep(div) {
          margin-top: $basespace-100;

          main {
            button {
              color: #FFFFFF;
              border: 1px solid $concrete-light;
            }
          }

          &.disabled {
            main {
              button {
                border: 1px solid #EAEAEA;
              }
            }
          }
        }
      }
    }
  }
}

.next-action-list {
  padding: 0;
  min-height: 60px;
  margin-top: $basespace-200;
  margin-bottom: $basespace-600;

  :deep(.task-container) {
    padding: $basespace-300 $basespace-600;

    &:not(:last-child) {
      border-bottom: 1px solid $bdcolor-light;
    }
  }
}
</style>
