<template>
  <CustomFieldMultiOption
    v-if="type === 'multi_option'"
    :model-value="innerMultipleOptionValue"
    :call-target-id="callTargetId"
    :custom-field-id="customFieldId"
    :title="title"
    :items="options"
    :loading="loading"
    :data-test="node"
    @update:model-value="handleUpdateMultiOption"
  />
  <BListItem
    v-else
    class="mb-200"
  >
    <template #header>
      <span>{{ title }}</span>
    </template>
    <VWait
      class="full-width"
      for="none"
      :visible="$wait.is('getCallTargetWait')"
    >
      <template #waiting>
        <BContentLoader
          :height="24"
          :width="230"
        >
          <rect
            x="0"
            y="8.5"
            rx="6"
            ry="6"
            width="160"
            height="12"
          />
        </BContentLoader>
      </template>
      <BLayout
        class="list-content"
        row
      >
        <BEditableContentDatetimePicker
          v-if="type === 'datetime'"
          v-model:editing="editing"
          :value="value"
          hyphen
          :loading="loading"
          :data-test="node"
          @check="handleClickCheck"
        />
        <template v-else-if="type === 'text'">
          <BEditableContent
            v-model:editing="editing"
            :value="value"
            :placeholder="title"
            hyphen
            :loading="loading"
            :rules="rules"
            :data-test="node"
            type="textarea"
            :autosize="{ min: 1, max: null }"
            @check="handleClickCheck"
          />
          <BLinkBtn
            v-if="isUrl(value) && !editing"
            :value="value"
          />
        </template>
        <BEditableContentSelect
          v-else-if="type === 'option'"
          v-model:editing="editing"
          :items="options"
          :value="value"
          hyphen
          :loading="loading"
          :data-test="node"
          @check="handleClickCheck"
        />
        <BEditableContent
          v-else-if="type === 'number'"
          v-model:editing="editing"
          :value="value"
          :placeholder="$t('customField.number.validate')"
          hyphen
          :loading="loading"
          :rules="rules"
          :data-test="node"
          :allow-float="true"
          input-component="BNumberInput"
          @check="handleClickCheck"
        />
        <template v-else-if="type === 'sf_reference'">
          <div class="sf_field">
            <span v-if="value">{{ value }}</span>
            <span v-else>-</span>
            <BLinkBtn
              v-if="isUrl(sfLink)"
              :value="sfLink"
            />
          </div>
        </template>

        <BEditableContentBoolean
          v-else-if="type === 'boolean'"
          v-model:editing="editing"
          :value="value"
          hyphen
          :loading="loading"
          :data-test="node"
          @check="handleClickCheck"
        />
        <slot
          v-if="!editing"
          name="help"
        />
      </BLayout>
    </VWait>
  </BListItem>
</template>

<script>
import CustomFieldMultiOption from '@/components/organisms/user/lead/detail/CustomFieldMultiOption.vue';
import { testUrl } from '@/utils/regexp';

export default {
  components: {
    CustomFieldMultiOption,
  },
  props: {
    callTargetId: Number,
    customFieldId: Number,
    title: String,
    node: String,
    editingNode: String,
    value: [String, Number, Array],
    sfLink: String,
    loading: Boolean,
    rules: {
      type: Array,
      default () {
        return [];
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    options: {
      type: Array,
      default () {
        return [];
      },
    },
  },
  emits: [
    'update:editing-node',
    'change',
    'save',
  ],
  data () {
    return {
      editing: false,
      innerMultipleOptionValue: [],
    };
  },
  watch: {
    editingNode(newVal) {
      if (newVal !== this.node) this.editing = false;
    },
    editing(newVal) {
      if (newVal) this.$emit('update:editing-node', this.node);
    },
    value: {
      handler(newVal) {
        this.innerMultipleOptionValue = newVal;
      },
      immediate: true,
    },
  },
  methods: {
    handleClickCheck (event) {
      this.$emit('save', { event, node: this.node });
    },
    isUrl (str) {
      return testUrl(str);
    },
    handleUpdateMultiOption(v) {
      this.innerMultipleOptionValue = v;
      this.$emit('change');
    },
  },
};
</script>

<style lang="scss" scoped>
  .list-content {
    position: relative;
    width: 100%;
    word-wrap: break-word;
    word-break: break-all;
  }
  .sf_field {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
</style>
