<template>
  <BContent
    v-loading="loading"
    invisible-navbar
    class="cockpit"
  >
    <LeadViewList />
    <SisenseContextProvider
      v-if="sisenseAuthChecked"
      :url="sisenseUrl"
      sso-enabled
    >
      <NextActionCharts />
    </SisenseContextProvider>
  </BContent>
</template>

<script setup lang="ts">
import {
  SisenseContextProvider,
} from '@sisense/sdk-ui-vue';
import { computed, onBeforeMount } from 'vue';
import { SisenseApiService } from '@/api/user/resources/sisense';
import LeadViewList from '@/components/organisms/user/home/LeadViewList.vue';
import NextActionCharts from '@/components/organisms/user/home/NextActionCharts.vue';
import { useSisense } from '@/composable/user/sisense/sisense';
import { useCurrentUser } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';

const { sisenseAuthChecked, checkSisenseAuth } = useSisense();
const { doActionWithWait, wait } = useWait();
const { currentUser, fetchCurrentUser } = useCurrentUser();
const api = new SisenseApiService();
const sisenseUrl = `${import.meta.env.VITE_SISENSE_URL}`;

const loading = computed(() => {
  return wait.is('checkSisenseAccount');
});

onBeforeMount(async () => {
  // BCとSisenseのアカウントが合致することをチェック
  await fetchCurrentUser();
  await checkSisenseAccount();
  await checkSisenseAuth(currentUser.value);
});

const checkSisenseAccount = async () => {
  await doActionWithWait('checkSisenseAccount', async () => {
    const loggedin = await api.loggedin();
    if (loggedin.ok) {
      const loggedinJson = await loggedin.json();
      // NOTE: BCアカウントとSisenseアカウントが同じでない場合はSisenseをログアウト
      if (loggedinJson.email !== currentUser.value.email) {
        await api.logout();
      }
    }
  });
};
</script>

<style lang="scss" scoped>
.cockpit {
  background-color: #F5F7FA;
}
:deep(.b-height-adjuster > .content) {
  height: 100%;
  padding: $basespace-300 $basespace-600;
}
</style>
