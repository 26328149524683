<template>
  <MailDrawerTemplate
    :title="bulkMail.campaignName"
    :can-duplicate="true"
    @duplicate="$emit('duplicate')"
  >
    <template #drawerHeaderAction>
      <BBtn
        class="cancel-btn"
        size="mini"
        fab
        flat
        @click="$emit('cancel')"
      >
        <BIcon size="mini">
          clear
        </BIcon>
      </BBtn>
    </template>
    <template #drawerBody>
      <div
        class="drawer-body"
      >
        <div class="mail-confirm mb-600">
          <div class="subject-confirm">
            {{ bulkMail.subject }}
          </div>
          <div class="content-confirm">
            <div
              class="quill-confirm"
              v-html="bulkMail.content"
            />
          </div>
        </div>
        <BListItem class="list-item mb-600">
          <template #header>
            <div>{{ $t('mail.to') }}</div>
          </template>
          <div>{{ selectedLeadViewName }}</div>
        </BListItem>
        <BListItem class="list-item mb-600">
          <template #header>
            <div>{{ $t('mail.sender') }}</div>
          </template>
          <span>{{ senderSet }}</span>
        </BListItem>
        <BListItem
          v-if="replyVisible"
          class="list-item"
        >
          <template #header>
            <div>{{ $t('mail.reply') }}</div>
          </template>
          <span>{{ replySet }}</span>
        </BListItem>
        <BListItem class="list-item">
          <template #header>
            <div>{{ $t('mailNotificationEvent.formTitle') }}</div>
          </template>
          <div>{{ mailEventClickSet }}</div>
          <div class="mt-100">
            {{ mailEventOpenSet }}
          </div>
        </BListItem>
      </div>
    </template>
    <template #drawerFooter>
      <BTooltip
        top
        :content="$t('mail.sentCountLabel')"
      >
        <div class="ml-400">
          <BListItem>
            <template #header>
              <span>{{ $t('mail.sentCount') }}</span>
            </template>
            <div class="lh-1 fs-700 fw-bold">
              {{ bulkMail.sentCount }}
            </div>
          </BListItem>
        </div>
      </BTooltip>
    </template>
  </MailDrawerTemplate>
</template>

<script>
import { mapGetters } from 'vuex';
import MailDrawerTemplate from '@/components/organisms/user/mail/common/drawer/MailDrawerTemplate.vue';

export default {
  name: 'SentDrawer',
  components: {
    MailDrawerTemplate,
  },
  props: {
    bulkMail: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'duplicate',
    'cancel',
  ],
  computed: {
    ...mapGetters('user', [
      'leadViews',
    ]),
    selectedLeadView () {
      if (this.bulkMail.leadViewId == null || this.leadViews.length === 0) return null;
      return this.leadViews.find((leadView) => leadView.id === this.bulkMail.leadViewId);
    },
    selectedLeadViewName () {
      return this.selectedLeadView == null ? this.$t('general.delete.done') : this.selectedLeadView.name;
    },
    senderSet () {
      return `${this.bulkMail.fromName} <${this.bulkMail.fromAddress}>`;
    },
    replyVisible () {
      return !!this.bulkMail.replyName && !!this.bulkMail.replyAddress;
    },
    replySet () {
      return `${this.bulkMail.replyName} <${this.bulkMail.replyAddress}>`;
    },
    mailEventOpenSet () {
      const message = this.bulkMail.open == true ? 'receive' : 'unreceive';
      return this.$t(`mailNotificationEvent.${message}`, { event: this.$t(`mailNotificationEvent.open`) });
    },
    mailEventClickSet () {
      const message = this.bulkMail.click == true ? 'receive' : 'unreceive';
      return this.$t(`mailNotificationEvent.${message}`, { event: this.$t(`mailNotificationEvent.click`) });
    },
  },
};
</script>

<style lang="scss" scoped>
  .mail-confirm {
    border: 1px solid $concrete;
    border-radius: 5px;

    .subject-confirm {
      min-height: 32px;
      max-height: 80px;
      overflow-y: scroll;
      border-bottom: 1px solid $concrete;
      padding: $basespace-100;
      font-weight: 600;
    }

    .content-confirm {
      max-height: 600px;
      overflow-y: scroll;
      padding: $basespace-100;

      .opt-out-link {
        margin: $basespace-400 0 $basespace-50;
        width: 160px;
        color: $basecolor-secondary;
        text-decoration-line: underline;
        font-size: $fontsize-100;
      }

    }
  }

  .lead-count {
    color: $basecolor-primary;
    font-weight: 600;
  }

  .flat-input {
    border-bottom: 1px solid $bdcolor-light;
  }

  .quill-confirm {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 13px;
    line-height: 1.42;
    tab-size: 4;
    text-align: left;

    // Biscuetの標準リンク色を上書き
    :deep(a) {
      color: #06c;
    }

    :deep(p) {
      margin: 0 !important;
      padding: 0 !important;
      counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
    }
  }
</style>
