/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetUnsubscribedEmail200Response } from '../models';
/**
 * DirectMailUnsubscribeApi - axios parameter creator
 * @export
 */
export const DirectMailUnsubscribeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * メールアドレスの配信停止状態を取得する
         * @summary 個別メール配信停止状態取得API
         * @param {string} email 対象のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnsubscribedEmail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getUnsubscribedEmail', 'email', email)
            const localVarPath = `/api/direct_mails/unsubscribed_email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メールアドレスの配信停止を解除する
         * @summary 個別メール配信停止解除API
         * @param {string} email 対象のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeDirectMail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('subscribeDirectMail', 'email', email)
            const localVarPath = `/api/direct_mails/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * メールアドレスを配信停止リストに追加する
         * @summary 個別メール配信停止API
         * @param {string} email 対象のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeDirectMail: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('unsubscribeDirectMail', 'email', email)
            const localVarPath = `/api/direct_mails/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DirectMailUnsubscribeApi - functional programming interface
 * @export
 */
export const DirectMailUnsubscribeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DirectMailUnsubscribeApiAxiosParamCreator(configuration)
    return {
        /**
         * メールアドレスの配信停止状態を取得する
         * @summary 個別メール配信停止状態取得API
         * @param {string} email 対象のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnsubscribedEmail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUnsubscribedEmail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnsubscribedEmail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メールアドレスの配信停止を解除する
         * @summary 個別メール配信停止解除API
         * @param {string} email 対象のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async subscribeDirectMail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.subscribeDirectMail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * メールアドレスを配信停止リストに追加する
         * @summary 個別メール配信停止API
         * @param {string} email 対象のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unsubscribeDirectMail(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unsubscribeDirectMail(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DirectMailUnsubscribeApi - factory interface
 * @export
 */
export const DirectMailUnsubscribeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DirectMailUnsubscribeApiFp(configuration)
    return {
        /**
         * メールアドレスの配信停止状態を取得する
         * @summary 個別メール配信停止状態取得API
         * @param {string} email 対象のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnsubscribedEmail(email: string, options?: any): AxiosPromise<GetUnsubscribedEmail200Response> {
            return localVarFp.getUnsubscribedEmail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * メールアドレスの配信停止を解除する
         * @summary 個別メール配信停止解除API
         * @param {string} email 対象のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeDirectMail(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.subscribeDirectMail(email, options).then((request) => request(axios, basePath));
        },
        /**
         * メールアドレスを配信停止リストに追加する
         * @summary 個別メール配信停止API
         * @param {string} email 対象のメールアドレス
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unsubscribeDirectMail(email: string, options?: any): AxiosPromise<void> {
            return localVarFp.unsubscribeDirectMail(email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DirectMailUnsubscribeApi - interface
 * @export
 * @interface DirectMailUnsubscribeApi
 */
export interface DirectMailUnsubscribeApiInterface {
    /**
     * メールアドレスの配信停止状態を取得する
     * @summary 個別メール配信停止状態取得API
     * @param {string} email 対象のメールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailUnsubscribeApiInterface
     */
    getUnsubscribedEmail(email: string, options?: AxiosRequestConfig): AxiosPromise<GetUnsubscribedEmail200Response>;

    /**
     * メールアドレスの配信停止を解除する
     * @summary 個別メール配信停止解除API
     * @param {string} email 対象のメールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailUnsubscribeApiInterface
     */
    subscribeDirectMail(email: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * メールアドレスを配信停止リストに追加する
     * @summary 個別メール配信停止API
     * @param {string} email 対象のメールアドレス
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailUnsubscribeApiInterface
     */
    unsubscribeDirectMail(email: string, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for getUnsubscribedEmail operation in DirectMailUnsubscribeApi.
 * @export
 * @interface DirectMailUnsubscribeApiGetUnsubscribedEmailRequest
 */
export interface DirectMailUnsubscribeApiGetUnsubscribedEmailRequest {
    /**
     * 対象のメールアドレス
     * @type {string}
     * @memberof DirectMailUnsubscribeApiGetUnsubscribedEmail
     */
    readonly email: string
}

/**
 * Request parameters for subscribeDirectMail operation in DirectMailUnsubscribeApi.
 * @export
 * @interface DirectMailUnsubscribeApiSubscribeDirectMailRequest
 */
export interface DirectMailUnsubscribeApiSubscribeDirectMailRequest {
    /**
     * 対象のメールアドレス
     * @type {string}
     * @memberof DirectMailUnsubscribeApiSubscribeDirectMail
     */
    readonly email: string
}

/**
 * Request parameters for unsubscribeDirectMail operation in DirectMailUnsubscribeApi.
 * @export
 * @interface DirectMailUnsubscribeApiUnsubscribeDirectMailRequest
 */
export interface DirectMailUnsubscribeApiUnsubscribeDirectMailRequest {
    /**
     * 対象のメールアドレス
     * @type {string}
     * @memberof DirectMailUnsubscribeApiUnsubscribeDirectMail
     */
    readonly email: string
}

/**
 * DirectMailUnsubscribeApi - object-oriented interface
 * @export
 * @class DirectMailUnsubscribeApi
 * @extends {BaseAPI}
 */
export class DirectMailUnsubscribeApi extends BaseAPI implements DirectMailUnsubscribeApiInterface {
    /**
     * メールアドレスの配信停止状態を取得する
     * @summary 個別メール配信停止状態取得API
     * @param {DirectMailUnsubscribeApiGetUnsubscribedEmailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailUnsubscribeApi
     */
    public getUnsubscribedEmail(requestParameters: DirectMailUnsubscribeApiGetUnsubscribedEmailRequest, options?: AxiosRequestConfig) {
        return DirectMailUnsubscribeApiFp(this.configuration).getUnsubscribedEmail(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メールアドレスの配信停止を解除する
     * @summary 個別メール配信停止解除API
     * @param {DirectMailUnsubscribeApiSubscribeDirectMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailUnsubscribeApi
     */
    public subscribeDirectMail(requestParameters: DirectMailUnsubscribeApiSubscribeDirectMailRequest, options?: AxiosRequestConfig) {
        return DirectMailUnsubscribeApiFp(this.configuration).subscribeDirectMail(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * メールアドレスを配信停止リストに追加する
     * @summary 個別メール配信停止API
     * @param {DirectMailUnsubscribeApiUnsubscribeDirectMailRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DirectMailUnsubscribeApi
     */
    public unsubscribeDirectMail(requestParameters: DirectMailUnsubscribeApiUnsubscribeDirectMailRequest, options?: AxiosRequestConfig) {
        return DirectMailUnsubscribeApiFp(this.configuration).unsubscribeDirectMail(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }
}
