import { readonly, ref } from 'vue';
import { ErrorHandlers } from '@/api/api_service';
import { SequenceStepInstance } from '@/api/openapi';
import { SequenceStepInstanceApiService } from '@/api/user/resources/sequence_step_instance';

const useSequenceStepInstance = (getErrorHandlers?: () => ErrorHandlers) => {
  const api = new SequenceStepInstanceApiService();
  const currentSequenceStepInstance = ref<SequenceStepInstance | undefined>();

  const fetchCurrentSequenceStepInstance = async (sequenceInstanceId: number) => {
    const res = await api.getCurrentSequenceStepInstance({
      request: { sequenceInstanceId },
      errorHandlers: getErrorHandlers ? getErrorHandlers() : undefined,
    });
    currentSequenceStepInstance.value = res.data;
  };
  const clearCurrentSequenceStepInstance = () => {
    currentSequenceStepInstance.value = undefined;
  };

  return {
    currentSequenceStepInstance: readonly(currentSequenceStepInstance),
    fetchCurrentSequenceStepInstance,
    clearCurrentSequenceStepInstance,
  };
};

export {
  useSequenceStepInstance,
};
