import { AxiosResponse } from 'axios';
import ApiService, { ApiArguments } from '@/api/api_service';
import ApiBase from '@/api/base';
import type { GetRecentlyMostUsedLeadViews200Response, LeadViewUpdateOwnerRequest } from '@/api/openapi';
import { LeadViewApi, type LeadViewApiDeleteLeadViewRequest, type LeadViewApiGetRecentlyMostUsedLeadViewsRequest } from '../../openapi/apis/lead-view-api';

export class LeadViewApiService extends ApiService {
  api = new LeadViewApi(undefined, this.baseUrl, this.httpInstance);

  constructor() {
    super(true);
  }

  async getRecentlyMostUsedLeadViews({
    request,
    options,
    errorHandlers,
  }: ApiArguments<LeadViewApiGetRecentlyMostUsedLeadViewsRequest> = {}): Promise<AxiosResponse<GetRecentlyMostUsedLeadViews200Response>> {
    return this.checkResponse<GetRecentlyMostUsedLeadViews200Response>(
      this.api.getRecentlyMostUsedLeadViews(request, options),
      errorHandlers,
    );
  }

  async updateLeadViewOwner(id: number, {
    request: patchLeadViewRequest,
    options,
    errorHandlers,
  }: ApiArguments<LeadViewUpdateOwnerRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.patchLeadView({ id, patchLeadViewRequest }, options),
      errorHandlers,
    );
  }

  async deleteLeadView({
    request,
    options,
    errorHandlers,
  }: ApiArguments<LeadViewApiDeleteLeadViewRequest>): Promise<AxiosResponse<void>> {
    return this.checkResponse<void>(
      this.api.deleteLeadView(request, options),
      errorHandlers,
    );
  }
}

export default {
  getLeadViews (args) {
    return ApiBase.get(`/user/lead_views`, args);
  },
  getLeadView (args) {
    const { leadViewId } = args;
    return ApiBase.get(`/user/lead_views/${leadViewId}`, args);
  },
  createLeadView (args) {
    return ApiBase.post(`/user/lead_views`, args);
  },
  updateLeadView (args) {
    const { leadViewId } = args;
    return ApiBase.put(`/user/lead_views/${leadViewId}`, args);
  },
  deleteLeadView (args) {
    const { leadViewId } = args;
    return ApiBase.delete(`/user/lead_views/${leadViewId}`, args);
  },
};
