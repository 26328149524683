<template>
  <BModalBody>
    <div class="form-items">
      <ExportDescription
        :number-of-target-leads="numberOfTargetLeads"
        :number-of-leads="numberOfLeads"
      />
      <ExportSettingFormCommon
        v-model="model"
        @update:valid="handleCommonFormValidChange"
      />
      <BListItem class="block-title">
        <span>{{ $t('leadExport.scheduledExportBlock') }}</span>
      </BListItem>
      <BListItem class="list-item">
        <ElDescriptions
          :column="1"
          border
        >
          <ElDescriptionsItem>
            <template #label>
              <span>{{ $t('leadExport.fileName') }}</span>
            </template>
            <BInput
              v-model="model.fileName"
              v-model:valid="formItemsValid.fileNameValid"
              fit
              :max-length="20"
              required
              validation
            />
          </ElDescriptionsItem>
          <ElDescriptionsItem>
            <template #label>
              <span>{{ $t('leadExport.interval.title') }}</span>
            </template>
            <div class="radio-group">
              <BRadio
                v-for="interval in PartLeadExportSettingScheduledIntervalEnum"
                :key="interval"
                v-model="model.interval"
                :label="interval"
                class="radio-item"
              >
                {{ $t(`leadExport.interval.enum.${camelCase(interval)}`) }}
              </BRadio>
            </div>
          </ElDescriptionsItem>
          <ElDescriptionsItem v-if="model.interval === PartLeadExportSettingScheduledIntervalEnum.Weekly">
            <template #label>
              <span>{{ $t('leadExport.daysOfWeek') }}</span>
            </template>
            <BDayOfWeekCheckButtons
              v-model="model.daysOfWeek"
              v-model:valid="formItemsValid.daysOfWeekValid"
              required
              size="large"
            />
          </ElDescriptionsItem>
          <ElDescriptionsItem v-if="model.interval === PartLeadExportSettingScheduledIntervalEnum.Monthly">
            <template #label>
              <span>{{ $t('leadExport.daysOfMonth') }}</span>
            </template>
            <BSelect
              v-model="model.daysOfMonth"
              v-model:valid="formItemsValid.daysOfMonthValid"
              :items="daysOfMonthItems"
              filterable
              multiple
              required
            />
          </ElDescriptionsItem>
          <ElDescriptionsItem>
            <template #label>
              <span>{{ $t('leadExport.time') }}</span>
            </template>
            <BTimePicker
              v-model="model.time"
              v-model:valid="formItemsValid.timeValid"
              format="HH:mm"
              value-format="HH:mm"
              required
              :clearable="false"
            />
          </ElDescriptionsItem>
        </ElDescriptions>
      </BListItem>
    </div>
  </BModalBody>
  <BModalFooter>
    <BTooltip
      top
      :content="tooManyLeadsMessage"
      :disabled="!tooManyLeadsMessage"
    >
      <BBtn
        type="primary"
        :disabled="saveDisabled"
        @click="handleSaveClick"
      >
        {{ $t('leadExport.scheduledExportSettingSave') }}
      </BBtn>
    </BTooltip>
  </BModalFooter>
</template>

<script lang="ts" setup>
import { camelCase } from 'lodash';
import { computed, ref, watch } from 'vue';
import { PartLeadExportSettingScheduledIntervalEnum, ScheduledLeadExportSetting } from '@/api/openapi';
import { LeadExportApiService } from '@/api/user/resources/lead_export';
import { useWait } from '@/composable/vue-wait';
import BDayOfWeekCheckButtons from '@/plugins/biscuet-materials/components/atoms/BDayOfWeekCheckButtons.vue';
import BModalBody from '@/plugins/biscuet-materials/components/molecules/BModalBody.vue';
import { makeInitialModel, useLeadLimit } from './export-setting';
import ExportDescription from './ExportDescription.vue';
import ExportSettingFormCommon from './ExportSettingFormCommon.vue';
import type { TLeadListProps } from './types';

type TProps = TLeadListProps;

const props = defineProps<TProps>();
const emit = defineEmits(['afterSave']);

const { wait, doActionWithWait } = useWait();
const api = new LeadExportApiService();
const { tooManyLeadsMessage } = useLeadLimit(() => props.numberOfTargetLeads, () => model.value.dataFormat);

const model = ref<ScheduledLeadExportSetting>({
  ...makeInitialModel(),
  fileName: '',
  interval: PartLeadExportSettingScheduledIntervalEnum.Daily,
  daysOfWeek: [],
  daysOfMonth: [],
  time: '00:00',
});
const commonFormValid = ref(false);
const formItemsValid = ref({
  fileNameValid: false,
  daysOfWeekValid: false,
  daysOfMonthValid: false,
  timeValid: false,
});
const formValid = computed(() => {
  return commonFormValid.value && Object.values(formItemsValid.value).every(valid => valid);
});
const saveDisabled = computed(() => {
  return tooManyLeadsMessage.value !== '' || !formValid.value || wait.is('postImmediateLeadExportWait');
});

watch(() => model.value.interval, (interval) => {
  formItemsValid.value.daysOfWeekValid = true;
  formItemsValid.value.daysOfMonthValid = true;
  if (interval === PartLeadExportSettingScheduledIntervalEnum.Weekly) {
    formItemsValid.value.daysOfWeekValid = false;
  } else if (interval === PartLeadExportSettingScheduledIntervalEnum.Monthly) {
    formItemsValid.value.daysOfMonthValid = false;
  }
}, { immediate: true });

const daysOfMonthItems = Array.from({ length: 31 }, (_, i) => ({
  value: i + 1,
  text: `${i + 1}日`,
}));

const handleCommonFormValidChange = (valid: boolean) => {
  commonFormValid.value = valid;
};
const handleSaveClick = async () => {
  if (!formValid.value) return;
  if (model.value.interval !== PartLeadExportSettingScheduledIntervalEnum.Weekly) {
    model.value.daysOfWeek = [];
  }
  if (model.value.interval !== PartLeadExportSettingScheduledIntervalEnum.Monthly) {
    model.value.daysOfMonth = [];
  }
  await doActionWithWait('putScheduledLeadExportSettingWait', async () => {
    await api.putScheduledLeadExportSetting({
      request: {
        scheduledLeadExportSetting: {
          ...model.value,
          leadListFilterParams: props.leadListFilterParams,
        },
      },
    });
  });
  emit('afterSave');
};
</script>

<style lang="scss" scoped>
.b-time-picker {
  width: 110px;
}
</style>
