/* tslint:disable */
/* eslint-disable */
/**
 * openapi
 * bales cloud
 *
 * The version of the OpenAPI document: 1.0
 * Contact: test@example.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GetRecentlyMostUsedLeadViews200Response } from '../models';
// @ts-ignore
import { PatchLeadViewRequest } from '../models';
/**
 * LeadViewApi - axios parameter creator
 * @export
 */
export const LeadViewApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * リードビュー削除API
         * @summary リードビュー削除API
         * @param {number} id リードビューID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeadView: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLeadView', 'id', id)
            const localVarPath = `/api/user/lead_views/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 最近使用されたリードビュー取得API
         * @summary 最近使用されたリードビュー取得API
         * @param {number} [userId] ユーザーID
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentlyMostUsedLeadViews: async (userId?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/lead_views/recently_most_used`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * リードビュー更新API
         * @summary リードビュー更新API
         * @param {number} id リードビューID
         * @param {PatchLeadViewRequest} [patchLeadViewRequest] リードビュー更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLeadView: async (id: number, patchLeadViewRequest?: PatchLeadViewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchLeadView', 'id', id)
            const localVarPath = `/api/user/lead_views/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            // authentication X-Bales-Role required
            await setApiKeyToObject(localVarHeaderParameter, "X-Bales-Role", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchLeadViewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeadViewApi - functional programming interface
 * @export
 */
export const LeadViewApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeadViewApiAxiosParamCreator(configuration)
    return {
        /**
         * リードビュー削除API
         * @summary リードビュー削除API
         * @param {number} id リードビューID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLeadView(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLeadView(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 最近使用されたリードビュー取得API
         * @summary 最近使用されたリードビュー取得API
         * @param {number} [userId] ユーザーID
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentlyMostUsedLeadViews(userId?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetRecentlyMostUsedLeadViews200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentlyMostUsedLeadViews(userId, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * リードビュー更新API
         * @summary リードビュー更新API
         * @param {number} id リードビューID
         * @param {PatchLeadViewRequest} [patchLeadViewRequest] リードビュー更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchLeadView(id: number, patchLeadViewRequest?: PatchLeadViewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchLeadView(id, patchLeadViewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeadViewApi - factory interface
 * @export
 */
export const LeadViewApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeadViewApiFp(configuration)
    return {
        /**
         * リードビュー削除API
         * @summary リードビュー削除API
         * @param {number} id リードビューID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLeadView(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLeadView(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 最近使用されたリードビュー取得API
         * @summary 最近使用されたリードビュー取得API
         * @param {number} [userId] ユーザーID
         * @param {number} [limit] 取得件数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentlyMostUsedLeadViews(userId?: number, limit?: number, options?: any): AxiosPromise<GetRecentlyMostUsedLeadViews200Response> {
            return localVarFp.getRecentlyMostUsedLeadViews(userId, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * リードビュー更新API
         * @summary リードビュー更新API
         * @param {number} id リードビューID
         * @param {PatchLeadViewRequest} [patchLeadViewRequest] リードビュー更新
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchLeadView(id: number, patchLeadViewRequest?: PatchLeadViewRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchLeadView(id, patchLeadViewRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeadViewApi - interface
 * @export
 * @interface LeadViewApi
 */
export interface LeadViewApiInterface {
    /**
     * リードビュー削除API
     * @summary リードビュー削除API
     * @param {number} id リードビューID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadViewApiInterface
     */
    deleteLeadView(id: number, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 最近使用されたリードビュー取得API
     * @summary 最近使用されたリードビュー取得API
     * @param {number} [userId] ユーザーID
     * @param {number} [limit] 取得件数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadViewApiInterface
     */
    getRecentlyMostUsedLeadViews(userId?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<GetRecentlyMostUsedLeadViews200Response>;

    /**
     * リードビュー更新API
     * @summary リードビュー更新API
     * @param {number} id リードビューID
     * @param {PatchLeadViewRequest} [patchLeadViewRequest] リードビュー更新
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadViewApiInterface
     */
    patchLeadView(id: number, patchLeadViewRequest?: PatchLeadViewRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

}

/**
 * Request parameters for deleteLeadView operation in LeadViewApi.
 * @export
 * @interface LeadViewApiDeleteLeadViewRequest
 */
export interface LeadViewApiDeleteLeadViewRequest {
    /**
     * リードビューID
     * @type {number}
     * @memberof LeadViewApiDeleteLeadView
     */
    readonly id: number
}

/**
 * Request parameters for getRecentlyMostUsedLeadViews operation in LeadViewApi.
 * @export
 * @interface LeadViewApiGetRecentlyMostUsedLeadViewsRequest
 */
export interface LeadViewApiGetRecentlyMostUsedLeadViewsRequest {
    /**
     * ユーザーID
     * @type {number}
     * @memberof LeadViewApiGetRecentlyMostUsedLeadViews
     */
    readonly userId?: number

    /**
     * 取得件数
     * @type {number}
     * @memberof LeadViewApiGetRecentlyMostUsedLeadViews
     */
    readonly limit?: number
}

/**
 * Request parameters for patchLeadView operation in LeadViewApi.
 * @export
 * @interface LeadViewApiPatchLeadViewRequest
 */
export interface LeadViewApiPatchLeadViewRequest {
    /**
     * リードビューID
     * @type {number}
     * @memberof LeadViewApiPatchLeadView
     */
    readonly id: number

    /**
     * リードビュー更新
     * @type {PatchLeadViewRequest}
     * @memberof LeadViewApiPatchLeadView
     */
    readonly patchLeadViewRequest?: PatchLeadViewRequest
}

/**
 * LeadViewApi - object-oriented interface
 * @export
 * @class LeadViewApi
 * @extends {BaseAPI}
 */
export class LeadViewApi extends BaseAPI implements LeadViewApiInterface {
    /**
     * リードビュー削除API
     * @summary リードビュー削除API
     * @param {LeadViewApiDeleteLeadViewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadViewApi
     */
    public deleteLeadView(requestParameters: LeadViewApiDeleteLeadViewRequest, options?: AxiosRequestConfig) {
        return LeadViewApiFp(this.configuration).deleteLeadView(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 最近使用されたリードビュー取得API
     * @summary 最近使用されたリードビュー取得API
     * @param {LeadViewApiGetRecentlyMostUsedLeadViewsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadViewApi
     */
    public getRecentlyMostUsedLeadViews(requestParameters: LeadViewApiGetRecentlyMostUsedLeadViewsRequest = {}, options?: AxiosRequestConfig) {
        return LeadViewApiFp(this.configuration).getRecentlyMostUsedLeadViews(requestParameters.userId, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * リードビュー更新API
     * @summary リードビュー更新API
     * @param {LeadViewApiPatchLeadViewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeadViewApi
     */
    public patchLeadView(requestParameters: LeadViewApiPatchLeadViewRequest, options?: AxiosRequestConfig) {
        return LeadViewApiFp(this.configuration).patchLeadView(requestParameters.id, requestParameters.patchLeadViewRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
