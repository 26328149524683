<template>
  <div
    v-loading="loading"
    class="select-user"
  >
    <BSelect
      ref="inputRef"
      v-model="selectedUserId"
      :disabled="disabled || loading"
      :items="userItems"
      :clearable="clearable"
      :placeholder="placeholder"
      :teleported="teleported"
      item-text="name"
      item-value="id"
      filterable
      class="type-select"
      :class="{ 'with-avatar': withAvatar }"
      @blur="handleBlur"
    >
      <template
        v-if="withAvatar"
        #label="{ value }"
      >
        <UserAvatar
          :user="findUser(value)"
        />
      </template>
      <template
        v-if="withAvatar"
        #optionTemplate="{ item }"
      >
        <UserAvatar
          :user="findUser(item.id)"
          size="small"
        />
        <span class="option-name">{{ item.name }}</span>
      </template>
    </BSelect>
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onBeforeMount, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { UserForDisplay } from '@/api/openapi';
import { useKey } from '@/composable/component';
import { useUsers } from '@/composable/user/user/users';
import { useWait } from '@/composable/vue-wait';
import { TOptions } from '../atoms/BSelect.vue';

// NOTE: 多分このComponentはsrc/components/organisms以下に置く方が正しい
type TProps = {
  placeholder?: string;
  useAll?: boolean;
  useAllLabel?: string;
  clearable?: boolean;
  onlyActiveUsers?: boolean;
  teleported?: boolean;
  withAvatar?: boolean;
  disabled?: boolean;
};
type TEmit = {
  'update:modelValue': [modelValue: number | undefined];
  'blur': [];
};
type TUser = Omit<UserForDisplay, 'id'> & { id: string | number };

const props = withDefaults(defineProps<TProps>(), {
  placeholder: undefined,
  useAll: true,
  clearable: false,
  onlyActiveUsers: false,
  useAllLabel: undefined,
});

const emit = defineEmits<TEmit>();
const VALUE_ALL = 'all';
const i18n = useI18n();
const { wait } = useWait();
const { key } = useKey();
const { users, activeUsers, fetchUsers, findUser, keyOfFetchUsers } = useUsers(() => false, () => key.value?.toString());
const loading = computed(() => {
  return wait.is(keyOfFetchUsers.value);
});

const modelValue = defineModel<number | undefined>();
const inputRef = ref<HTMLInputElement | null>(null);
const userItems = ref<TOptions>([]);
const fetchPromise = ref<Promise<unknown>>();
const selectedUserId = computed({
  get() {
    return props.useAll && modelValue.value === undefined ? VALUE_ALL : modelValue.value;
  },
  set(value: number | typeof VALUE_ALL) {
    emit('update:modelValue', value === VALUE_ALL ? undefined : value);
  },
});

onBeforeMount(async () => {
  fetchPromise.value = fetchUsers();
  await fetchPromise.value;
});
watch(users, () => {
  userItems.value = makeUserItems();
});

const makeUserItems = () => {
  const allItem: TUser = { id: VALUE_ALL, name: props.useAllLabel ?? i18n.t('selectUser.allOwners'), avatarUrl: null };

  const userItems: TOptions = props.useAll ? [allItem] : [];
  const targetUsers = props.onlyActiveUsers ? activeUsers.value : users.value;
  return userItems.concat(targetUsers);
};

const handleBlur = () => {
  emit('blur');
};
const focus = async () => {
  // NOTE: nextTickしないとfocusが発火しない
  await fetchPromise.value;
  await nextTick();
  inputRef.value?.focus();
};

defineExpose({
  focus,
});
</script>

<style lang="scss" scoped>
.select-user {
  .type-select {
    width: 160px;

    &.with-avatar {
      width: 90px;
      :deep(.el-select__wrapper) {
        padding: $basespace-200 $basespace-100;
        min-height: calc(46px + $basespace-50 * 2);
      }
      :deep(.el-select-dropdown) {
        width: 240px;
      }
      :deep(.el-select-dropdown__item) {
        @include m-flex-align-center;
        height: 36px;
        padding: 0 $basespace-200;
        .empty-box, .b-user-tooltip {
          align-self: center;
        }
        .option-name {
          @include m-truncate-text;
          flex: 1;
          padding-left: $basespace-100;
        }
      }
    }
  }
}
</style>
